import React from "react";
import Button from "../UI/Button";
import DividerHorizontal from "../UI/DividerHorizontal";
import checkON from "../../images/check-checkout-on.svg";
import checkOFF from "../../images/check-checkout-off.svg";

const AddressCheckout = (props) => {
  const {
    disclosureTitlesClasses,
    disclosurePanelClasses,
    checkOutItemClass,
    neighborhood,
    addressStatus,
    userAddress,
    handleAddressChange,
    userAddressComplement,
    handleAddressComplementChange,
    index,
    handleChangeIndex,
  } = props.data;

  return (
    <div
      as="div"
      className="d-flex flex-column gap-3 mb-3"
      onClick={() => handleChangeIndex(3)}
    >
      <div
        className={`d-flex justify-content-between align-items-center ${disclosureTitlesClasses}`}
      >
        <div className="d-flex gap-2 align-items-center">
          <i className="fa fa-map-marker" aria-hidden="true"></i>
          Dirección de entrega{" "}
        </div>
        <img src={addressStatus ? checkON : checkOFF} />
      </div>
      {index === 3 && (
        <div className={disclosurePanelClasses}>
          <div className={checkOutItemClass} style={{ fontSize: "14px" }}>
            <div className="form-floating d-flex flex-column gap-3">
              <div className="d-flex gap-2">
                <div className="form-floating w-100">
                  <strong className="disabled-forced">Ciudad</strong>
                  <select
                    value={neighborhood}
                    id="neighborhood-intention"
                    name="neighborhood-intention"
                    className="custom-select rounded-3 bg-white border-1 disabled-forced"
                    required="required"
                  >
                    <option value="">Barranquilla</option>
                  </select>
                </div>
                <div className="form-floating w-100">
                  <strong className="disabled-forced">Barrio</strong>
                  <select
                    value={neighborhood}
                    id="neighborhood-intention"
                    name="neighborhood-intention"
                    className="custom-select rounded-3 bg-white border-1 disabled-forced"
                    required="required"
                  >
                    <option value="">{neighborhood}</option>
                  </select>
                </div>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control rounded-3"
                  id="floatingAddress"
                  placeholder="tu@correo.com"
                  style={{ fontSize: "16px" }}
                  value={userAddress}
                  onChange={handleAddressChange}
                />
                <label htmlFor="floatingAddress">
                  Dirección como la conoces
                </label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control rounded-3"
                  id="floatingAddressDetails"
                  placeholder="tu@correo.com"
                  style={{ fontSize: "16px" }}
                  value={userAddressComplement}
                  onChange={handleAddressComplementChange}
                />
                <label htmlFor="floatingAddressDetails">
                  Detalles (Apartamento, piso, torre, etc.)
                </label>
              </div>
            </div>
            <DividerHorizontal height={1} color="#ebebeb" marginY={16} />
            <div
              className={`w-25 ${addressStatus ? "" : "disabled-forced"}`}
              onClick={() => handleChangeIndex(4)}
            >
              <Button text="Continuar" version="primary" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressCheckout;

import React, { useState, useEffect } from "react";
import Button from "./UI/Button";
import { useNavigate } from "react-router-dom";
import "../styles/AccessPage.css";
import bgImg from "../images/bg.jpg";
import logoImg from "../images/fluenti-logo-140.svg";
import { useFirebase } from "../context/FirebaseContext";

const AccessPage = () => {
  const navigate = useNavigate();
  const { user, login } = useFirebase();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();

  function handleBack() {
    navigate("/");
  }

  const logoImgStyle = {
    height: "48px",
  };

  useEffect(() => {
    // Comprueba si el usuario ya está autenticado en el contexto
    if (user) {
      navigate("/board");
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError();
    await login(email, password)
      .then((res) => {
        navigate("/board");
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <>
      <div
        className="p-4 p-md-5 gap-4 d-flex flex-column justify-content-center align-items-center"
        style={{
          minHeight: "100%",
          background: "#1464ff",
          background: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center", // La imagen se posicionará en el centro del contenedor
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="d-flex flex-column overflow-auto align-items-start justify-content-start flex"
          style={{ maxWidth: "1024px" }}
        >
          <div
            className="d-flex justify-content-between flex-fill position-absolute"
            style={{ maxWidth: "1024px", top: "2rem", left: "2rem" }}
          >
            <Button
              version="tertiary"
              text="Ir a inicio"
              onClick={handleBack}
            />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center ">
            <div className="card border-0 shadow-lg rounded-4 py-2">
              <div
                className="card-body d-flex flex-column gap-3"
                style={{ minWidth: "327px" }}
              >
                <img src={logoImg} style={logoImgStyle}></img>

                <div className="d-flex flex-column gap-0 text-center">
                  <h5 className="h5 text-center m-0 p-0">Ingresa a tu cuenta</h5>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control rounded-3"
                      id="floatingInput"
                      placeholder="tu@correo.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Correo electrónico</label>
                  </div>
                  {error && (
                    <div className="alert alert-danger">{error.message}</div>
                  )}
                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control rounded-3"
                      id="floatingPassword"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label for="floatingPassword">Contraseña</label>
                  </div>

                  {/* <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="rememberPasswordCheck"
                    />
                    <label
                      className="form-check-label"
                      for="rememberPasswordCheck"
                    >
                      Recordar contraseña
                    </label>
                  </div> */}
                  <hr></hr>

                  <div className="d-grid" onClick={handleSubmit}>
                    <Button text="Ingresar" version="primary" />
                  </div>
                  {/* <hr className="my-4 d-none" />
                  <div className="d-grid mb-2 d-none">
                    <button
                      className="btn btn-google btn-login text-uppercase fw-bold"
                      type="submit"
                    >
                      <i className="fab fa-google me-2"></i> Sign in with Google
                    </button>
                  </div>
                  <div className="d-grid d-none">
                    <button
                      className="btn btn-facebook btn-login text-uppercase fw-bold"
                      type="submit"
                    >
                      <i className="fab fa-facebook-f me-2"></i> Sign in with
                      Facebook
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessPage;

import React, { useState, useEffect } from "react";
import OrderHistory from "./BoardComponents/OrderHistory.jsx";
import ProfileCard from "./BoardComponents/ProfileCard.jsx";
import RechargeCard from "./BoardComponents/RechargeCard.jsx";
import SubscriptionsCards from "./BoardComponents/SubscriptionsCards.jsx";
import "../styles/Board.css";
import logoBrand from "../images/fluenti-logo-140.svg";
import { useFirebase } from "../context/FirebaseContext";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "./UI/Button.jsx";
import { useCartContext } from "../context/CartContext";
import cartIconBlue from "../images/cart-icon-blue.svg";
import cartIconDark from "../images/cart-icon.svg";
import Sidebar from "react-sidebar";
import AddressBar from "./BoardComponents/AddressBar.jsx";
import SideCart from "./BoardComponents/SideCart.jsx";
import loadingImg from "../images/loading.svg";
import { Dialog } from "@headlessui/react";

const Board = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, user, userInfo, subsInfoData, addresses, selectedAddress } =
    useFirebase();
  const { itemCount, total } = useCartContext();
  const [activeTab, setActiveTab] = useState("recambio");
  const [isScrolled, setIsScrolled] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarContent, setSidebarContent] = useState(null); // Estado para controlar qué contenido del sidebar mostrar
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    // Verifica si hay algún estado definido en la ubicación
    if (location.state) {
      // Si hay un estado definido, abre el sidebar y establece el contenido del sidebar
      setActiveTab("recambio");
      setSidebarOpen(true);
      setSidebarContent(location.state);
    } else {
      // Si no hay un estado definido, cierra el sidebar y establece el contenido del sidebar como nulo
      setSidebarOpen(false);
      setSidebarContent(null);
    }
  }, [location.state]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleCartContent = () => {
    setSidebarContent(1); // Cambia el contenido del sidebar a contenido de carrito
    setSidebarOpen(true);
  };

  const handleAddressContent = () => {
    setSidebarContent(2); // Cambia el contenido del sidebar a contenido de dirección
    setSidebarOpen(true);
  };

  useEffect(() => {
    if (user) {
      return;
    } else {
      navigate("/ingresar");
    }
  }, [user]);

  const logoStyle = {
    maxHeight: "48px",
  };
  const navbarStyle = {
    boxShadow: isScrolled
      ? "0 2px 16px rgba(0, 0, 0, 0.05) !important"
      : "none",
    zIndex: "1",
    borderBottom: "#f3f3f3 4px solid",
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleRechargeCardScroll = (scrolled) => {
    setIsScrolled(scrolled);
  };

  return userInfo !== null &&
    subsInfoData != null &&
    addresses != null &&
    selectedAddress != null ? (
    <Sidebar
      sidebar={
        sidebarContent === 1 ? (
          <SideCart closeSidebar={closeSidebar} />
        ) : sidebarContent === 2 ? (
          <AddressBar closeSidebar={closeSidebar} />
        ) : null
      }
      sidebarClassName="right-sidebar m-0 p-0 d-flex flex-column vh-100"
      open={sidebarOpen}
      onSetOpen={setSidebarOpen}
      pullRight={true} // Configuración para que la barra lateral aparezca en el lado derecho
      styles={{
        sidebar: {
          background: "white",
          zIndex: "100000000000000000",
        },
        overlay: {
          zIndex: "9999999",
          transition: "opacity .3s ease-out, visibility .3s ease-out",
          backgroundColor: "rgba(0,0,0,.72)",
        },
      }}
      touch={false}
    >
      <div className="w-100 vh-100">
        <div className="d-flex flex-column align-items-center justify-content-center">
          {/* custon navbar */}
          <div
            className="sticky-top d-flex flex-column justify-content-center align-items-center w-100 bg-white"
            style={navbarStyle}
          >
            {/* Navbar */}
            <div
              className="d-flex w-100 align-items-center justify-content-center gap-3  py-3 gap-md-0 px-4 px-md-0 flex-column flex-md-row"
              style={{ maxWidth: "1024px" }}
            >
              <div
                className={`headerLogo w-100 pb-md-0 ${
                  isScrolled ? "logo-transition hide-on-scroll" : ""
                }`}
              >
                <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
                <div className="hideOnDesktop">
                  <Button
                    version={itemCount && itemCount > 0 ? "cart" : "cart_empty"}
                    text={itemCount}
                    image={
                      itemCount && itemCount > 0 ? cartIconDark : cartIconBlue
                    }
                    onClick={handleCartContent}
                  />
                </div>
              </div>
              <ul
                id="contentSegmentor"
                className={`nav nav-pills nav-justified w-100 d-flex justify-content-center align-items-center px-md-0 rounded-3 overflow-hidden${
                  isScrolled ? "hidden-on-scroll" : ""
                }`}
              >
                <li className="nav-item p-0 m-0">
                  <a
                    className={`nav-link d-flex gap-2 align-items-center justify-content-center m-0 ${
                      activeTab === "recambio" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("recambio")}
                    href="#"
                  >
                    <i className="fa fa-refresh" aria-hidden="true"></i> Pedir
                    recambios
                  </a>
                </li>
                <li className="nav-item p-0 m-0">
                  <a
                    className={`nav-link d-flex gap-2 align-items-center justify-content-center m-0 ${
                      activeTab === "resumen" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("resumen")}
                    href="#"
                  >
                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                    Resumen
                  </a>
                </li>
              </ul>
              <div
                className="vr hideOnMobile h-75 align-self-center p-0 mx-3 "
                style={{ filter: "opacity(0.16)" }}
              ></div>
              <div className="d-none d-md-flex align-items-center justify-content-end">
                <Button
                  version={itemCount > 0 ? "cart" : "cart_empty"}
                  text={itemCount}
                  image={itemCount > 0 ? cartIconDark : cartIconBlue}
                  onClick={handleCartContent}
                />
              </div>
            </div>
            {/* Direccion seleccionada */}
            {activeTab === "recambio" && (
              <div
                className="d-flex flex-column w-100 p-0 m-0 justify-content-center align-items-center px-4 py-2 gap-3  px-md-0"
                style={{
                  backgroundColor: "#fcfcfc",
                  borderTop: "#f3f3f3 1px solid",
                }}
              >
                <div
                  className="d-flex w-100 justify-content-between"
                  style={{ maxWidth: "1024px" }}
                >
                  <div className=" d-flex align-items-center gap-2 w-100">
                    <i
                      className="fa fa-map-marker fa-lg"
                      aria-hidden="true"
                    ></i>
                    <div className="d-flex flex-column text-truncate">
                      <p style={{ fontSize: "12px" }}>
                        Entrega en{" "}
                        {selectedAddress.address.alias
                          ? selectedAddress.address.alias
                          : ""}
                      </p>
                      <div className="d-flex justify-content-center gap-2 align-items-center">
                        <h6 className="text-truncate p-0 m-0 ">
                          {selectedAddress.address.first_line +
                            ", " +
                            selectedAddress.address.neighborhood}
                        </h6>
                        <p
                          className="d-none d-sm-block"
                          onClick={handleAddressContent}
                          style={{
                            color: "#1464ff",
                            fontWeight: "700",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          Cambiar
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ color: "#1464ff", cursor: "pointer" }}>
                    <h2 sytle={{ color: "#1464ff" }}>
                      <i
                        className="fa fa-chevron-circle-right"
                        aria-hidden="true"
                        onClick={handleAddressContent}
                        sytle={{ color: "#1464ff" }}
                      ></i>
                    </h2>
                  </div>
                </div>
                <div
                  className="collapse flex-column w-100 mx-4 pb-4 overflow-auto"
                  style={{ maxWidth: "1024px", maxHeight: "500px" }}
                  id="collapseExample"
                ></div>
              </div>
            )}
          </div>
          {/* tabs */}
          <div className="w-100 d-flex justify-content-center" style={{}}>
            {/* tab resumen */}
            {activeTab === "resumen" && (
              <div
                className="w-100 d-flex flex-column flex-sm-row p-4 p-lg-0 pt-sm-5 gap-4 overflow-y-auto"
                style={{ maxHeight: "100vh", maxWidth: "1024px" }}
              >
                <div className="w-100 p-0 overflow-y-scroll overflow-hidden">
                  <ProfileCard logout={logout} userInfo={userInfo} />
                  <hr className="py-2 " style={{ filter: "opacity(48%)" }} />
                  <SubscriptionsCards switchTab={handleTabClick} />
                  <hr className="py-2 " style={{ filter: "opacity(48%)" }} />
                  <OrderHistory />
                  <div
                    className="bg-withe d-smnone d-block"
                    style={{ height: "180px" }}
                  ></div>
                </div>
              </div>
            )}
            {/* tab recambio */}
            {activeTab === "recambio" && (
              <div
                className="w-100 d-flex flex-column flex-fill"
                style={{ maxHeight: "100vh", maxWidth: "1024px" }}
              >
                <RechargeCard onScroll={handleRechargeCardScroll} />
              </div>
            )}
          </div>
        </div>
      </div>

    </Sidebar>
  ) : (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
      <img src={loadingImg}></img>
      <p className="h5 fixed-bottom pb-5 w-100 text-center">Cargando tu contenido...</p>
    </div>
  );
};

export default Board;

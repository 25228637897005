import React, { useContext } from "react";
import { useFirebase } from "../context/FirebaseContext";

import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { user } = useFirebase();
  return user ? children : <Navigate to={"/ingresar"} />;
};

export default PrivateRoute;

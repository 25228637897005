import React, { useState, useEffect } from "react";
import Button from "../UI/Button";
import DividerHorizontal from "../UI/DividerHorizontal";
import checkON from "../../images/check-checkout-on.svg";
import checkOFF from "../../images/check-checkout-off.svg";

const SlotsCheckout = (props) => {
  const {
    disclosureTitlesClasses,
    disclosurePanelClasses,
    itemCount,
    checkOutItemClass,
    slotStatus,
    selectedTime,
    setSelectedTime,
    selectedDate,
    setSelectedDate,
    setNewIndex,
    index,
    handleChangeIndex,
  } = props.data;

  useEffect(() => {}, [itemCount]);

  const afternoonCloseHourToMorningOrder = 11;
  const [currentDateTimeInColombia, setCurrentDateTimeInColombia] =
    useState(null); // Estado para la fecha y hora actual en Colombia

  const productImgStyle = {
    height: "40px",
  };

  // Obtener el día de la semana
  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  };

  // Función para seleccionar una fecha
  const handleSelectDate = (date) => {
    setSelectedDate(date);
    if (isBefore11AM()) {
    }
  };

  function getNearestActiveCicleEnd() {
    const activeCicleEnd = new Date();
    activeCicleEnd.setDate(activeCicleEnd.getDate() + 10);

    return activeCicleEnd;
  }

  const getDeliverysDays = () => {
    const today = new Date(currentDateTimeInColombia);
    const nearestActiveCicleEnd = getNearestActiveCicleEnd(); // Obtener la fecha límite de la función getNearestActiveCicleEnd()
    const days = [];
    let count = 0;
    // Si la hora actual es mayor o igual a 11, incrementamos count para pasar al día siguiente
    if (today.getHours() >= afternoonCloseHourToMorningOrder) {
      count++;
    }
    if (nearestActiveCicleEnd !== null) {
      // Agregamos los próximos lunes y viernes sin incluir el día actual hasta la fecha límite obtenida
      while (days.length < 4 && count < 8) {
        // Agregamos una condición adicional para asegurar que no se exceda el límite de días
        const nextDay = new Date(today);
        nextDay.setDate(nextDay.getDate() + count);
        if (
          nextDay.getTime() <= nearestActiveCicleEnd.getTime() &&
          (nextDay.getDay() === 2 ||
            nextDay.getDay() === 3 ||
            nextDay.getDay() === 1 ||
            nextDay.getDay() === 4 ||
            nextDay.getDay() === 5)
        ) {
          // 1 es lunes, 5 es viernes
          days.push(nextDay);
        }
        // days.push(nextDay);
        count++;
        if (
          nearestActiveCicleEnd.getFullYear() === nextDay.getFullYear() &&
          nearestActiveCicleEnd.getMonth() === nextDay.getMonth() &&
          nearestActiveCicleEnd.getDay() === nextDay.getDay()
        ) {
          return days;
        }
      }
    }
    return days;
  };

  const shouldShowToday = (date) => {
    const today = new Date(currentDateTimeInColombia);
    const cutoffTime = new Date(today);
    cutoffTime.setHours(afternoonCloseHourToMorningOrder, 0, 0); // Establece la hora de corte a las 11 am

    return (
      today.getTime() < cutoffTime.getTime() && // Verifica si la hora actual es anterior al corte
      date.getDate() === today.getDate() && // Verifica si es el día actual
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isToday = (date) => {
    const today = new Date(currentDateTimeInColombia);
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isTomorrow = (date) => {
    const tomorrow = new Date(currentDateTimeInColombia);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      date.getDate() === tomorrow.getDate() &&
      date.getMonth() === tomorrow.getMonth() &&
      date.getFullYear() === tomorrow.getFullYear()
    );
  };

  const getCurrentDateTimeInColombia = () => {
    const currentDate = new Date();
    const colombiaDateTime = new Date(currentDate.getTime());
    colombiaDateTime.setHours(colombiaDateTime.getHours()); // Restar 5 horas para convertir a la hora de Colombia
    return colombiaDateTime;
  };

  // En el useEffect, llama a la función para obtener la fecha y hora actual en Colombia
  useEffect(() => {
    const colombiaDateTime = getCurrentDateTimeInColombia();
    setCurrentDateTimeInColombia(colombiaDateTime);
  }, []);

  const morningSelectedClass =
    selectedTime === "morning" ? "selected-time" : "";
  const afternoonSelectedClass =
    selectedTime === "afternoon" ? "selected-time" : "";
  const disabledClass = isBefore11AM() ? "disabled-forced" : "";

  function formatTimestamp(timestamp) {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const days = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];

    const dateObj = new Date(timestamp);
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const dayOfWeek = days[dateObj.getDay()];

    return `${month} ${day}/${year}, ${dayOfWeek}`;
  }

  function formatCashToString(ammount) {
    return ammount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  function isBefore11AM() {
    const today = new Date(currentDateTimeInColombia);
    if (
      selectedDate &&
      selectedDate.getDay() === today.getDay() &&
      selectedDate.getMonth === today.getMonth
    ) {
      return true;
    }
    return false;
  }

  function isAfter12PM() {
    const today = new Date(currentDateTimeInColombia);
    if (
      selectedDate &&
      selectedDate.getDay() === today.getDay() &&
      selectedDate.getMonth() === today.getMonth() &&
      (today.getHours() >= 11 ||
        (today.getHours() >= 11 && today.getMinutes() >= 0))
    ) {
      return true;
    }
    return false;
  }

  function isAfter7PM() {
    const today = new Date(currentDateTimeInColombia);
    if (
      selectedDate &&
      selectedDate.getDay() === today.getDay() + 1 &&
      selectedDate.getMonth() === today.getMonth() &&
      today.getHours() >= 19 &&
      today.getMinutes() >= 0
    ) {
      return true;
    }
    return false;
  }

  const renderDays = () => {
    const days = getDeliverysDays();
    return days.map((day, index) => (
      <div
        key={index}
        style={{ minWidth: "120px" }}
        className={`card flex-row p-2 rounded-3 align-items-start pb-2 ${
          selectedDate && selectedDate.toDateString() === day.toDateString()
            ? "selected-date"
            : ""
        }`}
        onClick={() => handleSelectDate(day)}
      >
        <div className="d-flex flex-column">
          <p className="p-0 m-0">
            {shouldShowToday(day)
              ? "Hoy"
              : isTomorrow(day)
              ? "Mañana"
              : getDayOfWeek(day)}
          </p>
          <p className="p-0 m-0 font-weight-bold">
            {day.getDate()} de {day.toLocaleString("es-CO", { month: "long" })}
          </p>
        </div>
        <input
          className="form-check-input position-static p-0 m-0"
          type="radio"
          id={`date-check-${index}`} // Cambiar el id para que sea único
          checked={selectedDate && selectedDate.getDate() === day.getDate()}
        />
      </div>
    ));
  };

  const renderHours = () => {
    const timeSlots = [
      {
        id: "morning",
        label: "Por la mañana",
        time: "De 8am a 12m.",
        unavailable: isBefore11AM() || isAfter7PM(),
        selectedClass: morningSelectedClass,
      },
      {
        id: "afternoon",
        label: "Por la tarde",
        time: "De 2pm a 6pm.",
        unavailable: isAfter12PM(),
        selectedClass: afternoonSelectedClass,
      },
    ];

    return (
      <div
        id="hours"
        className={`w-100 d-flex gap-3 pb-2 ${
          selectedDate ? "" : "disabled-forced"
        } `}
      >
        {timeSlots.map(({ id, label, time, unavailable, selectedClass }) => (
          <div
            key={id}
            className={`card p-2 flex-row w-100 rounded-3 justify-content-between ${
              unavailable ? "disabled-forced" : ""
            } ${selectedClass}`}
          >
            <label htmlFor={id} className="w-100 d-flex flex-column p-0 m-0">
              <h6 className="p-0 m-0 mb-1">
                <span
                  className={`badge ${
                    unavailable ? "text-bg-dark" : "text-bg-primary"
                  }`}
                >
                  {unavailable ? "No disponible" : "Gratis"}
                </span> 
              </h6>
              <div className="w-100 d-flex flex-column gap-0">
                <p className="p-0 m-0">
                  <strong>{label}</strong>
                </p>
                <p className="d-flex gap-1 align-items-center">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                  {time}
                </p>
              </div>
            </label>
            <div>
              <input
                className="form-check-input"
                type="radio"
                id={id}
                checked={selectedTime === id}
                onChange={() => setSelectedTime(selectedTime === id ? "" : id)}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      as="div"
      className="d-flex flex-column gap-3 mb-3 "
      onClick={() => handleChangeIndex(4)}
    >
      <div
        className={`d-flex justify-content-between align-items-center ${disclosureTitlesClasses}`}
      >
        <div className="d-flex gap-2 align-items-center">
          <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
          Programa tu entrega{" "}
        </div>
        <img src={slotStatus ? checkON : checkOFF} />
      </div>
      {index === 4 && (
        <div className={disclosurePanelClasses}>
          <div
            className={`${checkOutItemClass} d-flex flex-column gap-2`}
            id="viewer"
          >
            <p className="font-weight-bold" style={{ fontSize: "14px" }}>
              📆 Fechas de entrega disponibles
            </p>
            <div id="dates" className="d-flex gap-3 w-100 overflow-x-scroll ">
              {renderDays()}
            </div>
            <p className="font-weight-bold" style={{ fontSize: "14px" }}>
              🕒 Franja de entrega
            </p>
            {renderHours()}
            <DividerHorizontal color="#ebebeb" marginY={4} height={0.5} />
            <div className={`pt-2 w-25 ${slotStatus ? "" : "disabled-forced"}`}>
              <Button
                text="Continuar"
                version="primary"
                onClick={() => handleChangeIndex(5)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlotsCheckout;

import React, { useEffect, useState } from "react";
import "../../styles/OrderHistory.css";
import { useFirebase } from "../../context/FirebaseContext";
import { useNavigate } from "react-router-dom";
import Button from "../UI/Button";

const OrderHistory = () => {
  const navigate = useNavigate();
  const { updateOrdersForUser, setCurrentPage, recentsOrders, ordersPerPage } =
    useFirebase();

  function generateDate(date) {
    const seconds = date.seconds;
    const nanoseconds = date.nanoseconds;

    // Crear un objeto Date a partir de los segundos y nanosegundos
    const milliseconds = seconds * 1000 + nanoseconds / 1000000;
    const formattedDate = new Date(milliseconds);

    // Obtener las horas y minutos
    const hours = formattedDate.getHours();
    const minutes = formattedDate.getMinutes();

    // Convertir las horas al formato de 12 horas y obtener la parte de AM/PM
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12; // Si es 0, lo convierte a 12 para el formato de 12 horas

    // Formatear la fecha y hora como desees
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDateString = formattedDate.toLocaleDateString(
      "es-ES",
      options
    );

    // Unir la fecha formateada y la hora con el formato de 12 horas y AM/PM
    const formattedDateTimeString = `${formattedDateString}, ${formattedHours}:${minutes}${ampm}`;

    return formattedDateTimeString;
  }

  function handleGoToOrder(orderId) {
    navigate(`/pedidos/${orderId}`);
  }

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex justify-content-between">
        <h4 className="h4 p-0 m-0">Historial de pedidos</h4>
        <h4 className="h4 p-0 m-0">0</h4>
      </div>
      <div id="cardTableBox" className="d-flex flex-column gap-4 w-100 ">
        <div className="card overflow-scroll bg-white rounded-3 gap-3 pb-4 mw-100">
          <table className="tableCSS table-striped">
            {/* Encabezado de la tabla */}
            <thead className="table-light p-3">
              <tr>
                <th scope="col">
                  <p>
                    <strong>Fecha</strong>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    <strong>Pedido</strong>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    <strong>Entrega en</strong>
                  </p>
                </th>

                <th scope="col">
                  <p>
                    <strong>Estado</strong>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    <strong>Recambios</strong>
                  </p>
                </th>
                <th scope="col">
                  <p>
                    <strong>Detalles</strong>
                  </p>
                </th>
              </tr>
            </thead>
            {/* Cuerpo de la tabla */}
            <tbody>
              {recentsOrders ? (
                recentsOrders.map((order) => (
                  <tr key={order.id}>
                    <td>
                      <p>{generateDate(order.created_at)}</p>
                    </td>
                    <td>
                      <p>{order.id}</p>
                    </td>
                    <td>
                      <p>
                        {order.addressPreview && order.addressPreview !== null
                          ? order.addressPreview.firstAddress.first_line +
                            (order.addressPreview.additionalAddressesCount > 0
                              ? " y " +
                                order.addressPreview.additionalAddressesCount +
                                " direcciones más."
                              : "")
                          : "Sin información"}
                      </p>
                    </td>
                    <td>
                      {order.status !== "delivered" ? (
                        <span className="badge text-bg-dark">
                          Pedido en progreso
                        </span>
                      ) : (
                        <span className="badge text-bg-success">
                          Pedido entregado
                        </span>
                      )}
                    </td>
                    <td>
                      {order.items > 1 ? (
                        <p>{order.items} Botellones</p>
                      ) : (
                        <p>{order.items} Botellón</p>
                      )}
                    </td>
                    <td className="align-items-center overflow-hidden">
                      <div onClick={() => handleGoToOrder(order.id)}>
                        <i
                          className="fa fa-chevron-circle-right fa-2x"
                          aria-hidden="true"
                          style={{ color: "#1464ff", cursor: "pointer" }}
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No hay órdenes disponibles
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="w-100 d-flex align-items-md-center justify-content-center">
          {recentsOrders &&
          recentsOrders !== null &&
          recentsOrders.length % ordersPerPage !== 0 ? (
            <p>Son todas las ordenes.</p>
          ) : (
            <Button
              version="primary"
              text="Cargar más"
              onClick={() => updateOrdersForUser()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;

import React, { useState } from "react";

const Button = (props) => {
  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);

  const buttonBase = {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "14px",
    fontWeight: "700",
    cursor: "pointer",
    userSelect: "none",
    justifyContent: "center",
    transition: "background 0.3s, color 0.3s",
    borderRadius: "8px",
    maxWidth:"280px"
  };

  const getBackground = (normalColor, pressedColor) =>
    pressed ? pressedColor : normalColor;

  const buttonStyles = {
    cart_empty: {
      ...buttonBase,
      background: getBackground("#F5F5F5", "#E9E9E9"),
      padding: "10px 16px",
      borderRadius: "44px",
      color: "#222",
    },
    cart: {
      ...buttonBase,
      background: getBackground("#1464ff", "#083794"),
      border: "2px solid #8db4ff",
      padding: "8px 12px",
      color: "#fff",
      borderRadius: "44px",
      boxShadow: "0px 8px 24px -12px rgba(20,100,255,0.50)",
    },
    primary: {
      ...buttonBase,
      background: getBackground("var(--Brand-Brand, #1464FF)", "#0C3C99"),
      border: "2px solid var(--Brand-Brand, #1464FF)",
      padding: "8px 8px",
      color: "#FFF",
    },
    primaryLarge: {
      ...buttonBase,
      background: getBackground("var(--Brand-Brand, #1464FF)", "#0C3C99"),
      border: "2px solid var(--Brand-Brand, #1464FF)",
      padding: "16px 8px",
      color: "#FFF",
      fontSize:"16px"
    },
    secondary: {
      ...buttonBase,
      background: getBackground(
        "rgba(72, 129, 240, 0.10)",
        "rgba(72, 129, 240, 0.5)"
      ),
      border: "2px solid var(--Brand-Brand, #1464FF)",
      padding: "8px 8px",
      color: "var(--Brand-Brand, #1464FF)",
    },
    tertiary: {
      ...buttonBase,
      background: getBackground(
        pressed ? "#CCCCCC" : hovered ? "#F5F5F5" : "#FFFFFF",
        "#1464FF"
      ),
      border: "2px solid var(--Brand-Brand, #1464FF)",
      padding: "8px 8px",
      color: "#1464FF",
      hoverBackground: "#YourHoverColor", // Cambia YourHoverColor al color que desees
      pressedBackground: "#YourPressedColor", // Cambia YourPressedColor al color que desees
    },
    flat: {
      ...buttonBase,
      background: "rgba(255, 255, 255, 0)",
      border: "2px solid rgba(255, 255, 255, 0)",
      padding: "8px 0px",
      color: "#1464FF",
      hoverBackground: "#YourHoverColor", // Cambia YourHoverColor al color que desees
      pressedBackground: "#YourPressedColor", // Cambia YourPressedColor al color que desees
    },
    stepper: {
      ...buttonBase,
      background:
        props.text === " + "
          ? getBackground("var(--Brand-Brand, #1464FF)", "#0C3C99")
          : getBackground(
              "rgba(72, 129, 240, 0.10)",
              "rgba(72, 129, 240, 0.5)"
            ),
      border: "2px solid var(--Brand-Brand, #1464FF)",
      padding: "8px 16px",
      color: props.text === " + " ? "#fff" : "#1464ff",
      fontSize: "24px",
    },
  };

  const handleMouseDown = () => {
    setPressed(true);
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  return (
    <>
      {props.version && (
        <div
          className={`h-100 button ${props.disabled ? "disabled" : ""}`}
          style={{
            ...buttonStyles[props.version],
            ...(props.disabled && { opacity: "0.3", cursor: "auto" }),
            ...(props.version === "tertiary" && {
              background: getBackground(
                pressed
                  ? buttonStyles.tertiary.pressedBackground
                  : hovered
                  ? buttonStyles.tertiary.hoverBackground
                  : "#FFFFFF",
                "#FFFFF"
              ),
            }),
          }}
          onMouseOver={() => !props.disabled && setPressed(true)}
          onMouseOut={() => !props.disabled && setPressed(false)}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onClick={() => !props.disabled && props.onClick && props.onClick()}
        >
          {props.image && (
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "4px", height: "8px" }}
            >
              <img
                src={props.image}
                alt="Button Image"
                className="buttonImage"
                style={{ height: "20px" }}
              />
            </div>
          )}
          {props.version === "cart" ? (
            <h4
              className="p-0 m-0 d-flex justify-content-center align-items-center"
              style={{
                width: "24px",
                height: "20px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              {props.text}
            </h4>
          ) : (
            props.text
          )}
        </div>
      )}
    </>
  );
};

export default Button;

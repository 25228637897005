import React, { createContext, useContext, useState, useEffect } from "react";
import { useFirebase } from "./FirebaseContext";
import { toast } from "react-toastify";

export const CartContext = createContext();
export const useCartContext = () => useContext(CartContext);

const useStorage = (key, initialValue) => {
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = useState(initial);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const CartProvider = ({ children }) => {
  const {
    addresses,
    subsInfoData,
    setSubsInfoData,
    getEnvironment,
    userInfo,
    updateAddresses,
    updateSubsInfoData,
    getOrdersForUser
  } = useFirebase();
  const [subsCart, setSubsCart] = useStorage("subsCart", {}); // Utiliza el almacenamiento local para subsCart
  const [conditionsConfirmation, setConditionsConfirmation] = useStorage(
    "conditionsConfirmation",
    false
  ); // Utiliza el almacenamiento local para subsCart
  // const [recharge, setRecharge] = useStorage("recharge", 0);
  // const [newSig, setNewSig] = useStorage("newSig", 0);
  // const [usedSig, setUsedSig] = useStorage("usedSig", 0);
  const [total, setTotal] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [pricing, setPricing] = useState(null);
  const [resume, setResume] = useState({});

  // useEffect(() => {
  //   const fetchData = () => {
  //     // Definir la promesa para obtener el entorno de pricing
  //     const promise = new Promise((resolve, reject) => {
  //       // Simulación de una función asincrónica, como obtener el entorno de pricing
  //       getEnvironment("pricing", (error, result) => {
  //         if (error) {
  //           reject(error); // Si hay un error, rechazamos la promesa
  //         } else {
  //           resolve(result); // Si no hay error, resolvemos la promesa con el resultado
  //         }
  //       });
  //     });

  //     // Manejar el resultado de la promesa
  //     promise
  //       .then((result) => {
  //         setPricing(result);
  //         console.log(">>>>>>>>>>> tengo el pricing"); // Actualizar el estado con el resultado obtenido
  //       })
  //       .catch((error) => {
  //         console.error("Error al obtener el entorno de pricing:", error); // Manejar cualquier error que ocurra
  //       });
  //   };

  //   // Llamar a la función para obtener el entorno de pricing
  //   fetchData();
  // }, []);

  useEffect(() => {
    let totalCount = 0;
    // Recorrer todas las direcciones en subsCart
    if (subsCart) {
      Object.values(subsCart).forEach((subscription) => {
        if (subscription.refill_on_cart > 0) {
          totalCount += subscription.refill_on_cart;
        }
      });
      setItemCount(totalCount);
      setTotal(0);
    }
  }, [subsCart]);

  useEffect(() => {
    const fetchData = async () => {
      // Si subsCart es null (no hay valor en el almacenamiento local)
      if (
        !subsCart ||
        !addresses ||
        !subsInfoData ||
        Object.keys(subsCart).length === 0
      ) {
        // Generar el nuevo subsCart con los datos actuales
        const addressInfo = await generateAddressInfo(subsInfoData, addresses);
        setSubsCart(addressInfo);
      } else {
        const addressInfo = await generateAddressInfo(subsInfoData, addresses);
        const updatedSubsCart = await compareSubsCart(addressInfo, subsCart);
        // Verificar si los datos han cambiado antes de actualizar subsCart
        if (!areObjectsEqual(updatedSubsCart, subsCart)) {
          toast.warning("Algunas suscripciones se han actualizado");
          setSubsCart(updatedSubsCart);
        }
      }
    };

    fetchData();
  }, [subsInfoData, addresses]);

  const compareSubsCart = (receivedObj, actualObj) => {
    const updatedObj = { ...actualObj };

    for (const key in receivedObj) {
      const receivedSub = receivedObj[key];
      const actualSub = actualObj[key];

      if (receivedSub && actualSub) {
        // Verificar si existen antes de acceder a sus propiedades
        let newRefillOnCart = actualSub.refill_on_cart;
        let newRefillAvailables = actualSub.refill_availables;

        const diff = receivedSub.refill_availables - actualSub.refill_on_cart;

        if (diff < 0) {
          newRefillOnCart = receivedSub.refill_availables;
          newRefillAvailables = 0;
        } else {
          newRefillAvailables = diff;
        }

        updatedObj[key] = {
          ...actualSub,
          refill_availables: newRefillAvailables,
          refill_on_cart: newRefillOnCart,
        };
      }
    }

    return updatedObj;
  };

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === "object" && typeof val2 === "object") {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };

  const getCurrentDateTimeInColombia = async () => {
    try {
      const response = await fetch("http://worldtimeapi.org/api/ip");
      if (!response.ok) {
        throw new Error("Failed to fetch current date and time");
      }
      const { utc_datetime } = await response.json();
      const colombiaDateTime = new Date(utc_datetime);
      colombiaDateTime.setHours(colombiaDateTime.getHours() - 5); // Restar 5 horas para convertir a la hora de Colombia
      return colombiaDateTime;
    } catch (error) {
      console.error("Error fetching current date and time:", error);
      return new Date(); // Devuelve la fecha y hora actual del dispositivo si hay un error
    }
  };

  const generateAddressInfo = (subsInfoData, addresses) => {
    const addressInfo = {};

    if (subsInfoData && addresses) {
      // Verificar si subsInfoData no es nulo
      subsInfoData.forEach((subscription) => {
        const { address_id, status, active_cicle_end } = subscription;

        if (address_id in addresses) {
          if (!addressInfo[address_id]) {
            addressInfo[address_id] = {
              addressInfo: {
                ...addresses[address_id],
              },
              refill_availables: countActiveSubsByAddress(
                address_id,
                active_cicle_end
              ),
              refill_on_cart: 0,
              subscriptions: [],
            };
          }
          addressInfo[address_id].subscriptions.push({
            ...subscription,
            on_cart: false, // Agregar el campo "on_cart" con valor "false"
          });
        }
      });
    }

    return addressInfo;
  };

  function formatCashToString(ammount) {
    return ammount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const updateSubsCart = (address_id, type, sub_id) => {
    setSubsCart((prevSubsCart) => {
      const updatedSubsCart = { ...prevSubsCart };
      switch (type) {
        case "increase":
          if (updatedSubsCart[address_id]) {
            updatedSubsCart[address_id].refill_availables -= 1;
            updatedSubsCart[address_id].refill_on_cart += 1;
            // Encuentra la suscripción en el arreglo subscriptions por su ID
            const subscriptionToUpdate = updatedSubsCart[
              address_id
            ].subscriptions.find((sub) => sub.id === sub_id);
            // Verifica si se encontró la suscripción y actualiza el valor on_cart a true
            if (subscriptionToUpdate) {
              subscriptionToUpdate.on_cart = true;
            }
          }
          if (updatedSubsCart[address_id].refill_on_cart === 1) {
            // toast.info("Recambio agregado");
          }
          return { ...updatedSubsCart }; // Devuelve un nuevo objeto para garantizar la actualización
        case "decrease":
          if (updatedSubsCart[address_id]) {
            updatedSubsCart[address_id].refill_availables += 1;
            updatedSubsCart[address_id].refill_on_cart -= 1;
            const subscriptionToUpdate = updatedSubsCart[
              address_id
            ].subscriptions.find((sub) => sub.id === sub_id);
            // Verifica si se encontró la suscripción y actualiza el valor on_cart a true
            if (subscriptionToUpdate) {
              subscriptionToUpdate.on_cart = false;
            }
          }
          if (updatedSubsCart[address_id].refill_on_cart === 0) {
            // toast.warning("Recambio eliminado");
          }
          return { ...updatedSubsCart }; // Devuelve un nuevo objeto para garantizar la actualización
        default:
          return { ...prevSubsCart }; // Devuelve el estado anterior si no se realiza ninguna modificación
      }
    });
  };

  const countActiveSubsByAddress = (addressId) => {
    return subsInfoData.reduce((count, obj) => {
      const formattedDateObj = new Date(
        obj.active_cicle_end.seconds * 1000 +
          obj.active_cicle_end.nanoseconds / 1000000
      );
      if (
        obj.address_id === addressId &&
        obj.status === "active" &&
        obj.recharges_availables > 0
      ) {
        count++;
      }
      return count;
    }, 0);
  };

  const countActiveRecharges = (address_id_to_find) => {
    if (!subsInfoData) return 0; // Si subsInfoData es undefined, devolver 0

    function getActiveCicleEnd(active_cicle_end) {
      return new Date(
        active_cicle_end.seconds * 1000 + active_cicle_end.nanoseconds / 1000000
      );
    }
    const now = new Date();

    // Filtrar las suscripciones con la dirección proporcionada y status="active"
    const activeSubscriptions = subsInfoData.filter(
      (sub) =>
        sub.address_id === address_id_to_find &&
        sub.status === "active" &&
        getActiveCicleEnd(sub.active_cicle_end) > now
    );

    // Obtener la cantidad de suscripciones activas
    const count = activeSubscriptions.length;

    return count;
  };

  

  // function countActiveRecharges(addressId) {
  //   let count = 0;
  //   for (let i = 0; i < subsInfoData.length; i++) {
  //     const now = new Date();
  //     const formattedDateObj = new Date(
  //       subsInfoData[i].active_cicle_end.seconds * 1000 +
  //         subsInfoData[i].active_cicle_end.nanoseconds / 1000000
  //     );
  //     if (
  //       subsInfoData[i].address_id === addressId &&
  //       subsInfoData[i].status === "active" &&
  //       formattedDateObj > now
  //     ) {
  //       count++;
  //     }
  //   }
  //   return count;
  // }

  async function cleanCartContext() {
    localStorage.clear(); // Limpiar el almacenamiento local
    await updateAddresses(userInfo.id);
    await updateSubsInfoData(userInfo.id);
    await getOrdersForUser()
  }

  return (
    <CartContext.Provider
      value={{
        pricing,
        total,
        setTotal,
        itemCount,
        setItemCount,
        subsCart,
        setSubsCart,
        updateSubsCart,
        conditionsConfirmation,
        setConditionsConfirmation,
        formatCashToString,
        countActiveRecharges,
        setResume,
        resume,
        cleanCartContext,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

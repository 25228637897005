import React from "react";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
import waterBgImg from "../../images/waterbg.jpg";

const ProfileCard = (props) => {
  const navigate = useNavigate();
  const userInfo = props.userInfo;

  const earlyStyles = {
    background: `url(${waterBgImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center", // La imagen se posicionará en el centro del contenedor
    backgroundRepeat: "no-repeat",
  };

  const imgBotStyle = {
    maxHeight: "48px",
  };

  function logout() {
    props.logout();
  }

  return (
    <div
      className=" d-flex flex-column mt-0 mt-sm-4 align-items-start rounded-2 p-3 card border-0"
      style={earlyStyles}
    >
      <div className="d-flex flex-column mb-2 ">
        <p style={{ paddingBottom: "8px", textTransform: "uppercase", letterSpacing:"4px", opacity:"0.2" }}>
          Tu cuenta
        </p>
        <h3
          className="h3 p-0 m-0"
          style={{ fontWeight: "700", color: "#1464ff" }}
        >
          {userInfo.name + " " + userInfo.last_name}
        </h3>
        {/* <p>{userInfo.national_id}</p> */}
        <p className="pt-3">{userInfo.email}</p>
        <p>{userInfo.phone}</p>
      </div>
      <div className="d-flex gap-1 align-items-center" onClick={logout}>
        <i className="fa fa-power-off"></i>
        <p>
          <strong>Cerrar sesión</strong>
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;

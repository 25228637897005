import React, { useState, useEffect } from "react";
import Button from "./UI/Button";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { useFirebase } from "../context/FirebaseContext";
import loadingImg from "../images/loading.svg";
import logoBrand from "../images/fluenti-logo-140.svg";
import zigzagImg from "../images/zigzag.svg";
import html2canvas from "html2canvas"; // Importa html2canvas
import moment from "moment-timezone";
// eslint-disable-next-line
import Barcode from "react-jsbarcode";
// import ProgressBar from "./UI/ProgressBar";
// import copyImg from "../images/copy-es.svg";

import "../styles/Medias.css";

const SuccessPage = () => {
  //Variable de carga
  const [isLoading, setIsLoading] = useState(true);
  //Contexto
  const { formatCashToString, updateOrderFieldsOnSuccess, checkPaymentStatus } =
    useFirebase(); // Obtén el contexto de Firebase
  //Variables de navegacion
  const navigate = useNavigate();
  const { linkParam } = useParams();
  const location = useLocation();
  //Variables de estado
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);
  // Estado para almacenar la ID y la respuesta de la transacción
  const [paymentData, setPaymentData] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [expiredPaymentLink, setExpiredPaymentLink] = useState(false);

  //Variables de styles
  const imageStyle = {
    height: "64px",
    margin: "1rem",
  };
  const imageStyleGrey = {
    height: "64px",
    margin: "1rem",
    filter: "grayscale(100%)",
  };



  useEffect(() => {
    const getTransaction = async () => {
      // Obtener el valor del parámetro 'id' de la URL de redirección
      //Si hay parametro continuar
      if (isValidOrderId(linkParam)) {
        const urlParams = new URLSearchParams(window.location.search);
        const idFromUrl = urlParams.get("id");
        setTransactionId(idFromUrl);
        if (transactionId !== null) {
          if (paymentData === null) {
            try {
              const response = await axios.get(
                `https://production.wompi.co/v1/transactions/${idFromUrl}`
              );
              setPaymentStatus(response.data.data.status);
              setPaymentData(response.data.data);
              if (response.data.data.status === "APPROVED") {
                const paymentStatus = await checkPaymentStatus(linkParam);
                if (paymentStatus === false) {
                  disableLink(response.data.data.payment_link_id);
                  updateOrderFieldsOnSuccess(linkParam, response.data.data.id);
                  notifyToHook(response.data.data, linkParam);
                } else {
                }
              } else {
                const responseLink = await axios.get(
                  `https://production.wompi.co/v1/payment_links/${response.data.data.payment_link_id}`
                );
                if (responseLink.data) {
                  const expirationDate = new Date(
                    responseLink.data.data.expires_at
                  );
                  const currentTimestamp = new Date();
                  if (currentTimestamp > expirationDate) {
                    setExpiredPaymentLink(true);
                    disableLink(response.data.data.payment_link_id);
                  }
                } else {
                  console.log("Error with payment Link");
                }
              }
              // Puedes acceder a la información de la transacción usando transactionInfo
            } catch (error) {
              // Manejar errores en la solicitud
              setPaymentStatus("MISSING");
              console.error(
                "Error al obtener información de la transacción:",
                error.response
              );
            }
          }
        }
        setIsLoading(false);
      } else {
        setIsInvalidUrl(true);
      }
    };

    getTransaction();
  }, [linkParam, transactionId]);

  useEffect(() => {
    if (isInvalidUrl) {
      navigate("/404");
    }
  }, [isInvalidUrl, navigate]);

  const formattedDate = paymentData?.finalized_at
    ? moment(paymentData.finalized_at)
        .tz("America/Bogota")
        .format("YYYY-MM-DD HH:mm:ss")
    : "Fecha no disponible";

  function handleSearchOrder() {
    const newUrl = `/pedido/${linkParam}`;
    navigate(newUrl, { replace: true });
  }

  const handleBack = () => {
    navigate("/");
  };

  const isValidOrderId = (orderId) => {
    const regex = /^[0-9a-zA-Z]{8}$/;
    return regex.test(orderId);
  };

  const retryPayment = () => {
    const newUrl = "https://checkout.wompi.co/l/" + paymentData.payment_link_id;
    window.open(newUrl, "_blank");
  };
  const getRetryURL = () => {
    const newUrl = "https://checkout.wompi.co/l/" + paymentData.payment_link_id;
    return newUrl;
  };

  const talkToSupport = () => {
    const whatsAppApi =
      "https://api.whatsapp.com/send?phone=573161232337&text=";
    const text = `Hola, tengo problemas para realizar mi pago, No. orden: ${linkParam}, Link de Pago: ${paymentData.payment_link_id}, No. de pago: ${paymentData.id} `;
    window.open(whatsAppApi + text, "_blank");
  };

  const renderTransactionStatus = () => {
    if (paymentStatus) {
      switch (paymentStatus) {
        case "APPROVED":
          return (
            <div
              className="successBody card border-0  px-5 align-items-center "
              style={{ width: "320px" }}
            >
              <img src={logoBrand} style={imageStyle} alt="Fluenti"> </img>

              <h3
                className="text-center"
                style={{ fontSize: "32px", lineHeight: "90%" }}
              >
                ¡Transacción Aprobada!
              </h3>
              <p className="text-center">
                Hemos recibido tu pago.<br></br>
                Fecha: {formattedDate}
                <br></br>
                Monto: ${formatCashToString(paymentData.amount_in_cents / 100)}
              </p>
              <div className="p-2 px-4 my-4 border d-flex flex-column gap align-items-center text-center">
                <p className="text-center">Número de tu pedido</p>
                <h3 className="m-0 text-center" style={{ fontSize: "32px" }}>
                  {linkParam}
                </h3>
              </div>
              <p className="text-center">
                Sigue tu pedio en:<br></br>
                <b>fluenti.co/pedido/{linkParam}</b>
                <br></br>(Enviaremos detalles a tu correo)
              </p>
              <div
                style={{ maxWidth: "100%" }}
                className="align-items-center justify-content-center d-flex pt-4 pb-4"
              >
                <Barcode
                  value={linkParam}
                  options={{
                    format: "code128",
                    width: "2",
                    height: 32,
                    margin: 0,
                    marginBottom: 0,
                    background: "#ffffff",
                    lineColor: "#000000",
                    text: "Fluenti",
                    fontSize: 14,
                  }}
                  renderer="svg"
                />
              </div>
            </div>
          );
        case "VOIDED":
        case "ERROR":
        case "DECLINED":
          const noPaymentStatus =
            paymentData.status === "VOIDED"
              ? "Anulada"
              : paymentData.status === "ERROR"
              ? "con errores"
              : paymentData.status === "DECLINED"
              ? "Declinada"
              : "";
          return (
            <div
              className="successBody card px-5 py-5 align-items-center "
              style={{ width: "320px", border: "2px solid red" }}
            >
              <img src={logoBrand} style={imageStyleGrey} alt=""></img>
              <h3
                className="text-center"
                style={{ fontSize: "32px", lineHeight: "90%", color: "red" }}
              >
                Transacción {noPaymentStatus}
              </h3>
              <p className="text-center">
                Lo sentimos, no se ha podido completar tu compra.
              </p>
              {expiredPaymentLink === false ? (
                <p className="text-center pt-4">
                  El link de pago continua activo, puedes reintentar tu pago{" "}
                  <a target="blank" href={getRetryURL()}>
                    <b>aquí.</b>
                  </a>
                </p>
              ) : (
                <p className="text-center pt-4">
                  El link de pago ha expirado, debes realizar un nuevo pedido.{" "}
                </p>
              )}
            </div>
          );
        case "MISSING":
          return <h1>MISSING</h1>;
        default:
          return null;
      }
    }
  };

  const [isHtmlVisible, setIsHtmlVisible] = useState(false);

  const generateJPG = () => {
    // Selecciona el elemento que quieres capturar (en este caso, el div con la clase "successBody")
    const successBodyElement = document.querySelector(".successBody");
    // Verifica si el elemento existe antes de continuar
    if (!successBodyElement) {
      console.error("No se pudo encontrar el elemento '.successBody'");
      return;
    }
    // Cambia el estado para mostrar el contenido antes de la captura
    setIsHtmlVisible(true);
    // Captura el contenido utilizando html2canvas
    html2canvas(successBodyElement).then((canvas) => {
      // Convierte el canvas a una imagen en formato de datos URL (base64)
      const dataURL = canvas.toDataURL("image/jpeg");

      // Crea un enlace temporal para descargar la imagen
      const a = document.createElement("a");
      a.href = dataURL;
      a.download =
        "paymentFluenti-" + linkParam + "-" + paymentData.id + ".jpg";
      // Simula un clic en el enlace para iniciar la descarga
      a.click();
      // Cambia el estado para ocultar el contenido después de la captura
      setTimeout(() => {
        // Cambia el estado para ocultar el contenido después de la captura
        setIsHtmlVisible(false);
      }, 800);
    });
  };

  const notifyToHook = async (data, orderId) => {
    try {
      const webhookResponse = await axios.post(
        "https://hook.us1.make.com/hs4yjrahxru3z5n8linws9f8t1uhvus0",
        { data, orderId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (webhookError) {
      console.error(
        "Error al enviar cartData al webhook:",
        webhookError.message
      );
    }
  };

  const disableLink = async (paymentLinkId) => {
    try {
      if (paymentLinkId !== null) {
        //
        const url = `https://production.wompi.co/v1/payment_links/${paymentLinkId}`;
        const accesskey = "prv_prod_clSh8sJLpHiU5oMVjrtieSJMspzwRUKE";
        //
        const response = await axios.patch(
          url,
          {
            active: false,
          },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accesskey}`,
            },
          }
        );
      } else {
      }
    } catch (error) {
      console.error("Error on request:", error.response);
    }
  };

  return (
    <div
      className="d-flex flex-column overflow-auto"
      style={{
        height: "100vh",
        width: "100vw",
        color: "#fff",
        backgroundColor: "#225BCA",
        color: "#fff",
        overflow: "auto",
      }}
    >
      {isLoading ? (
        <div className="d-flex flex-column flex-fill gap-3 justify-content-center align-items-center">
          <img src={loadingImg} alt="loading"/>
          <div className="fixed-bottom"></div>
        </div>
      ) : (
        paymentData !== null && (
          <>
            <div
              className="d-flex flex-column align-items-center  justify-content-start gap-2"
              style={{
                height: "100vh",
                width: "100vw",
                marginBottom: "120px",
              }}
            >
              <div className="d-flex p-4" style={{ width: "100%" }}>
                <Button
                  version="tertiary"
                  text="Ir a inicio"
                  onClick={handleBack}
                />
              </div>
              <div>
                {paymentData.status === "APPROVED" && (
                  <img src={zigzagImg} style={{ width: "320px" }} alt="" />
                )}
                {renderTransactionStatus()}
                {paymentData.status === "APPROVED" && (
                  <img
                    src={zigzagImg}
                    style={{ width: "320px", rotate: "180deg" }}
                    alt=""
                  />
                )}
                {paymentData.status === "APPROVED" ? (
                  <div
                    className="p-4 d-flex align-items-center gap-2 justify-content-center fixed-bottom"
                    style={{
                      backgroundColor: "#fff",
                      boxShadow: "0px -10px 23px -17px rgba(0,0,0,0.75)",
                    }}
                  >
                    <Button
                      text="Descargar comprobante"
                      version="secondary"
                      onClick={generateJPG}
                    />
                    <Button
                      text="Rastrear mi pedido"
                      version="primary"
                      onClick={handleSearchOrder}
                    />
                  </div>
                ) : (
                  <div
                    className="p-4 d-flex align-items-center gap-2 justify-content-center fixed-bottom"
                    style={{
                      backgroundColor: "#fff",
                      boxShadow: "0px -10px 23px -17px rgba(0,0,0,0.75)",
                    }}
                  >
                    <Button
                      text="Hablar con soporte"
                      version="secondary"
                      onClick={talkToSupport}
                    />
                    {expiredPaymentLink === false ? (
                      <Button
                        text="Reintentar mi pago"
                        version="primary"
                        onClick={retryPayment}
                      />
                    ) : (
                      <Button
                        text="Crear nuevo pedido"
                        version="primary"
                        onClick={handleBack}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default SuccessPage;

import React, { useState, useEffect } from "react";
import { useCartContext } from "../context/CartContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useFirebase } from "../context/FirebaseContext";
import loadingImg from "../images/loading.svg";
import Navbar from "./Navbar";
import Button from "./UI/Button";
import resume2x from "../images/resume-2x.png";
import resume4x from "../images/resume-4x.png";
import resume8x from "../images/resume-8x.png";
import DividerHorizontal from "./UI/DividerHorizontal";

const SubscriptionGateway = () => {
  const linkParam = useParams(); // Obtiene el parámetro de la URL
  const subscription_id = linkParam.linkParam;

  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { getSingleSubsData, userInfo } = useFirebase();

  useEffect(() => {
    if (userInfo && subscription_id) {
      return;
    } else {
      navigate("/ingresar");
    }
  }, [userInfo]);

  useEffect(() => {
    const fetchData = async () => {
      if (data === null && userInfo) {
        const getData = await getSingleSubsData(userInfo.id, subscription_id);
        if (getData && Object.keys(getData).length !== 0) {
          setData(getData);
        } else {
          navigate("/ingresar");
        }
      }
    };
    fetchData();
  }, []);

  return data !== null && Object.keys(data).length !== 0 ? (
    <div className="h-100 vw-100 d-flex align-items-center justify-content-center">
      <Navbar version="SubscriptionGateway" />
      <div
        className="d-flex w-100 vh-100 gap-3 flex-column"
        style={{ maxWidth: "750px", marginTop: "220px" }}
      >
        <div className="d-flex card p-4 rounded-3 border-0 shadow-sm">
          <div className="d-flex w-100 justify-content-between align-items-end">
            <div className="d-flex flex-column">
              <label>Plan {data.type}, comprado el {data.activeCicleId}</label>
              <h1 className="h3 fw-bold text-uppercase">{data.id}</h1>
              <h4>
                <span className="badge bg-primary">Plan activo</span>
              </h4>
            </div>
            <div>
              <img src={data.type === "2x" ? resume2x : resume4x} />
            </div>
          </div>
          <DividerHorizontal height={2} marginY={16} color="#ebebeb"/>
        </div>
        Renovar Plan
        <h3 className="text-justify w-50">{JSON.stringify(data)}</h3>
        <div className="fixed-bottom d-flex align-items-center justify-content-center bg-white shadow  w-100 py-4">
          <Button text="Renovar tu plan" version="primary" />
        </div>
      </div>
    </div>
  ) : (
    <div className="vh-100 vw-100 d-flex align-items-center justify-content-center align-items-center">
      <img src={loadingImg}></img>
      <p className="h5 fixed-bottom pb-5 w-100 text-center">
        Cargando Suscripción...
      </p>
    </div>
  );
};

export default SubscriptionGateway;

import React from "react";
import oasisImg from "../images/oasis-fluenti-smartwater-404.jpg";
import Button from "./UI/Button";
import logoBrand from "../images/fluenti-logo-140.svg";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  const imageStyle = {
    height: "88px",
  };
  function handleGoHome() {
    navigate("/");
  }

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-between p-5"
      style={{
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${oasisImg})`,
        backgroundSize: "cover", // Ancho igual al ancho de la ventana
        backgroundPosition: "center", // Centrado vertical y horizontal
        backgroundColor: "rgba(0, 0, 0, 0.0)",
      }}
    >
      <img src={logoBrand} style={imageStyle}></img>
      <div className="d-flex flex-column align-items-center justify-content-center gap-3">
        <h1>404</h1>
        <p style={{ textAlign: "center" }}>
          Lo sentimos.<br></br>No hemos encontrado la pagina que buscas.
        </p>
        <Button version="primary" text="Ir a Fluenti" onClick={handleGoHome} />
      </div>
    </div>
  );
};

export default PageNotFound;

import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/FirebaseContext";
import { useCartContext } from "../context/CartContext";
import {
  useLocation,
  useNavigate,
  useParams,
  useHistory,
} from "react-router-dom";
import axios from "axios";
import { Stepper } from "react-form-stepper";
import reloadIcon from "../images/reload-svg.svg";
import deleteIcon from "../images/delete-svg.svg";
import waves from "../images/wave-footer.svg";
import loadingImg from "../images/loading.svg";
import greenDot from "../images/dot.gif";
import redDot from "../images/dotred.gif";
import checkDot from "../images/check.gif";
import replacementImg from "../images/no-tag-product-replace.png";
import newBottleImg from "../images/no-tag-product-new.png";

import "../styles/PostOrderFeedback.css";
import "../styles/Medias.css";

//Components
import Button from "./UI/Button";

const PostOrderFeedback = (props) => {
  //Constantes y variables
  const { formatCashToString } = useCartContext();
  const { getOrderData, getPaymentLinkInfo } = useFirebase();
  const navigate = useNavigate(); // Obtiene la función de navegación
  const linkParam = useParams(); // Obtiene el parámetro de la URL
  const orderId = linkParam.linkParam; //Bajamos la URL a una variable
  const location = useLocation(); //De donde viene la data si es nueva
  const [actualOrder, setActualOrder] = useState(null); // Cuando comprobamos
  const [data, setData] = useState(null);
  const [emptyState, setEmptyState] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [isSearchValid, setIsSearchValid] = useState(true);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const orderStatusInfo = {
    paymentPending: {
      title: "🕒 Pago pendiente",
      status: "Pedido activo",
    },
    expired: {
      title: "❌ Link de pago vencido",
      status: "Pedido cancelado",
    },
    waitingConfirmation: {
      title: "⚠️ Pago bajo verificación",
      status: "Pedido activo",
    },
    acceptedPayment: {
      title: "✅ Pago aceptado",
      status: "Pedido activo",
    },
    acceptedSubscriptionOrder: {
      title: "✅ Orden recibida",
      status: "Pedido activo",
    },
    gettingReady: {
      title: "✨ Preparando pedido",
      status: "Pedido activo",
    },
    readyToDelivery: {
      title: "🛵 En zona de despacho",
      status: "Pedido activo",
    },
    onDelivery: {
      title: "🛵 En camino a destino",
      status: "Pedido activo",
    },
    delivered: {
      title: "✅ Entregado",
      status: "Pedido completado",
    },
    rejected: {
      title: "❌ No recibido",
      status: "Pedido detenido",
    },
    undeliverable: {
      title: "⚠️ No se pudo entregar",
      status: "Pedido detenido",
    },
    // Agrega más estados según sea necesario
  };

  const h2Style = {
    margin: "0px",
    padding: "0px",
  };

  const waveStyles = {
    minWidth: "100vw",
    margin: "0px",
  };

  const dotStyle = {
    width: "16px",
    height: "16px",
  };

  const imageStyle = {
    height: "64px",
    width: "64px",
  };

  //EFECTO, REDIRECCIONA LA DATA Y VERIFICA SI ES RECIENTE O BUSCA EN FIREBASE
  useEffect(() => {
    const fetchData = async () => {
      // Restablecer los estados relevantes al comienzo de la carga de datos
      setActualOrder(null);
      setData(null);
      setEmptyState(true);
      setOrderStatus("");
      setLoading(false);

      if (location.state) {
        setEmptyState(false);
        setData(location.state.fluentiData);
        setActualOrder(location.state.fluentiData.fluentiId);
        determinateOrderSatus(location.state.fluentiData);
      } else if (orderId && isValidOrderId(orderId)) {
        setEmptyState(false);
        setActualOrder(orderId);
        setLoading(true);

        const cartData = await getOrderData(orderId);
        if (cartData) {
          setData(cartData);
          determinateOrderSatus(cartData);
        } else {
          setLoading(false);
          setData(null);
          console.error("Error on get order info.");
        }
      } else {
        setEmptyState(true);
      }
    };

    fetchData();
  }, [orderId, location.state, getOrderData]);

  function determinateOrderSatus(data) {
    const currentTimestamp = new Date();
    currentTimestamp.setMilliseconds(0);
    const epochTimeInSeconds = Math.floor(data.expirationDate / 1000);
    switch (data.payment) {
      case null:
        setOrderStatus("");
        break;
      case false:
        if (
          currentTimestamp > data.expirationDate &&
          data.status === "paymentPending"
        ) {
          disableLink(data.paymentLinkId);
          setOrderStatus("expired");
          setIsLinkExpired(true);
        } else if (
          currentTimestamp < data.expirationDate &&
          data.status === "paymentPending"
        ) {
          setOrderStatus("paymentPending");
        } else if (
          currentTimestamp < data.expirationDate &&
          data.status === "waitingConfirmation"
        ) {
          setOrderStatus("waitingConfirmation");
        } else {
          setOrderStatus("");
        }
        break;
      case true:
        disableLink(data.paymentLinkId);
        switch (data.status) {
          case "acceptedPayment":
            setOrderStatus("acceptedPayment");
            break;
          case "acceptedSubscriptionOrder":
            setOrderStatus("acceptedPayment");
            break;
          case "gettingReady":
            setOrderStatus("gettingReady");
            break;
          case "readyToDelivery":
            setOrderStatus("readyToDelivery");
            break;
          case "onDelivery":
            setOrderStatus("onDelivery");
            break;
          case "delivered":
            setOrderStatus("delivered");
            break;
          case "rejected":
            setOrderStatus("rejected");
            break;
          case "undeliverable":
            setOrderStatus("undeliverable");
            break;

          default:
            break;
        }
        break;
      default:
        setOrderStatus("");
        break;
    }
  }

  const getOrderStatusTitle = () => {
    return orderStatus !== "" ? orderStatusInfo[orderStatus].title : "";
  };
  const getOrderGeneralStatus = () => {
    return orderStatus !== "" ? orderStatusInfo[orderStatus].status : "";
  };

  const getActiveStep = () => {
    switch (orderStatus) {
      case "paymentPending":
      case "expired":
      case "waitingConfirmation":
        return 0;
      case "acceptedPayment":
      case "gettingReady":
        return 1;
      case "readyToDelivery":
      case "onDelivery":
      case "rejected":
      case "undeliverable":
        return 2;
      case "delivered":
        return 3;
      default:
        return 0;
    }
  };

  const getActiveImage = () => {
    switch (orderStatus) {
      case "expired":
      case "rejected":
      case "undeliverable":
        return redDot;
      case "paymentPending":
      case "waitingConfirmation":
      case "acceptedPayment":
      case "gettingReady":
      case "readyToDelivery":
      case "onDelivery":
        return greenDot;
      case "delivered":
        return checkDot;
      default:
        return 0;
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setIsSearchValid(isValidOrderId(inputValue));
    setSearchValue(inputValue);
  };
  const isValidOrderId = (orderId) => {
    const regex = /^[0-9a-zA-Z]{8}$/;
    return regex.test(orderId);
  };

  const handleBack = () => {
    navigate("/board");
  };

  const handleGoToPaymentLink = () => {
    const newUrl = data.paymentLinkUrl;
    window.open(newUrl, "_blank");
  };

  function searchOrderWithEnter(event) {
    if (event.key === "Enter") {
      if (isSearchValid) {
        const newUrl = `/pedido/${searchValue}`;
        window.location.href = newUrl;
      }
    }
  }

  const handleRefresh = () => {
    setActualOrder(null);
    setData(null);
    setEmptyState(true);
    setOrderStatus("");
    setLoading(false);
    navigate(`/orderSearch/${orderId}`, { replace: true });
  };

  const searchOrder = () => {
    if (isSearchValid) {
      setActualOrder(null);
      setData(null);
      setEmptyState(true);
      setOrderStatus("");
      setLoading(false);
      if (searchValue === orderId) {
        handleRefresh();
      } else {
        const newUrl = `/pedido/${searchValue}`;
        navigate(newUrl, { replace: true });
      }
    }
  };

  const disableLink = async (paymentLinkId) => {
    if (data === null) {
      try {
        if (paymentLinkId !== null) {
          const url = `https://production.wompi.co/v1/payment_links/${paymentLinkId}`;
          const accesskey = "prv_prod_clSh8sJLpHiU5oMVjrtieSJMspzwRUKE";

          const response = await axios.patch(
            url,
            {
              active: false,
            },
            {
              headers: {
                accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${accesskey}`,
              },
            }
          );
        } else {
        }
      } catch (error) {
        console.error("Error on request:", error.response);
      } finally {
        // Independientemente del resultado, detener la carga
      }
    }
  };

  return (
    <>
      <div
        className="p-4 p-md-5 gap-4 d-flex flex-column align-items-md-center"
        style={{ minHeight: "100%" }}
      >
        <div className="d-flex align-items-start" style={{ width: "100%" }}>
          <Button version="primary" text="Volver" onClick={handleBack} />
        </div>
        <div className="orderContents d-flex flex-column flex-fill pb-4">
          <div
            className="d-flex flex-column p-4 gap-3"
            style={{
              backgroundColor: "#FAFAFA",
              borderColor: "#F0F0F0",
              border: "0.5px solid",
            }}
          >
            <h2 style={h2Style}>Rastrear pedido</h2>
            <div className="d-flex flex-column flex-md-row gap-3 py-2 align-items-md-center">
              <input
                type="text"
                value={searchValue}
                onChange={handleInputChange}
                placeholder="Ingresa # de pedido"
                className="flex-fill p-2"
                onKeyDown={searchOrderWithEnter}
              />
              <div
                className={`d-flex gap-2 ${
                  !isSearchValid ? "disabled-forced" : ""
                }`}
              >
                <div className="flex-fill">
                  <Button
                    version="primary"
                    text="Buscar"
                    onClick={searchOrder}
                  />
                </div>
              </div>
            </div>
          </div>
          {emptyState ? (
            <div></div>
          ) : data !== null ? (
            <div className="d-flex flex-column gap-4">
              <div
                className="d-flex flex-column flex-md-row mt-4 p-3 p-md-3 align-items-center align-items-md-end justify-content-between"
                style={{
                  backgroundColor: "#fff",
                  color: "#222",
                  border: "1px solid",
                }}
              >
                <div>
                  <h3 className="m-0">Pedido #{orderId}</h3>
                  <p>Creado: {data.creationDateRead}</p>
                  <div className="d-flex gap-1 align-items-center mt-3 justify-content-center justify-content-md-start mb-3 mb-md-0">
                    <img src={getActiveImage()} style={dotStyle} />
                    <p>{getOrderGeneralStatus()}</p>
                  </div>
                </div>
                {orderStatus !== "expired" && (
                  <div className="mt-2 gap-2 d-flex">
                    <Button
                      version="primary"
                      text="Solicitar ayuda"
                      onClick={handleRefresh}
                    />
                    {orderStatus !== "delivered" &&
                      orderStatus !== "expired" && (
                        <Button
                          version="primary"
                          text="Refrescar"
                          image={reloadIcon}
                          onClick={handleRefresh}
                        />
                      )}
                  </div>
                )}
              </div>
              {orderStatus !== "" && (
                <div className="d-flex flex-column gap-4">
                  <div className="card p-3">
                    <div className="d-flex gap-4 gap-md-2 py-2 flex-fill justify-content-between align-items-center">
                      <p>Estado del pedido: </p>
                      <div className="d-flex flex-column gap-1 align-items-end">
                        <h4>{getOrderStatusTitle()}</h4>
                        {orderStatus === "paymentPending" && (
                          <p style={{ textAlign: "end" }}>
                            ⚠️ Si has pagado, puede tardar hasta 10 min. en
                            reflejarse.
                          </p>
                        )}
                      </div>
                    </div>
                    <hr style={{ width: "100%" }}></hr>
                    <div className="flex-fill" style={{ minWidth: "100%" }}>
                      <Stepper
                        steps={[
                          { label: "Pago" },
                          { label: "Preparación" },
                          { label: "Envío" },
                          { label: "Entrega" },
                        ]}
                        activeStep={getActiveStep()}
                        styleConfig={{
                          activeBgColor: isLinkExpired ? "#222" : "#1464FF",
                          inactiveTextColor: "#BBB",
                          completedBgColor: "#1049B7",
                        }}
                        connectorStyleConfig={{
                          disabledColor: "color-deshabilitado",
                          activeColor: "color-activo",
                          completedColor: "color-completado",
                          stepSize: "3em",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`card py-4 mx-3 justify-content-center align-items-center ${
                      orderStatus !== "paymentPending" ? "d-none" : ""
                    }`}
                    style={{
                      backgroundColor: "#1464FF",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handleGoToPaymentLink}
                  >
                    <h4 style={{ color: "#fff" }}>Ir a Pagar</h4>
                  </div>
                  <div
                    className={`card p-3 ${
                      isLinkExpired || data.payment ? "disabled-forced" : ""
                    }`}
                  >
                    <div className="d-flex flex-fill justify-content-between">
                      <p>Link de pago:</p>
                      <a href={data.paymentLinkUrl}>
                        <p>wompi.com/l/{data.paymentLinkId}</p>
                      </a>
                    </div>
                    <hr></hr>
                    <div className="d-flex flex-fill justify-content-between">
                      <p>Estado del link:</p>
                      <p>
                        {data.payment
                          ? "Cerrado"
                          : isLinkExpired
                          ? "❌ Vencido"
                          : "Disponible"}
                      </p>
                    </div>
                    <div className="d-flex flex-fill justify-content-between">
                      <p>Fecha de vencimiento:</p>
                      <p>{data.expirationDateRead}</p>
                    </div>
                  </div>
                  <div className="card p-3">
                    {data.recharge > 0 && (
                      <div className="d-flex flex-fill align-items-end my-2 justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={replacementImg} style={imageStyle} />
                          <div className="d-flex flex-column ">
                            <p>Recambio Botellón 20lt</p>
                            <p>
                              $
                              {formatCashToString(
                                data.rechargeTotal / data.recharge
                              )}{" "}
                              x{data.recharge} ud
                            </p>
                          </div>
                        </div>
                        <p>$ {formatCashToString(data.rechargeTotal)}</p>
                      </div>
                    )}
                    {data.newSig > 0 && (
                      <div className="d-flex flex-fill align-items-end my-2 justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={newBottleImg} style={imageStyle} />
                          <div className="d-flex flex-column ">
                            <p>Botellón 20lt Nuevo 100%</p>
                            <p>
                              $
                              {formatCashToString(
                                data.newSigTotal / data.newSig
                              )}{" "}
                              x{data.newSig} ud
                            </p>
                          </div>
                        </div>
                        <p>$ {formatCashToString(data.newSigTotal)}</p>
                      </div>
                    )}
                    {data.usedSig > 0 && (
                      <div className="d-flex flex-fill align-items-end my-2 justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={newBottleImg} style={imageStyle} />
                          <div className="d-flex flex-column ">
                            <p>Botellón 20lt Preutilizado</p>
                            <p>
                              $
                              {formatCashToString(
                                data.usedSigTotal / data.usedSig
                              )}{" "}
                              x{data.usedSig} ud
                            </p>
                          </div>
                        </div>
                        <p>$ {formatCashToString(data.usedSigTotal)}</p>
                      </div>
                    )}
                    <hr></hr>
                    <div className="d-flex flex-fill justify-content-between">
                      <p>Estado del pago:</p>
                      <p>
                        {data.payment
                          ? "✅ Pago completado"
                          : isLinkExpired
                          ? "❌ Pago vencido"
                          : "🕒 Pago pendiente"}
                      </p>
                    </div>
                    <div className="d-flex flex-fill justify-content-between">
                      <p>{data.payment ? "Total pagado:" : "Total a pagar:"}</p>
                      <h4>$ {formatCashToString(data.total)}</h4>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className="d-flex flex-fill justify-content-center align-items-center"
              style={{ minHeight: "100%" }}
            >
              {loading ? (
                <lottie-player
                  src="https://lottie.host/6d67d79f-b84c-49b9-abcc-2a5b73c15939/bRX4fEXKs3.json"
                  background="transparent"
                  speed="1"
                  style={{ maxWidth: "240px", maxHeight: "240px" }}
                  direction="1"
                  loop
                  autoplay
                ></lottie-player>
              ) : (
                <h3>Sin resultados</h3>
              )}
            </div>
          )}
        </div>
      </div>
      <img
        src={waves}
        style={waveStyles}
        alt="Wave Footer"
        className="waveStyles fixed-bottom"
      />
    </>
  );
};

export default PostOrderFeedback;

import React, { useState, useEffect } from "react";
import Button from "./Button";
import { useCartContext } from "../../context/CartContext";
import { toast } from "react-toastify";

const Stepper = (props) => {
  const {
    recharge,
    setRecharge,
    newSig,
    setNewSig,
    usedSig,
    setUsedSig,
    setConditionsConfirmation,
  } = useCartContext();

  const [counter, setCounter] = useState(0);

  function increase() {
    switch (props.product) {
      case "recharge":
        if (recharge === 0) {
          toast.info("Recambio de Botellón agregado");
        }
        setRecharge(recharge + 1);
        break;
      case "newSig":
        if (newSig === 0) {
          toast.info("Botellón Nuevo agregado");
        }
        setNewSig(newSig + 1);
        break;
      case "usedSig":
        if (usedSig === 0) {
          toast.info("Botellón Preutilizado agregado");
        }
        setUsedSig(usedSig + 1);
        break;
      default:
        break;
    }
  }

  function decrease() {
    switch (props.product) {
      case "recharge":
        if (recharge === 1) {
          toast.warn("Recambio de Botellón eliminado");
          setConditionsConfirmation(false);
        }
        setRecharge(recharge - 1);
        break;
      case "newSig":
        if (newSig === 1) {
          toast.warn("Botellón Nuevo eliminado");
        }
        setNewSig(newSig - 1);
        break;
      case "usedSig":
        if (usedSig === 1) {
          toast.warn("Botellón Preutilizado eliminado");
        }
        setUsedSig(usedSig - 1);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    switch (props.product) {
      case "recharge":
        setCounter(recharge);
        break;
      case "newSig":
        setCounter(newSig);
        break;
      case "usedSig":
        setCounter(usedSig);
        break;
      default:
        break;
    }
  }, [recharge, newSig, usedSig]);

  const counterFrame = {
    width: "56px",
    backgroundColor: "#E8EEFA",
    transition: "width 16s ease-in-out",
  };

  const h5Style = {
    fontSize: "32px",
    fontWeight: "700",
    margin: "0px 24px",
    padding: "0",
  };
  const h5StyleMobile = {
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px 0px",
    padding: "0",
    minWidth:"56px",
    maxWidth:"56px",
    textAlign:"center"
  };

  return (
    <div className="h-100">
      {counter === 0 && (
        <div className="flex-fill h-100">
          <Button version="primary" text="Agregar" onClick={() => increase()} />
        </div>
      )}
      {counter > 0 && (
        <div className="d-flex w-100 h-100">
          {props.size === "large" ? (
            <div>
              <div
                className="d-flex justify-content-center align-items-center h-100 "
                style={{
                  minWidth: "100px",
                  background: "#99BBFF",
                  color: "#fff",
                }}
                onClick={() => decrease()}
              >
                <h2>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                </h2>
              </div>
            </div>
          ) : (
            <Button version="secondary" text=" - " onClick={() => decrease()} />
          )}
          <div
            className="d-flex align-items-center w-100 justify-content-center"
            style={counterFrame}
          >
            {props.size === "large" ? (
              <h5 className="" style={h5Style}>
                {counter}
              </h5>
            ) : (
              <h5 className="" style={h5StyleMobile}>
                {counter}
              </h5>
            )}
          </div>
          {props.size === "large" ? (
            <div>
              <div
                className="d-flex justify-content-center align-items-center h-100 "
                style={{
                  minHeight: "48px",
                  minWidth: "100px",
                  background: "#1464ff",
                  color: "#fff",
                }}
                onClick={() => increase()}
              >
                <h2>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </h2>
              </div>
            </div>
          ) : (
            <Button version="primary" text=" + " onClick={() => increase()} />
          )}
        </div>
      )}
    </div>
  );
};

export default Stepper;
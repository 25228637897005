import React, { useState, useEffect } from "react";
import Button from "../UI/Button";
import { useFirebase } from "../../context/FirebaseContext";
import { toast } from "react-toastify";
import { useCartContext } from "../../context/CartContext";
import DividerHorizontal from "../UI/DividerHorizontal";

const AddressBar = (props) => {
  const { addresses, selectedAddress, subsInfoData, switchAddress } =
    useFirebase();
  const [isScrolled, setIsScrolled] = useState(false);
  const shadowScroll = {
    boxShadow: isScrolled ? "0 2px 16px rgba(0, 0, 0, 0.05)" : "none",
    zIndex: "1",
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleSelectAddress(address_to_set) {
    switchAddress(address_to_set);
    props.closeSidebar();
    toast.info(
      "Dirección " + addresses[address_to_set].first_line + " seleccionada."
    );
  }

  function countAddresses() {
    // Obtenemos un array de las claves del objeto
    const keys = Object.keys(addresses);
    // Retornamos la longitud del array, que es la cantidad de elementos en el objeto
    return keys.length;
  }

  function countAllSubscriptions(addressId) {
    let count = 0;
    for (let i = 0; i < subsInfoData.length; i++) {
      if (subsInfoData[i].address_id === addressId) {
        count++;
      }
    }
    return count;
  }

  function countActiveRecharges(addressId) {
    let count = 0;
    for (let i = 0; i < subsInfoData.length; i++) {
      const now = new Date();
      const formattedDateObj = new Date(
        subsInfoData[i].active_cicle_end.seconds * 1000 +
          subsInfoData[i].active_cicle_end.nanoseconds / 1000000
      );
      if (
        subsInfoData[i].address_id === addressId &&
        subsInfoData[i].status === "active" &&
        formattedDateObj > now
      ) {
        count++;
      }
    }
    return count;
  }

  function renderPlansAndRecharges(addressIdToFind) {
    return (
      <div className=" d-flex flex-column">
        <div className="d-flex align-items-center gap-2">
          <p
            className="p-0 m-0 d-flex gap-1 align-content-center align-items-center"
            style={{ lineHeight: "72%" }}
          >
            <i
              className="fa fa-caret-right "
              aria-hidden="true"
              style={{ color: "#222" }}
            ></i>
            Planes/Botellones:
          </p>
          <h5 className="m-0 p-0">
            <span
              className="badge rounded-pill text-bg-light"
              style={{ minWidth: "24px" }}
            >
              {countAllSubscriptions(addressIdToFind)}
            </span>
          </h5>
        </div>
        <div className="d-flex align-items-center gap-2">
          <p
            className="p-0 m-0 d-flex gap-1 align-content-center align-items-center"
            style={{ lineHeight: "72%" }}
          >
            <i
              className="fa fa-refresh "
              aria-hidden="true"
              style={{ color: "#222" }}
            ></i>
            Recambios disponibles:
          </p>
          <h5 className="m-0 p-0">
            <span
              className={`badge rounded-pill ${
                countActiveRecharges(addressIdToFind) > 0
                  ? "bg-primary-subtle text-primary"
                  : "text-bg-secondary"
              }`}
              style={{ minWidth: "24px" }}
            >
              {countActiveRecharges(addressIdToFind)}
            </span>
          </h5>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex align-items-center justify-content-between w-100 bg-white sticky-top p-4 "
        style={shadowScroll}
      >
        <h3>Tus direcciones</h3>
        <Button
          version="secondary"
          text="Cerrar"
          onClick={props.closeSidebar}
        ></Button>
      </div>
      <div className="d-flex flex-column text-truncate overflow-visible px-4 pb-5 w-100">
        <hr></hr>
        <h4 className="pb-3">Dirección seleccionada:</h4>
        <div className="d-flex mb-3 justify-content-between gap-5 align-items-center card shadow-sm rounded-2 ">
          <div className="flex-column w-100 p-4 gap-3 d-flex">
            <div className="d-flex gap-0 flex-column w-100 align-items-start text-wrap ">
              <h6 className="d-flex gap-0">
                <span className="badge text-bg-dark">
                  {selectedAddress.address.alias}
                </span>
              </h6>
              <h5
                className="p-0 m-0 gap-0 text-wrap text-primary"
                style={{ fontSize: "18px", fontWeight: "700" }}
              >
                {selectedAddress.address.first_line}
                <br></br>
                {selectedAddress.address.neighborhood},{" "}
                {selectedAddress.address.city}
              </h5>
              <p><strong>{selectedAddress.address.user_details.second_line}</strong></p>
            </div>
            {renderPlansAndRecharges(selectedAddress.address.id)}
          </div>
        </div>
        {addresses && countAddresses() > 1 && (
  <>
    {" "}
    <hr></hr>
    <h4 className="pb-3">Tus otras direcciones: </h4>
    <div className="d-flex flex-column p-4 card rounded-2 shadow-sm overflow-visible">
      {Object.keys(addresses).map(
        (addressKey, index, array) =>
          // Verificar si la dirección actual es la misma que la seleccionada
          selectedAddress.address.id !== addressKey && (
            <div key={addressKey}>
              <div className="address-item d-flex flex-column gap-2">
                <div>
                  <p style={{ fontSize: "14px", color: "#111111", fontWeight:"700" }}>
                    {"Entrega en " + addresses[addressKey].alias}
                  </p>
                  <p className="text-truncate text-primary">
                    <strong>
                      {addresses[addressKey].first_line},{" "}
                      {addresses[addressKey].neighborhood}
                    </strong>
                  </p>
                </div>
                <div className="d-flex w-100 gap-2 justify-content-between align-items-end ">
                  {renderPlansAndRecharges(addressKey)}
                  <Button
                    version="primary"
                    text="Elegir"
                    onClick={() => handleSelectAddress(addressKey)}
                  />
                </div>
              </div>
              {index !== array.length - 1 && (
                <DividerHorizontal
                  height={4}
                  color="#efefef"
                  marginY={24}
                />
              )}
            </div>
          )
      )}
    </div>
  </>
)}

      </div>
    </div>
  );
};

export default AddressBar;

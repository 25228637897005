import React from "react";
import "../styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import waves from "../images/wave-footer.svg";

const Footer = () => {
  const navigate = useNavigate();

  const waveStyles = {
    marginBottom: "-2px",
    minWidth: "101vw",
  };

  function goToOrders() {
    navigate("/pedidos");
  }
  const handleCoverage = () => {
    // navigate("/cobertura");
    navigate("/cobertura");
  };
  const goToLogin = () => {
    // navigate("/cobertura");
    navigate("/ingresar");
  };

  return (
    <footer className="d-flex flex-column gap-0 w-100 overflow-hidden">
      <img
        src={waves}
        style={waveStyles}
        alt="Wave Footer"
        className="waveStyles p-0"
      />
      <div
        className="d-flex flex-fill w-100 pt-5 justify-content-center align-items-center"
        style={{ backgroundColor: "#225bca", minWidth: "100vw" }}
      >
        <div
          className="footerGrid gap-5 pb-5"
          style={{ maxWidth: "1024px", backgroundColor: "#225bca" }}
        >
          <div className="footerItem">
            <h5 className="h6 mb-4">Sobre nosotros</h5>
            <p>
              Somos una empresa líder en innovación. Te brindamos el mejor
              sercvicio de agua purificada! Pide botellones y recambios
              comodamente en línea. Con tecnología de vanguardia y un
              servicio de atención excepcional, disfruta de agua de calidad
              donde la necesites.
            </p>
          </div>
          <div className="footerItem">
            <h5 className="h6 mb-4">Proceso de purificación</h5>
            <ul className=" kilimanjaro_widget">
              <li>
                <p>1. Sedimentos </p>
              </li>
              <li>
                <p>2. Carbón Granular (GAC)</p>
              </li>
              <li>
                <p>3. Carbón en Block (CTO)</p>
              </li>
              <li>
                <p>4. Ósmosis Inversa</p>
              </li>
              <li>
                <p>5. Carbón Activado</p>
              </li>
              <li>
                <p>6. Luz Ultravioleta</p>
              </li>
            </ul>
          </div>
          <div className="footerItem">
            <h5 className="h6 mb-4">Enlaces de interes</h5>
            <ul className="kilimanjaro_links">
              <li className="d-none">
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  Sobre Fluenti
                </a>
              </li>
              <li onClick={handleCoverage}>
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  Zona de cobertura
                </a>
              </li>
              <li onClick={goToOrders} hidden>
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  Rastrear pedido
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  Atención al cliente
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  T&C, datos y privacidad
                </a>
              </li>
              <li onClick={goToLogin}>
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  Ingresa a tu cuenta
                </a>
              </li>
            </ul>
          </div>
          <div className="footerItem">
            <div className="kilimanjaro_single_contact_info">
              <h5 className="h6 mb-4 m-0 mb-3">Contacto</h5>
              <p>
                <a
                  href="https://wa.me/573161232337"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-whatsapp"
                    className="me-2"
                  />
                  +57 316 1232337
                </a>
              </p>
              <p>
                <a href="mailto:flu@fluenti.co" target="_blank">
                  <FontAwesomeIcon icon="envelope" className="me-2" />
                  flu@fluenti.co
                </a>
              </p>
            </div>
            <div className="kilimanjaro_part m-top-15">
              <h5 className="h6 mb-4">Más cerca de ti</h5>
              <ul className="kilimanjaro_social_links">
                <li>
                  <a href="https://web.facebook.com/fluenti.co" target="_blank">
                    <i className="fa fa-facebook" aria-hidden="true"></i>{" "}
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/fluenti.co"
                    target="_blank"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>{" "}
                    Instagram
                  </a>
                </li>
                <li className="d-none">
                  <a href="#">
                    <i className="fa fa-pinterest" aria-hidden="true"></i>{" "}
                    TikTok
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/company/fluenti"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin" aria-hidden="true"></i>{" "}
                    Linkedin
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className=" kilimanjaro_bottom_header_one section_padding_50 text-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>Fluenti® todos los derechos reservados, 2024</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

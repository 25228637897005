import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import replacementImg from "../../images/no-tag-product-replace.png";
import logoImg from "../../images/fluenti-logo-140.svg";
import bgImg from "../../images/bg.jpg";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const navigate = useNavigate();
  function handleBack() {
    navigate("/");
  }
  const replacementImgStyle = {
    height: "220px",
  };
  const logoImgStyle = {
    height: "48px",
  };
  return (
    <div
      className="w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white"
      style={{
        background: "#1464ff",
        background: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center", // La imagen se posicionará en el centro del contenedor
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="position-absolute" style={{ top: "2rem", left: "2rem" }}>
        <Button version="tertiary" text={"Volver"} onClick={handleBack} />
      </div>
      <img
        src={replacementImg}
        style={replacementImgStyle}
        className="my-3"
      ></img>
      <div className="d-flex flex-column gap-2 align-items-center w-100 w-md-25 text-center">
        <h2>¡Gracias!</h2>
        <p>
          Te avisaremos en cuanto estemos disponibles.<br></br>
          <strong>¡Siguenos y no te pierdas nada!</strong>
        </p>
      </div>

      <div className="kilimanjaro_part m-top-15 align-items-center justify-content-center d-flex flex-column">
        <ul className="kilimanjaro_social_links">
          <li>
            <a href="https://web.facebook.com/fluenti.co" target="_blank">
              <FontAwesomeIcon icon="fa-brands fa-facebook" />
              <i className="fa fa-facebook" aria-hidden="true"></i> Facebook
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/fluenti.co">
              <FontAwesomeIcon icon="fa-brands fa-instagram" />
              <i className="fa fa-twitter" aria-hidden="true"></i> Instagram
            </a>
          </li>
          <li className="d-none">
            <a href="#">
              <FontAwesomeIcon icon="fa-brands fa-tiktok" />
              <i className="fa fa-pinterest" aria-hidden="true"></i> TikTok
            </a>
          </li>
          <li>
            <a href="https://linkedin.com/company/fluenti" target="_blank">
              <FontAwesomeIcon icon="fa-brands fa-linkedin" />
              <i className="fa fa-linkedin" aria-hidden="true"></i> Linkedin
            </a>
          </li>
        </ul>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <img src={logoImg} style={logoImgStyle}></img>
      </div>
    </div>
  );
};

export default ThankYouPage;

import React from "react";
import Button from "../UI/Button";
import DividerHorizontal from "../UI/DividerHorizontal";
import checkON from "../../images/check-checkout-on.svg";
import checkOFF from "../../images/check-checkout-off.svg";

const UserCheckout = (props) => {
  const {
    disclosureTitlesClasses,
    disclosurePanelClasses,
    checkOutItemClass,
    userFullName,
    handleNameChange,
    showedPhoneNumber,
    handlePhoneChange,
    handleEmailChange,
    userStatus,
    index,
    handleChangeIndex,
    userEmail
  } = props.data;

  return (
    <div
      as="div"
      className="d-flex flex-column gap-3 mb-3"
      onClick={() => handleChangeIndex(2)}
    >
      <div
        className={`d-flex justify-content-between align-items-center ${disclosureTitlesClasses}`}
      >
        <div className="d-flex gap-2 align-items-center">
          <i className="fa fa-user-circle-o" aria-hidden="true"></i>
          Información de contacto{" "}
        </div>
        <img src={userStatus ? checkON : checkOFF} />
      </div>
      {index === 2 && (
        <div className={disclosurePanelClasses}>
          <div className={checkOutItemClass} style={{ fontSize: "14px" }}>
            <div className="form-floating d-flex flex-column gap-2">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control rounded-3"
                  id="floatingName"
                  placeholder=""
                  value={userFullName}
                  onChange={handleNameChange}
                />
                <label htmlFor="floatingName">Nombre y Apellidos</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control rounded-3"
                  id="floatingPhone"
                  placeholder=""
                  value={showedPhoneNumber}
                  onChange={handlePhoneChange}
                />
                <label htmlFor="floatingPhone">Teléfono</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control rounded-3"
                  id="floatingInput"
                  placeholder=""
                  value={userEmail}
                  style={{ fontSize: "16px" }}
                  onChange={handleEmailChange}
                />
                <label htmlFor="floatingInput">Correo electrónico</label>
              </div>
            </div>
            <DividerHorizontal height={1} color="#ebebeb" marginY={16} />
            <div
              className={`w-25 ${userStatus ? "" : "disabled-forced"}`}
              onClick={() => handleChangeIndex(3)}
            >
              <Button text="Continuar" version="primary" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserCheckout;

import React, { useState, useEffect } from "react";
import notAvailables from "../../images/notAvailables.svg";
import axios from "axios";
import Button from "../UI/Button";
import thanksyou from "../../images/thanksyou.svg";
import botcomings from "../../images/botcomings.gif";
import { useFirebase } from "../../context/FirebaseContext";
import DividerHorizontal from "../UI/DividerHorizontal";

const NotJugsAvailables = (props) => {
  const { checkLeadExists, addNewLead } = useFirebase();
  const [selectedQty2, setSelectedQty2] = useState(null);
  const [formFilled, setFormFilled] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [termsState, setTermsState] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(); // State to store the phone number
  const [showedPhoneNumber, setShowedPhoneNumber] = useState(); // State to store the phone number
  const [sendedData, setSendedData] = useState(false);

  useEffect(() => {
    if (name && email && phone && termsState) {
      setFormFilled(true);
    } else {
      setFormFilled(false);
    }
  }, [name, email, phone, termsState]);

  function handleBack() {
    props.setIsEmptyModalOpen(false);
  }

  async function handleSendInfo() {
    const data = {
      type:"Unavailable",
      name,
      email,
      phone,
      city: "Barranquilla",
      neighborhood: props.getSelectedBarrio(),
      recharge_plan: props.selectedPlan,
      reason: "not-available",
    };

    setSendedData(true);

    // Verificar si el lead ya existe
    const leadExists = await checkLeadExists(email, phone);

    if (!leadExists) {
      // Agregar el nuevo lead a Firebase
      await addNewLead(data);
      // Enviar los datos al webhook utilizando Axios
      axios
        .post(
          "https://hook.us1.make.com/1ayjmd8w8gystmffjgcbcpsgtxric8i8",
          data
        )
        .then((response) => {
          // navigate("/gracias");
        })
        .catch((error) => {
          console.error("Error al enviar los datos:", error);
        });
    } else {
      console.error("Este lead existe");
      setSendedData(true);
    }
  }

  const handlePhoneChange = (event) => {
    // Remove any non-numeric characters from the input value
    const formattedPhoneNumber = event.target.value.replace(/\D/g, "");

    // Format the phone number with spaced groups
    let formattedPhoneNumberWithSpaces = "";
    for (let i = 0; i < formattedPhoneNumber.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumberWithSpaces += " "; // Add a space after the third and sixth character
      }
      formattedPhoneNumberWithSpaces += formattedPhoneNumber[i];
    }

    // Limit the phone number to a maximum of 10 characters
    const truncatedPhoneNumber = formattedPhoneNumberWithSpaces.slice(0, 12);

    // Update the state with the formatted phone number
    setPhoneNumber(formattedPhoneNumber);
    setShowedPhoneNumber(truncatedPhoneNumber);
  };

  const handleConfirmation = () => {
    setTermsState(!termsState);
  };

  return ( 
   
   <div
      className="d-flex flex-column gap-3 overflow-auto border-4"
      style={{ maxHeight: "100vh" }}
    >
      <div className="d-flex">
        <Button version="tertiary" text="Atras" onClick={handleBack} />
      </div>
      {sendedData ? (
        <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
          <div className="w-100 align-content-center justify-content-center d-flex p-4">
            <img src={thanksyou} alt="Expansión Pronto" />
          </div>
          <label className="text-center">
            Hemos tomado tu solicitud, nos comunicamos contigo en cuanto puedas
            hacer uso de nuestros servicios. 🙏
          </label>
          <div className="p-3 w-50 ">
            <Button text="Volver" version="primary" onClick={handleBack} />
          </div>
        </div>
      ) : (
        <>
          <form
            className="d-flex flex-column gap-4 mt-2"
            style={{ marginBottom: "80px" }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center pt-3">
              <div className="w-100 align-items-center justify-content-center d-flex flex-column flex-fill pt-3 pb-2 gap-3">
                <img
                  src={notAvailables}
                  style={{ maxWidth: "220px" }}
                  alt="Expansión Pronto"
                />
                <img
                  src={botcomings}
                  style={{ maxWidth: "220px" }}
                  alt="Expansión Pronto"
                />
              </div>
            </div>
            <p className="h6 text-center px-2 text">
              Déjanos avisarte cuando tengamos planes disponibles y{" "}
              <b>Recibe 10% de Descuento</b> en tu primer pedido.
            </p>

            {/* <div className="d-flex flex-column gap-2">
              <div className="input-group overflow-hidden">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fa fa-user"></i>
                  </div>
                </div>
                <input
                  id="name-intention"
                  name="name-intention"
                  placeholder="Nombre y apellido"
                  type="text"
                  required="required"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fa fa-envelope"></i>
                  </div>
                </div>
                <input
                  id="email-intention"
                  name="email-intention"
                  type="text"
                  className="form-control"
                  required="required"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">+57</div>
                </div>
                <input
                  id="phone-intention"
                  name="phone-intention"
                  placeholder="Telefono (con WhatsApp)"
                  type="text"
                  required="required"
                  className="form-control"
                  aria-describedby="phone-intentionHelpBlock"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column py-3">
                <p className="text-center">
                  ¿Cuantos botellones al mes necesitas?
                </p>
                <div className="d-flex flex-fill gap-2 justify-content-between pt-3">
                  {["1-2", "2-5", "5-10", "10+"].map((qty) => (
                    <div
                      key={qty}
                      className={`qtyButton ${
                        selectedQty2 === qty ? "selected" : ""
                      }`}
                      onClick={() => setSelectedQty2(qty)}
                    >
                      {qty}
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
            <div className="d-flex flex-column gap-2">
              <div className="input-group rounded-2 overflow-hidden">
                <div
                  className="input-group-prepend border-0 bg-light d-flex justify-content-center align-items-center"
                  style={{ height: "48px", width: "48px" }}
                >
                  <div className="input-group-text bg-light border-0">
                    <i className="fa fa-user"></i>
                  </div>
                </div>
                <input
                  id="name-intention"
                  name="name-intention"
                  placeholder="Nombre y apellido"
                  type="text"
                  required="required"
                  className="form-control bg-light border-0"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ height: "48px" }}
                />
              </div>
              <div className="input-group rounded-2 overflow-hidden">
                <div
                  className="input-group-prepend border-0 bg-light d-flex justify-content-center align-items-center"
                  style={{ height: "48px", width: "48px" }}
                >
                  <div className="input-group-text border-0 bg-light">
                    <i className="fa fa-envelope"></i>
                  </div>
                </div>
                <input
                  id="email-intention"
                  name="email-intention"
                  type="text"
                  className="form-control border-0 bg-light"
                  required="required"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ height: "48px" }}
                />
              </div>
              <div className="input-group rounded-2 overflow-hidden">
                <div
                  className="input-group-prepend border-0 bg-light"
                  style={{ height: "48px", width: "48px" }}
                >
                  <div className="input-group-text border-0 bg-light d-flex align-items-center justify-content-center">
                    +57
                  </div>
                </div>
                <input
                  id="phone-intention"
                  name="phone-intention"
                  placeholder="Telefono (con WhatsApp)"
                  type="text"
                  required="required"
                  className="form-control border-0 bg-light"
                  aria-describedby="phone-intentionHelpBlock"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ height: "48px" }}
                />
              </div>
            </div>
            <DividerHorizontal height={1} marginY={4} color="#ebebeb" />
            <div className="d-flex flex-row-reverse gap-3 justify-content-between align-items-center rounded-">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                style={{
                  width: "32px",
                  height: "32px",
                  padding: "0",
                  margin: "0",
                  border: "#1464ff 2px solid",
                }}
                onChange={handleConfirmation}
                checked={termsState}
              />
              <p className="w-100 pe-5 m-0">
                Aceptar los términos y condiciones y tratamiento de
                datos personales.
              </p>
            </div>
          </form>
          <div
            className="bg-white w-100 shadow-lg"
            style={{ position: "absolute", left: "0px", bottom: "0px" }}
          >
            <div
              className={`${
                formFilled ? "" : "disabled-forced"
              } w-100 d-flex align-items-center justify-content-center px-5 py-4`}
            >
              <Button
                version="primary"
                text="Avisarme disponibilidad"
                onClick={handleSendInfo}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NotJugsAvailables;

import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./UI/Button";
import Faq from "react-faq-component";
import logoImg from "../images/fluenti-logo-140.svg";
import Footer from "./Footer";
import Navbar from "./Navbar";

const FAQ = () => {
  const navigate = useNavigate();
  const waveStyles = {
    marginBottom: "-2px",
  };
  const logoImgStyle = {
    height: "48px",
  };
  const data = {
    title: "Preguntas Frecuentes",
    rows: [
      {
        title:
          "¿Qué tipos de agua purificada ofrecerán y cuál será su proceso de purificación?",
        content: `Ofreceremos agua purificada natural y también con diferentes sabores, como limón o menta. Nuestro proceso de purificación constará de 6 pasos: filtración, ósmosis inversa, desinfección UV, filtración de sedimentos, remineralización y ozonización. Esto asegurará un agua de mayor calidad y pureza que el agua embotellada común, eliminando impurezas y contaminantes.`,
      },
      {
        title:
          "¿Cuáles serán los tiempos de entrega y cómo se realizarán los pedidos?",
        content: `El tiempo de entrega estándar será de 24 a 48 horas hábiles después de realizada la compra, dependiendo de la ubicación. Para entregas express, el tiempo podrá ser de 4 a 6 horas con un cargo adicional. Podrás hacer tu pedido de agua purificada a través de nuestra página web o por WhatsApp, seleccionando los productos que desees y proporcionando tus datos de entrega. También ofreceremos la opción de pago contra entrega en efectivo.`,
      },
      {
        title:
          "¿Qué políticas tendrán para garantizar la satisfacción del cliente?",
        content: `Si por algún motivo no estás satisfecho con tu pedido, podrás contactarnos dentro de las 24 horas posteriores a la entrega y con gusto te ofreceremos una solución, ya sea reemplazando el producto o realizando un reembolso. Además, tendremos un equipo de atención al cliente disponible para responder tus preguntas y atender tus necesidades tanto por teléfono como por correo electrónico o WhatsApp.`,
      },
      {
        title:
          "¿Cómo garantizarán la calidad y seguridad del agua durante el transporte y almacenamiento?",
        content: `Utilizaremos vehículos especialmente acondicionados para el transporte de agua purificada, manteniendo las condiciones de higiene y temperatura adecuadas para preservar su calidad hasta el momento de la entrega. Nuestro agua purificada tendrá una vida útil de 6 meses a partir de la fecha de producción, siempre y cuando se mantenga en un lugar fresco y protegido del sol.`,
      },
      {
        title:
          "¿Qué medidas tomarán para reducir el impacto ambiental y proteger los datos personales?",
        content: `Nos comprometeremos con el medio ambiente utilizando envases reciclables y fomentando el retorno de los envases usados para su reutilización o reciclaje. Además, garantizaremos la confidencialidad y seguridad de tus datos personales mediante el uso de tecnologías de encriptación y el cumplimiento de las normativas de protección de datos.`,
      },
      {
        title:
          "¿Habrá opciones de suscripción y descuentos por compras recurrentes?",
        content: `Sí, tendremos programas de suscripción que ofrecerán descuentos especiales para clientes recurrentes. Podrás elegir la frecuencia de entrega que mejor se adapte a tus necesidades y obtener ahorros al realizar compras periódicas.`,
      },
      {
        title:
          "¿Qué opciones tendrán para ajustar los pedidos y entregas según las necesidades del cliente?",
        content: `Podrás indicarnos un horario preferido para la entrega y haremos nuestro mejor esfuerzo para cumplir con tu solicitud, siempre y cuando esté dentro de nuestras posibilidades logísticas. Si necesitas cancelar o modificar tu pedido, te recomendaremos que nos contactes lo antes posible para que podamos verificar si es posible hacer los cambios requeridos antes de que el pedido sea procesado y enviado. Si no te encuentras en casa en el momento de la entrega, podremos dejar el pedido en un lugar seguro previamente acordado o programar una segunda entrega según tu disponibilidad.`,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "#222",
    rowTitleColor: "#444",
    rowContentColor: "grey",
    arrowColor: "#1464ff",
    bgColor: "transparent",
  };

  const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
  };

  function handleBack() {
    navigate("/");
  }

  return (
    <>
      <div
        className="p-4 gap-4 d-flex flex-column align-items-center"
      >
        <Navbar version="EarlyAccess" />
        <div
          className="d-flex flex-column mt-5 py-5 overflow-auto align-items-start justify-content-start flex "
          style={{ maxWidth: "1024px" }}
        >
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;

import React, { useState } from "react";
import Button from "./UI/Button";
import "../styles/SubscriptionsDetails.css";
import bgHeaderImg from "../images/dispensador-agua-purificada.jpg";

import fluentiLogo from "../images/fluenti-logo-140.svg";
import image01 from "../images/plan-x2.png";
import image02 from "../images/plan-x4.png";
import image03 from "../images/plan-x8.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import EarlyIntention from "./subscriptions/EarlyIntention";
import value01 from "../images/plan-x2.png";
import value02 from "../images/plan-x2.png";
import value03 from "../images/plan-x2.png";
import value04 from "../images/plan-x2.png";
import value05 from "../images/plan-x2.png";
import value06 from "../images/plan-x2.png";
import value07 from "../images/plan-x2.png";
import value08 from "../images/plan-x2.png";
import value09 from "../images/plan-x2.png";
import value10 from "../images/plan-x2.png";
import value11 from "../images/plan-x2.png";
import value12 from "../images/plan-x2.png";
import value13 from "../images/plan-x2.png";
import value14 from "../images/plan-x2.png";
import value15 from "../images/plan-x2.png";
import value16 from "../images/plan-x2.png";
import checkSkinny from "../images/check-skinny.svg";

const SubscriptionsDetails = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalCustomStyles = {
    content: {
      borderRadius: "0px",
      border: "#fff 1px solid",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleBack = () => {
    navigate("/");
  };
  const openModal = (modalValue) => {
    setIsModalOpen(true);
  };

  const showPlans = () => {
    document.getElementById("plans").scrollIntoView();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const bgImgStyles = {
    backgroundImage: `url(${bgHeaderImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "360px",
  };



  const productImgStyles = [
    {
      backgroundImage: `url(${image01})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxHeight: "160px",
      minHeight: "160px",
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      backgroundImage: `url(${image02})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxHeight: "160px",
      minHeight: "160px",
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      backgroundImage: `url(${image03})`,
      height: "160px",
      maxWidth: "160px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxHeight: "160px",
      minHeight: "160px",
      minWidth: "160px",
      maxWidth: "160px",
    },
  ];

  const plans = [
    {
      id: 1,
      name: "Plan 2x",
      price: "17.990",
      bottles: 1,
      dispensers: 1,
      refills: 2,
      refillPrice: "9.000",
      isPopular: false,
    },
    {
      id: 2,
      name: "Plan 4x",
      price: "33.990",
      bottles: 1,
      dispensers: 1,
      refills: 4,
      refillPrice: "8.500",
      isPopular: true,
    },
    {
      id: 3,
      name: "Plan 8x",
      price: "63.990",
      bottles: 1,
      dispensers: 1,
      refills: 8,
      refillPrice: "8.200",
      isPopular: false,
    },
  ];

  const Badge = ({ children, color }) => (
    <span
      className="badge"
      style={{ backgroundColor: "#E7EDF8", color: color || "#1464ff" }}
    >
      {children}
    </span>
  );

  const PlanCard = ({
    name,
    price,
    bottles,
    dispensers,
    refills,
    refillPrice,
    isPopular,
  }) => (
    <div
      className={`card w-100 p-0 rounded-4 ${
        isPopular ? "border-2 border-primary-subtle" : "border-1"
      } bg-white text-center`}
    >
      <span className="h4 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">
        {name}
      </span>
      <div className="pt-4">
        <div className="w-100 d-flex align-items-center justify-content-center">
          {name === "Plan 2x" ? (
            <div
              className="rounded-2 overflow-x-hidden w-100 w-md-50 mb-3"
              style={productImgStyles[0]}
            ></div>
          ) : name === "Plan 4x" ? (
            <div
              className="rounded-2 overflow-x-hidden w-100 w-md-50 mb-3"
              style={productImgStyles[1]}
            ></div>
          ) : (
            <div
              className="rounded-2 overflow-x-hidden w-100 w-md-50 mb-3"
              style={productImgStyles[2]}
            ></div>
          )}
        </div>
        <h1 className="h1 font-weight-normal text-primary text-center mb-0">
          <strong>${price}</strong>
        </h1>
        {isPopular && (
          <span
            className="badge text-bg-warning shadow me-2"
            style={{ color: "#ffefc9" }}
          >
            Más popular
          </span>
        )}
        <span className="badge text-bg-light shadow">Pago Mensual</span>
      </div>
      <div className="card-body pt-0">
        <div className="d-flex gap-2 pb-2 pt-4">
          <div className="card p-0 m-0 d-flex flex-column w-100 rounded-3 p-1 py-3">
            <h2 className="h4 p-0 m-0">
              <strong>{bottles}</strong>
            </h2>
            <p>Botellón</p>
          </div>
          <div className="card p-0 m-0 d-flex flex-column w-100 rounded-3 p-1 py-3">
            <h2 className="h4 p-0 m-0">
              <strong>{dispensers}</strong>
            </h2>
            <p>Dispensador</p>
          </div>
          <div
            className="card p-0 m-0 d-flex flex-column w-100 rounded-3 p-1 py-3 text-light"
            style={{
              backgroundColor:
                refills === 2
                  ? "#85adff"
                  : refills === 4
                  ? "#4785ff"
                  : "#1464ff",
            }}
          >
            <h2 className="h4 p-0 m-0">
              <strong>{refills}</strong>
            </h2>
            <p>Recambios</p>
          </div>
        </div>
        <div className="card rounded-2 p-2 m-0 w-100">
          <p className="p-0 m-0 w-100 d-flex justify-content-between align-items-center">
            <span className="d-flex flex-column gap-0 p-0 m-0 text-start">
              Re-cambio adicional:
              <span
                className="p-0 m-0"
                style={{ fontSize: "10px", lineHeight: "10px" }}
              >
                (Por si en el mes necesitas más de {refills})
              </span>
            </span>
            <b>${refillPrice}</b>
          </p>
        </div>
        <hr />
        <div>
          <Button
            text="Quiero este plan"
            version={isPopular ? "primary" : "secondary"}
            onClick={openModal}
          />
        </div>
      </div>
    </div>
  );

  const Header = () => (
    <>
      <div
        className="w-100 align-items-center justify-content-center d-flex flex-column flex-sm-row gap-5 p-0 m-0 mt-5 pt-4 px-4 px-md-0"
        style={{ width: "100%", maxWidth: "1024px" }}
      >
        <div className="p-0 m-0 gap-3 d-flex flex-column w-md-50 w-100">
          <h1
            className="d-flex flex-column gap-2"
            style={{ fontWeight: "800" }}
          >
            Planes de servicio que lo incluyen todo!
          </h1>
          <h3
            className="d-flex flex-wrap gap-2"
            style={{ lineHeight: "300px" }}
          >
            <Badge>✅ Botellón 20 litros</Badge>
            <Badge>✅ Recambios</Badge>
            <Badge>✅ Dispensador</Badge>
            <Badge>✅ Pide en línea ó WhatsApp</Badge>
            <Badge>✅ Paga en línea</Badge>
            <Badge>✅ Domicilio Programado Gratis</Badge>
          </h3>
        </div>
        <div
          className="rounded-2 overflow-x-hidden w-100 w-md-50"
          style={bgImgStyles}
        ></div>
      </div>
    </>
  );


  const values = [
    { image: value01, title: "Recambios en 24h o menos" },
    { image: value02, title: "Planes de recambios por botellón" },
    { image: value03, title: "Compra el plan en línea" },
    { image: value04, title: "2 Botellones incluidos en el plan" },
    { image: value05, title: "Se entrega un botellón" },
    { image: value06, title: "Dispones de botellón para recambio siempre" },
    { image: value07, title: "Se entrega un dispensador recargable" },
    { image: value08, title: "Plataforma Web" },
    { image: value09, title: "Pide recambios por plataforma 24/7" },
    { image: value10, title: "Pide recambios por WhatsApp 24/7" },
    { image: value11, title: "Recibe donde lo necesites" },
    { image: value12, title: "Domicilio Programado Gratis" },
    { image: value13, title: "Domicilio Express Disponible" },
    { image: value14, title: "Monitoreo y seguimiento de los botellones" },
    { image: value15, title: "Soporte 24/7" },
  ];

  const OurOffer = () => {
    return (
      <div className="row" style={{ maxWidth: "1024px" }}>
        {values.map((value, index) => (
          <div key={index} className="col-md-4 col-sm-12">
            <div className="px-4 align-items-center justify-content-center d-flex flex-column gap-2">
              <img
                src={checkSkinny}
                className="card-img-top"
                alt={`Value ${index + 1}`}
                style={{ width: "24px", height: "24px" }}
              />
              <div className="card-body text-center">
                <h5 className="card-title">{value.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className="pt-4 px-md-5 pt-md-5 gap-4 d-flex flex-column align-items-md-center"
      style={{
        minHeight: "100%",
        maxWidth: "100vw",
        width: "100%",
      }}
    >
      <Navbar version="EarlyAccess" />
      <Header />
      <div
        className="mb-5 mt-5 d-flex flex-column flex-lg-row gap-3 px-4 px-md-0"
        style={{ width: "100%", maxWidth: "1024px" }}
        id="plans"
      >
        {plans.map((plan) => (
          <PlanCard
            key={plan.id}
            name={plan.name}
            price={plan.price}
            bottles={plan.bottles}
            dispensers={plan.dispensers}
            refills={plan.refills}
            refillPrice={plan.refillPrice}
            isPopular={plan.isPopular}
          />
        ))}
      </div>
      <h5
        className="w-100 text-center pb-3 pt-5"
        style={{ fontWeight: "400", fontSize: "32px" }}
      >
        Lo que recibes con
        <br />{" "}
        <span style={{ fontWeight: "700", color: "#1464ff" }}>
          nuestros planes
        </span>
      </h5>
      <OurOffer />
      <div className="px-4 px-md-0">

      </div>
      <Footer />
      <Modal
        style={modalCustomStyles}
        closeTimeoutMS={250}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        overlayClassName="overlay-modal"
      >
        <EarlyIntention closeModal={closeModal} email={""} />
      </Modal>
    </div>
  );
};

export default SubscriptionsDetails;

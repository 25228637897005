import React from "react";
import Button from "./UI/Button";
// import CoverageArea from "./CoverageArea";

const ModalContents = (props) => {
  let title = "";
  const content = {
    about: (
      <div className="d-flex flex-column gap-2 my-4">
        <h2>Sobre Fluenti</h2>
      </div>
    ),
    process: (
      <div className="d-flex flex-column gap-2 my-4">
        <h2>Proceso de purificación</h2>
      </div>
    ),
    map: (
      <div className="d-flex flex-column gap-2 my-4 flex-fill">
        {/* <CoverageArea/> */}

        <h2>Cobertura en Barranquilla</h2>
        <div className="flex-fill">
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1UUsbSOEL_Fvcf9OOdk6jcWw4R_lw-Qk&noprof=1&dg=1&authuser=0&dg=1&authuser=0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </div>
    ),
    support: (
      <div className="d-flex flex-column gap-2 my-4">
        <h2>Atención al cliente</h2>
      </div>
    ),
    terms: (
      <div className="d-flex flex-column gap-2 my-4">
        <h2>Legales</h2>
      </div>
    ),
  };

  function getModalContent() {
    switch (props.modalType) {
      case "about":
        return content["about"];
        break;
      case "process":
        return content["process"];
        break;
      case "map":
        return content["map"];
        break;
      case "support":
        return content["support"];
        break;
      case "terms":
        return content["terms"];
        break;

      default:
        break;
    }
  }
  return (
    <div className="p-4 d-flex flex-column" style={{ height: "100%" }}>
      <div className="d-flex">
        <Button version="primary" text="Atras" onClick={props.closeModal} />
      </div>
      {getModalContent()}
    </div>
  );
};

export default ModalContents;

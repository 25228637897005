import React, { useState, useEffect } from "react";
import { useCartContext } from "../../context/CartContext";
import { toast } from "react-toastify";
import Button from "../UI/Button";
import imgBot from "../../images/no-tag-product-empty.png";
import imgBotReplace from "../../images/no-tag-product-replace.png";
import trashIcon from "../../images/icon-trash.svg";
import "../../styles/SubsStepper.css";
import { useNavigate } from "react-router-dom";

const SubsStepper = (props) => {
  const navigate = useNavigate();
  const { subsCart, updateSubsCart, conditionsConfirmation } = useCartContext();

  const [counter, setCounter] = useState(0);

  const refillImage = { maxWidth: "120x", height: "120px" };
  const refillImageGrey = {
    maxWidth: "120x",
    height: "120px",
    filter: "grayscale(1) opacity(0.4)",
  };

  function changeAmount(address_id, type, sub_id) {
    updateSubsCart(address_id, type, sub_id);
  }

  useEffect(() => {
    if (subsCart && subsCart[props.addressId]) {
      const refillOnCart = subsCart[props.addressId].refill_on_cart;
      if (refillOnCart !== undefined) {
        setCounter(refillOnCart);
      }
    }
  }, [subsCart, props.addressId]);

  const counterFrame = {
    width: "56px",
    backgroundColor: "#E8EEFA",
    transition: "width 16s ease-in-out",
  };

  const h5Style = {
    fontSize: "24px",
    fontWeight: "600",
    margin: "0px 24px",
    padding: "0",
  };

  function getStatus() {
    if (subsCart && subsCart[props.addressId]) {
      // Verificar si la suscripción actual contiene la suscripción con el ID especificado
      const suscripcion = subsCart[props.addressId].subscriptions.find(
        (sub) => sub.id === props.subscription
      );
      // Si se encuentra la suscripción, devolver el estado y el valor
      if (suscripcion) {
        const status = suscripcion.status;
        const activeCicleEnd = new Date(
          suscripcion.active_cicle_end.seconds * 1000 +
            suscripcion.active_cicle_end.nanoseconds / 1000000
        );

        const now = new Date();
        if (status === "pending" || activeCicleEnd < now) {
          return "expired";
        } else if (
          status === "active" &&
          activeCicleEnd > now &&
          suscripcion.recharges_availables > 0
        ) {
          return "active";
        } else if (
          status === "active" &&
          activeCicleEnd > now &&
          suscripcion.recharges_availables <= 0
        ) {
          return "active_empty";
        } else if (status === "active-on-progress") {
          return "on_progress";
        } else {
          return "expired";
        }
      }
    }
    return "undefined";
  }

  function isOnCart() {
    const suscripcion = subsCart[props.addressId].subscriptions.find(
      (sub) => sub.id === props.subscription
    );
    return suscripcion.on_cart;
  }

  function getTagTime() {
    const sub = subsCart[props.addressId].subscriptions.find(
      (sub) => sub.id === props.subscription
    );

    const date = formatDateAndDaysUntilEpoch(
      sub.active_cicle_end.seconds,
      sub.active_cicle_end.nanoseconds
    );

    return date.daysLeft;
  }

  const formatDateAndDaysUntilEpoch = (seconds, nanoseconds) => {
    const milliseconds = Math.floor(nanoseconds / 1000000);
    const date = new Date(seconds * 1000 + milliseconds);
    const currentDate = new Date();
    const difference = date.getTime() - currentDate.getTime();
    const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));
    return {
      formattedDate: date.toLocaleDateString("es-ES"),
      daysLeft: daysLeft,
    };
  };

  function goToSubsGateway() {
    navigate(`/mi-suscripcion/${props.subscription}`);

  }

  return (
    <>
      {props.version === "refill" ? (
        getStatus() === "active" ? (
          isOnCart() ? (
            <div
              className="card refill rounded-3 overflow-hidden flex-fill d-flex flex-column justify-content-center align-items-center position-relative pt-4 shadow-sm"
              style={{ border: "#bdbdbd 1px solid" }}
            >
              {getTagTime() < 8 && (
                <h6
                  className="position-absolute"
                  style={{ top: "8px", left: "8px" }}
                >
                  <span className="badge text-bg-light shadow-sm">
                    Vence en {getTagTime()} días
                  </span>
                </h6>
              )}
              <img src={imgBotReplace} style={refillImage} />
              <div
                className="position-absolute bg-white shadow-sm p-2 rounded-circle"
                style={{ top: "8px", right: "8px" }}
              >
                <Button
                  text=""
                  version="flat"
                  image={trashIcon}
                  onClick={() =>
                    changeAmount(
                      props.addressId,
                      "decrease",
                      props.subscription
                    )
                  }
                ></Button>
                {/* <img src={trashIcon} /> */}
              </div>

              <p className="text-body-tertiary">Plan/Botellón</p>
              <h6 className="mb-2 mt-0">
                <strong>{props.subscription.toUpperCase()}</strong>
              </h6>
              <div
                className={`position-aboslute bottom-0 ${
                  conditionsConfirmation === true ? "" : "disabled-forced"
                }`}
                style={{ minWidth: "90%", paddingBottom: "1rem" }}
              >
                <Button text="Recambio agregado" version="flat" />
              </div>
            </div>
          ) : (
            <div className="card refill rounded-3 overflow-hidden flex-fill d-flex flex-column justify-content-center align-items-center position-relative pt-4">
              {getTagTime() < 8 && (
                <h6
                  className="position-absolute"
                  style={{ top: "8px", left: "8px" }}
                >
                  <span className="badge text-bg-light shadow-sm">
                    Vence en {getTagTime()} días
                  </span>
                </h6>
              )}
              <img src={imgBot} style={refillImage} />
              <p className="text-body-tertiary">Plan/Botellón</p>
              <h6 className="mb-3 mt-0">
                <strong>{props.subscription.toUpperCase()}</strong>
              </h6>
              {}
              <div
                className="position-aboslute bottom-0"
                style={{ minWidth: "56%", paddingBottom: "1rem" }}
              >
                <Button
                  text="Pedir Recambio"
                  version="primary"
                  onClick={() =>
                    changeAmount(
                      props.addressId,
                      "increase",
                      props.subscription
                    )
                  }
                />
              </div>
            </div>
          )
        ) : getStatus() === "expired" ? (
          <div className="card bg-body-tertiary refill rounded-3 border-0 overflow-hidden flex-fill d-flex flex-column justify-content-center align-items-center position-relative pt-4">
            <h6
              className="position-absolute"
              style={{ top: "8px", left: "8px" }}
            >
              <span className="badge text-bg-danger shadow">Plan vencido</span>
            </h6>
            <img src={imgBot} style={refillImageGrey} />
            <p className="text-body-tertiary">Plan/Botellón</p>
            <h6 className="mb-3 mt-0">
              <strong>{props.subscription.toUpperCase()}</strong>
            </h6>
            <div
              style={{ minWidth: "56%", paddingBottom: "1rem" }}
              onClick={goToSubsGateway}
            >
              <Button text="Renovar plan" version="secondary" />
            </div>
          </div>
        ) : getStatus() === "on_progress" ? (
          <div
            className="card refill rounded-3 overflow-hidden flex-fill d-flex flex-column justify-content-center align-items-center position-relative pt-4"
            style={{ border: "none", backgroundColor: "#EEF4FF" }}
          >
            {" "}
            <div
              className="d-flex justify-content-center align-items-center pt-3"
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
                maxHeight: "56px",
                maxWidth: "56px",
              }}
            >
              <lottie-player
                src="https://lottie.host/cdefc9a3-ca55-42cc-b95f-877b7b1a55b8/24rhlLPKbn.json"
                background="transparent"
                speed="1"
                style={{ minWidth: "96px", minHeight: "96px" }}
                direction="1"
                loop
                autoplay
              ></lottie-player>
            </div>
            {getTagTime() < 8 && (
              <h6
                className="position-absolute"
                style={{ top: "8px", left: "8px" }}
              >
                <span className="badge text-bg-light shadow-sm">
                  Plan vence en {getTagTime()} días
                </span>
              </h6>
            )}
            <img src={imgBotReplace} style={refillImage} />
            <p className="text-body-tertiary">Plan/Botellón</p>
            <h6 className="mb-2 mt-0">
              <strong>{props.subscription.toUpperCase()}</strong>
            </h6>
            <div
              className="position-aboslute bottom-0"
              style={{
                minWidth: "90%",
                paddingBottom: "1rem",
                pointerEvents: "none",
              }}
            >
              <Button text="Recambio en proceso" version="flat" />
            </div>
          </div>
        ) : getStatus() === "active_empty" ? (
          <div className="card refill rounded-3 overflow-hidden flex-fill d-flex flex-column justify-content-center align-items-center position-relative pt-4">
            {true && (
              <h6
                className="position-absolute"
                style={{ top: "8px", right: "8px" }}
              >
                <span className="badge text-bg-warning shadow-sm">
                  Sin recambios
                </span>
              </h6>
            )}
            <img src={imgBot} style={refillImage} />
            <p className="text-body-tertiary">Plan/Botellón</p>
            <h6 className="mb-3 mt-0">
              <strong>{props.subscription.toUpperCase()}</strong>
            </h6>
            {}
            <div
              className={`position-aboslute bottom-0 ${
                conditionsConfirmation === true ? "" : "disabled-forced"
              }`}
              style={{ minWidth: "90%", paddingBottom: "1rem" }}
            >
              <Button text="Pedir Extra $11.990" version="secondary" />
            </div>
          </div>
        ) : (
          ""
        )
      ) : props.version === "refill-counter" ? (
        <h5 className="h6 h-100 p-0 m-0 d-flex gap-2 align-items-center justify-content-center">
          <span
            className="badge text-bg-primary"
            style={{ width: "20px", height: "20px" }}
          >
            {counter}
          </span>
          <strong>Recambios</strong>
        </h5>
      ) : (
        <div className="h-100">
          {counter === 0 && (
            <div className="flex-fill h-100">
              <Button
                version="primary"
                text="Agregar"
                onClick={() => changeAmount(props.addressId, "increase")}
              />
            </div>
          )}

          {counter > 0 && (
            <div className="d-flex w-100 h-100">
              <Button
                version="stepper"
                text=" - "
                onClick={() => {
                  changeAmount(props.addressId, "decrease");
                }}
              />
              <div
                className="d-flex align-items-center w-100 justify-content-center"
                style={counterFrame}
              >
                <h5 className="" style={h5Style}></h5>
              </div>
              <div
                className={`d-flex justify-content-center align-items-center ${
                  subsCart[props.addressId].refill_availables <= 0
                    ? "disabled-forced"
                    : ""
                }`}
              >
                <Button
                  version="stepper"
                  text=" + "
                  onClick={() => {
                    changeAmount(props.addressId, "increase");
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SubsStepper;

import React, { useState, useEffect } from "react";
import Button from "../UI/Button";
import DividerHorizontal from "../UI/DividerHorizontal";
import resume2x from "../../images/resume-2x.png";
import resume4x from "../../images/resume-4x.png";
import resume8x from "../../images/resume-8x.png";
import checkON from "../../images/check-checkout-on.svg";
import checkOFF from "../../images/check-checkout-off.svg";
import tinyBot from "../../images/tinyBotellon.png";

const OrderCheckOut = (props) => {
  // Desestructuramos las propiedades del objeto data
  const {
    x2Plans,
    x4Plans,
    x8Plans,
    setx2Plans,
    setx4Plans,
    setx8Plans,
    disclosureTitlesClasses,
    disclosurePanelClasses,
    checkOutItemClass,
    orderStatus,
    index,
    handleChangeIndex,
    pricing,
  } = props.data;

  const tinyBotStyles = [
    {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      opacity: 0.08,
    },
    {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      opacity: 0,
    },
  ];

  useEffect(() => {}, [pricing]);

  const [show, setShow] = useState(false);

  const PlanStepperContent = ({ plan }) => (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <div className="d-flex align-items-center gap-2">
        <div className="d-flex flex-column align-items-center">
          <img
            src={plan === "1" ? resume2x : plan === "2" ? resume4x : resume8x}
            style={{ height: "40px" }}
          />
          <p className="text-black-50 " style={{ marginTop: "-10px" }}>
            Plan{" "}
          </p>
        </div>

        <div className=" d-flex flex-column">
          <p className="fw-bold">
            {plan === "1" ? "2" : plan === "2" ? "4" : "8"} Recambios/mes
          </p>
          <p>
            ${" "}
            {plan === "1"
              ? pricing.plans.x2Plan.price.toLocaleString()
              : plan === "2"
              ? pricing.plans.x4Plan.price.toLocaleString()
              : pricing.plans.x8Plan.price.toLocaleString()}{" "}
            ud
          </p>
        </div>
      </div>
      <div className="d-flex gap-3 text-center align-items-center">
        <div className="d-flex align-items-center justify-content-center gap-3">
          <i
            className="fa fa-lg fa-minus-circle text-body-secondary scale-forced"
            aria-hidden="true"
            onClick={() => operateQty(plan, "decrease")}
          ></i>
          <h5 className="p-o m-0 position-relative" style={{ width: "24px" }}>
            <strong>
              <img
                src={tinyBot}
                style={
                  (plan === "1" && x2Plans > 0) ||
                  (plan === "2" && x4Plans > 0) ||
                  (plan === "3" && x8Plans > 0)
                    ? tinyBotStyles[0]
                    : tinyBotStyles[1]
                }
              />
              {plan === "1" ? x2Plans : plan === "2" ? x4Plans : x8Plans}
            </strong>
          </h5>
          <i
            className="fa fa-lg fa-plus-circle text-primary scale-forced"
            aria-hidden="true"
            onClick={() => operateQty(plan, "increase")}
          ></i>
        </div>
      </div>
    </div>
  );

  function operateQty(plan, operation) {
    const actions = {
      1: [x2Plans, setx2Plans],
      2: [x4Plans, setx4Plans],
      3: [x8Plans, setx8Plans],
    };

    const operationValues = {
      increase: 1,
      decrease: -1,
    };

    if (actions[plan] && operationValues[operation] !== undefined) {
      const [getPlan, setPlan] = actions[plan];
      const newValue = getPlan + operationValues[operation];
      if (newValue >= 0) {
        setPlan(newValue);
      }
    }
  }

  return (
    <div
      as="div"
      className="d-flex flex-column gap-3 mb-3"
      onClick={() => handleChangeIndex(1)}
    >
      <div
        className={`d-flex justify-content-between align-items-center ${disclosureTitlesClasses}`}
      >
        <div className="d-flex gap-2 align-items-center">
          <i className="fa fa-shopping-basket" aria-hidden="true"></i>
          Tu pedido
        </div>
        <img src={orderStatus ? checkON : checkOFF} />
      </div>

      {index === 1 && (
        <div className={disclosurePanelClasses}>
          <div className={checkOutItemClass}>
            <div className="p-2 px-3 rounded-3 bg-white mb-4">
              <p>
                <i class="fa fa-info pe-2 text-danger" aria-hidden="true"></i>
                Recuerda, cada plan incluye su botellón.
              </p>
            </div>
            <div className="d-flex flex-column gap-2">
              <PlanStepperContent plan="1" />
              <DividerHorizontal color="#ebebeb" marginY={4} height={0.5} />

              <PlanStepperContent plan="2" />
              <DividerHorizontal color="#ebebeb" marginY={4} height={0.5} />

              <PlanStepperContent plan="3" />
            </div>
            <DividerHorizontal height={1} color="#ebebeb" marginY={16} />
            <div
              className={`w-50  ${orderStatus ? "" : "disabled-forced"}`}
              onClick={() => handleChangeIndex(2)}
            >
              <Button text="Continuar" version="primary" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderCheckOut;

import React, { createContext, useContext, useState, useEffect } from "react";
import { useFirebase } from "./FirebaseContext";
import { toast } from "react-toastify";

export const SalesContext = createContext();
export const useSalesContext = () => useContext(SalesContext);

export const SalesProvider = ({ children }) => {
  const { getPricing, getDoc, doc, db } = useFirebase();
  const [pricing, setPricing] = useState(null);
  const [availability, setAvailability] = useState(null);

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const pricingData = await getPricing();
        setPricing(pricingData);
      } catch (error) {
        console.error("Error fetching pricing data:", error);
      }
    };

    const fetchAvailability = async () => {
      try {
        const docRef = doc(db, "environment", "availability");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setAvailability(docSnap.data());
        } else {
          console.log("No such availabilty");
          return null;
        }
      } catch (error) {
        console.error("Error fetching availability data:", error);
        throw error;
      }
    };

    fetchPricing();
    fetchAvailability();
  }, [getPricing, getDoc, doc]);

  return (
    <SalesContext.Provider value={{ pricing, availability }}>
      {children}
    </SalesContext.Provider>
  );
};
 
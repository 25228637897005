import React, { useEffect, useState } from "react";
import { useCartContext } from "../../context/CartContext";
import { useFirebase } from "../../context/FirebaseContext";
//UI Components
import Stepper from "../UI/Stepper";
import Button from "../UI/Button";
//Images
import replacementImg from "../../images/no-tag-product-replace.png";
//Styles
import "../../styles/Cart.css";
import "../../styles/Medias.css";
import { useNavigate } from "react-router-dom";
import SubsStepper from "./SubsStepper";
import botellonCheck from "../../images/botellon-check.png";
import redDot from "../../images/dotred.gif";

const SideCart = (props) => {
  const { selectedAddress } = useFirebase();
  const navigate = useNavigate();

  const {
    total,
    setTotal,
    itemCount,
    setItemCount,
    pricing,
    recharge,
    setRecharge,
    newSig,
    setNewSig,
    setUsedSig,
    usedSig,
    formatCashToString,
    setConditionsConfirmation,
    conditionsConfirmation,
    subsCart,
    updateSubsCart,
  } = useCartContext();

  const [collapseOpen, setCollapseOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {}, [total, itemCount]);

  const h3Style = {
    fontSize: "18px",
    fontWeight: "700",
    textAlign: "start",
    margin: "0",
  };

  const h3StyleWhite = {
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: "700",
    textAlign: "start",
    margin: "0",
    color: "#fff",
  };
  const h4Style = {
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "start",
    margin: "0",
  };
  const pSmallStyleRight = {
    fontSize: "14px",
    textAlign: "end",
    width: "100%",
    fontWeight: "400",
    color: "#666",
    margin: "0",
  };
  const h4StyleWhite = {
    fontSize: "16px",
    fontWeight: "400",
    textAlign: "start",
    color: "#666",
    margin: "0",
    color: "#fff",
  };
  const productCardStyle = {
    minWidth: "240px",
    position: "relative",
  };
  const productImgStyle = {
    height: "80px",
    "@media (max-width: 600px)": {
      height: "20px", // Ajusta según tus necesidades
      // Otros estilos específicos...
    },
  };
  const totalBarStyle = {
    backgroundColor: "#1464FF",
    color: "#fff",
  };

  const dotStyle = {
    width: "20px",
    height: "20px",
  };

  function cleanCart() {
    setRecharge(0);
    setNewSig(0);
    setUsedSig(0);
    setTotal(0);
    setItemCount(0);
    setConditionsConfirmation(false);
  }

  function goToSlots() {
    navigate("/finalizar-pedido");
  }

  const handleConditionsConfirmation = () => {
    setConditionsConfirmation(!conditionsConfirmation);
    if (!conditionsConfirmation && collapseOpen) {
      setCollapseOpen(false);
    }
  };

  const checkboxLabel =
    subsCart && Object.keys(subsCart).length > 0 && subsCart !== null ? (
      itemCount === 0 ? (
        <div
          className="d-flex align-items-center flex-row-reverse gap-2 pe-5 me-3"
          style={{ width: "100%" }}
        >
          <p
            style={{
              fontSize: "14px",
              color: "#000",
              lineHeight: "14px",
              width: "100%",
            }}
          >
            Los botellones a agregar cumplen con todas las{" "}
            <a href="#terms" style={{ color: "#1464ff" }}>
              condiciones para el recambio
            </a>
            .
          </p>
          <img src={botellonCheck} style={{ height: "32px" }}></img>
        </div>
      ) : itemCount < 2 ? (
        <div
          className="d-flex align-items-center flex-row-reverse gap-2 pe-5 me-3"
          style={{ width: "100%" }}
        >
          <p style={{ fontSize: "14px", lineHeight: "14px", width: "100%" }}>
            Mi botellón <b>cumple</b> con todas las{" "}
            <a href="#terms" style={{ color: "#1464ff" }}>
              condiciones para recambio
            </a>
            .
          </p>
          <img src={botellonCheck} style={{ height: "32px" }}></img>
        </div>
      ) : (
        <div
          className="d-flex align-items-center flex-row-reverse gap-2 pe-5 me-3"
          style={{ width: "100%" }}
        >
          <p style={{ fontSize: "14px", lineHeight: "14px", width: "100%" }}>
            Mis botellones <b>cumplen</b> con todas las{" "}
            <a href="#terms" style={{ color: "#1464ff" }}>
              condiciones para recambio
            </a>
            .
          </p>
          <img
            src={botellonCheck}
            style={{ height: "32px", width: "22px" }}
          ></img>
        </div>
      )
    ) : (
      <></>
    );

  return (
    <div className="d-flex flex-column flex-fill">
      <div className="d-flex align-items-center justify-content-between w-100 bg-white sticky-top p-4 ">
        <h3>
          <strong>Carrito</strong>
        </h3>
        <Button
          version="secondary"
          text="Cerrar"
          onClick={props.closeSidebar}
        ></Button>
      </div>
      <div className="d-flex flex-column gap-2 flex-fill side-cart">
        <div className="d-flex flex-column flex-fill gap-2 justify-content-between">
          {itemCount < 1 ? (
            <div className="px-4">
              <hr />
              <p style={h3Style}>
                No has agregado recambios o productos en está sesión.
              </p>
            </div>
          ) : (
            <div className="d-flex flex-column gap-2">
              {Object.entries(subsCart).map(
                ([addressId, subscription]) =>
                  subscription.refill_on_cart > 0 && (
                    <div
                      key={addressId}
                      style={productCardStyle}
                      className="px-4 d-flex flex-column h-100 align-items-start"
                    >
                      <div className="w-100 overflow-y-hidden">
                        <div className="d-flex align-items-center gap-2 w-100">
                          <i
                            className="fa fa-map-marker fa-lg"
                            aria-hidden="true"
                          ></i>
                          <div className="d-flex flex-column text-truncate">
                            <p style={{ fontSize: "14px" }}>
                              Entrega en {subscription.addressInfo.alias}
                            </p>
                            <div className="d-flex justify-content-center gap-2 align-items-center">
                              <h6 className="text-truncate p-0 m-0 ">
                                <strong>
                                  {subscription.addressInfo.first_line}
                                </strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div
                        id="cart-item"
                        className="d-flex pb-2 justify-content-start gap-2 w-100"
                      >
                        <img
                          src={replacementImg}
                          style={productImgStyle}
                          alt="New Bottle Image"
                        />
                        <div className="d-flex flex-column gap-1 justify-content-center text-nowrap w-100">
                          <p>
                            <span className="badge bg-primary-subtle text-body">
                              Suscripción
                            </span>
                          </p>
                          <h3 style={h4Style} className="d-flex flex-column">
                            <strong>Re-cambio Botellón 20lt</strong>
                          </h3>
                          <h3 style={h4Style} className="pb-2">
                            $0 <s>$9.990/u</s>
                          </h3>
                          <div className="d-flex align-items-start">
                            <SubsStepper
                              product={"recharge"}
                              addressId={subscription.addressInfo.id}
                              version="refill-counter"
                            />
                            <div className="d-flex flex-column justify-content-end align-items-end align-content-end w-100">
                              <h3 style={h3Style}>
                                <strong className="w-100">$ 0</strong>
                              </h3>
                              <p style={pSmallStyleRight}>
                                {"Total " +
                                  subscription.refill_on_cart +
                                  (recharge > 1 ? " uds" : " ud") +
                                  "."}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ height: "8px", backgroundColor: "#f6f6f6" }}
                        className="w-100 my-3"
                      ></div>
                    </div>
                  )
              )}
            </div>
          )}

          <div
            className="sticky-bottom pb-3 w-100 d-flex flex-column px-4 align-items-center justify-content-center"
            style={{
              backgroundColor: "#fff",
              minHeight: "96px",
              borderTop: "#dedede 1px solid",
              boxShadow: "0px -8px 16px -8px rgba(0,0,0,0.24)",
            }}
          >
            <div
              className="mb-3 pb-3 gap-2 mt-3 d-flex flex-column gap-0 justify-content-between w-100"
              style={{
                boxShadow: "inset 0px -64px 16px -64px rgba(100,100,100,0.08)",
                borderBottom: "#eaeaea 1px solid",
              }}
            >
              <div
                className="btn btn-primary m-0 p-0 border-0 d-flex gap-2 align-items-center justify-content-center w-100"
                style={{
                  color: "#222",
                  fontWeight: "700",
                  fontSize: "12px",
                  backgroundColor: "transparent",
                }}
              >
                <p className="d-flex align-items-center justify-content-start w-100 gap-1">
                  <img
                    src={redDot}
                    style={dotStyle}
                    className={`${
                      conditionsConfirmation === false ? "d-flex" : "d-none"
                    }`}
                  />
                  {`${conditionsConfirmation === true ? "✅" : ""}`}
                  <strong>Condiciones para recambios</strong>
                </p>
                {/* Eliminado el ícono de colapso */}
              </div>
              <div>
                {/* Contenido que estaba dentro del colapso */}
                <div className="d-flex flex-row-reverse gap-3 justify-content-between align-items-center rounded-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    style={{
                      width: "32px",
                      height: "32px",
                      padding: "0",
                      margin: "0",
                      border: "#1464ff 2px solid",
                    }}
                    onChange={handleConditionsConfirmation}
                    checked={conditionsConfirmation}
                  />
                  {checkboxLabel}
                </div>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="d-flex flex-column w-50">
                <div className="d-flex gap-2" style={h4Style}>
                  {"Productos: " + itemCount}
                </div>
                <div className="d-flex gap-2 p-0 m-0" style={h3Style}>
                  {"Subtotal: $ " + formatCashToString(total)}
                </div>
              </div>
              <div></div>
              <div
                className={`flex-fill w-50 ${
                  conditionsConfirmation === true && itemCount > 0
                    ? ""
                    : "disabled-forced"
                }`}
                onClick={openModal}
              >
                <Button
                  text="Continuar"
                  version="primary"
                  onClick={goToSlots}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideCart;

import React, { useState, useEffect } from 'react';

const ProgressBar = ({ completion }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(completion);
  }, [completion]);

  return (
    <div>
      <div
        style={{
          width: `${progress}%`,
          height: '12px',
          backgroundColor: '#1464FF',
          transition: 'width 0.5s ease-in-out',
        }}
      />
    </div>
  );
};

export default ProgressBar;

import React, { useState, useEffect } from "react";
import Button from "../UI/Button";
import "../../styles/EarlyIntention.css";
import expansionsoon from "../../images/expansionsoon.svg";
import thanksyou from "../../images/thanksyou.svg";
import axios from "axios";
import { useFirebase } from "../../context/FirebaseContext";

const EarlyIntention = (props) => {
  const { checkLeadExists, addNewLead } = useFirebase();
  const [selectedQty2, setSelectedQty2] = useState(null);
  const [formFilled, setFormFilled] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(); // State to store the phone number
  const [showedPhoneNumber, setShowedPhoneNumber] = useState(); // State to store the phone number
  const [sendedData, setSendedData] = useState(false);

  useEffect(() => {
    if (name && email && phone) {
      setFormFilled(true);
    } else {
      setFormFilled(false);
    }
  }, [name, email, phone]);

  function handleBack() {
    props.closeModal();
  }

  async function handleSendInfo() {
    const data = {
      name,
      email,
      phone,
      city: "Barranquilla",
      neighborhood: props.getSelectedBarrio(),
      recharge_plan: props.selectedPlan,
      reason:"out-of-range"
    };

    setSendedData(true);

    // Verificar si el lead ya existe
    const leadExists = await checkLeadExists(email, phone);

    if (!leadExists) {
      // Agregar el nuevo lead a Firebase
      await addNewLead(data);
      // Enviar los datos al webhook utilizando Axios
      axios
        .post(
          "https://hook.us1.make.com/x0d48yp7o327r1urrzinel77h5e1pth4",
          data
        )
        .then((response) => {
          // navigate("/gracias");
        })
        .catch((error) => {
          console.error("Error al enviar los datos:", error);
        });
    } else {
      console.error("Este lead existe");
      setSendedData(true);
    }
  }

  const handlePhoneChange = (event) => {
    // Remove any non-numeric characters from the input value
    const formattedPhoneNumber = event.target.value.replace(/\D/g, "");

    // Format the phone number with spaced groups
    let formattedPhoneNumberWithSpaces = "";
    for (let i = 0; i < formattedPhoneNumber.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumberWithSpaces += " "; // Add a space after the third and sixth character
      }
      formattedPhoneNumberWithSpaces += formattedPhoneNumber[i];
    }

    // Limit the phone number to a maximum of 10 characters
    const truncatedPhoneNumber = formattedPhoneNumberWithSpaces.slice(0, 12);

    // Update the state with the formatted phone number
    setPhoneNumber(formattedPhoneNumber);
    setShowedPhoneNumber(truncatedPhoneNumber);
  };

  return (
    <div
      className="d-flex flex-column gap-3 overflow-auto border-4"
      style={{ maxHeight: "90vh" }}
    >
      <div className="d-flex">
        <Button version="tertiary" text="Atras" onClick={handleBack} />
      </div>
      {sendedData ? (
        <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
          <div className="w-100 align-content-center justify-content-center d-flex p-4">
            <img src={thanksyou} alt="Expansión Pronto" />
          </div>
          <label className="text-center">
            Hemos tomado tu solicitud, nos comunicamos contigo en cuanto puedas
            hacer uso de nuestros servicios. 🙏
          </label>
          <div className="p-3 w-50 ">
            <Button text="Volver" version="primary" onClick={handleBack} />
          </div>
        </div>
      ) : (
        <>
          <form
            className="d-flex flex-column gap-4 mt-2"
            style={{ marginBottom: "120px" }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center pt-3">
              <div className="w-100 align-content-center justify-content-center d-flex">
                <img
                  src={expansionsoon}
                  style={{ maxWidth: "220px", marginBottom: "24px" }}
                  alt="Expansión Pronto"
                />
              </div>
              <p
                className="text-center px-2"
                style={{ fontSize: "16px", fontWeight: "700" }}
              >
                ¡Ya abrimos y trabajamos a diario para poder entregar en toda la
                ciudad!
              </p>
            </div>
            <p className="text-center px-2">
              Con tu contacto te avisamos en cuanto podamos entregarte.
            </p>
            <div className="d-flex flex-column gap-2">
              <div className="input-group rounded-2 overflow-hidden">
                <div
                  className="input-group-prepend border-0 bg-light d-flex justify-content-center align-items-center"
                  style={{ height: "48px", width: "48px" }}
                >
                  <div className="input-group-text bg-light border-0">
                    <i className="fa fa-user"></i>
                  </div>
                </div>
                <input
                  id="name-intention"
                  name="name-intention"
                  placeholder="Nombre y apellido"
                  type="text"
                  required="required"
                  className="form-control bg-light border-0"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ height: "48px" }}
                />
              </div>
              <div className="input-group rounded-2 overflow-hidden">
                <div
                  className="input-group-prepend border-0 bg-light d-flex justify-content-center align-items-center"
                  style={{ height: "48px", width: "48px" }}
                >
                  <div className="input-group-text border-0 bg-light">
                    <i className="fa fa-envelope"></i>
                  </div>
                </div>
                <input
                  id="email-intention"
                  name="email-intention"
                  type="text"
                  className="form-control border-0 bg-light"
                  required="required"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ height: "48px" }}
                />
              </div>
              <div className="input-group rounded-2 overflow-hidden">
                <div
                  className="input-group-prepend border-0 bg-light"
                  style={{ height: "48px", width: "48px" }}
                >
                  <div className="input-group-text border-0 bg-light d-flex align-items-center justify-content-center">
                    +57
                  </div>
                </div>
                <input
                  id="phone-intention"
                  name="phone-intention"
                  placeholder="Telefono (con WhatsApp)"
                  type="text"
                  required="required"
                  className="form-control border-0 bg-light"
                  aria-describedby="phone-intentionHelpBlock"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ height: "48px" }}
                />
              </div>
            </div>
          </form>
          <div
            className="bg-white w-100 shadow-lg"
            style={{ position: "absolute", left: "0px", bottom: "0px" }}
          >
            <div
              className={`${
                formFilled ? "" : "disabled-forced"
              } w-100 d-flex align-items-center justify-content-center px-5 py-4`}
            >
              <Button
                version="primary"
                text="Pre-inscribirme"
                onClick={handleSendInfo}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EarlyIntention;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";
import Button from "./UI/Button";
import DividerHorizontal from "./UI/DividerHorizontal";
import { useSalesContext } from "../context/SalesContext";
import OrderCheckOut from "./CheckoutComponents/OrderCheckOut";
import UserCheckout from "./CheckoutComponents/UserCheckOut";
import AddressCheckout from "./CheckoutComponents/AddressCheckout";
import SlotsCheckout from "./CheckoutComponents/SlotsCheckout";
import PaymentTerms from "./CheckoutComponents/PaymentTerms";
import loadingImg from "../images/loading.svg";
import { useMediaQuery } from "react-responsive";

const Checkout = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const linkParam = useParams(); // Obtiene el parámetro de la URL
  const { pricing } = useSalesContext();
  const [onboarding, setOnboarding] = useState(true);
  useEffect(() => {
    if (pricing) {
      const onboardingTimer = setTimeout(() => {
        setOnboarding(false);
      }, 800);
    } else {
      setOnboarding(true);
    }
  }, [pricing]);

  const [x2Plans, setx2Plans] = useState(
    linkParam.selectedPlan === "1" ? 1 : 0
  );
  const [x4Plans, setx4Plans] = useState(
    linkParam.selectedPlan === "2" ? 1 : 0
  );
  const [x8Plans, setx8Plans] = useState(
    linkParam.selectedPlan === "3" ? 1 : 0
  );
  const [itemCount, setItemCount] = useState(x2Plans + x4Plans + x8Plans);

  const [index, setIndex] = useState(1);
  const [orderStatus, setOrderStatus] = useState(true);
  const [userStatus, setUserStatus] = useState(false);
  const [addressStatus, setAddressStatus] = useState(false);
  const [slotStatus, setSlotStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [terms, setTerms] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState(false);

  const [userFullName, setUserFullName] = useState("");
  const [userPhone, setUserPhone] = useState();
  const [showedPhoneNumber, setShowedPhoneNumber] = useState(); // State to store the phone number
  const [userEmail, setUserEmail] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userAddressComplement, setUserAddressComplement] = useState("");
  const [selectedTime, setSelectedTime] = useState(""); // Estado para el horario seleccionado
  const [selectedDate, setSelectedDate] = useState(null); // Estado para la fecha seleccionada

  const disclosureTitlesClasses =
    "w-100 py-3 border-1 text-start d-flex justify-content-between bg-white font-weight-bold border-bottom border-top-0 border-left-0 border-right-0 gap-2";
  const disclosurePanelClasses =
    "mt-3 text-start d-flex justify-content-between border-0 bg-transparent";
  const checkOutItemClass = "bg-light w-100 rounded-3 p-3";

  //Efecto que valida la info de contacto
  useEffect(() => {
    const isValidName = userFullName && userFullName.trim().length > 2;
    const isValidPhone =
      userPhone && typeof userPhone === "string" && userPhone.length === 10;
    const isValidEmail = userEmail && isEmail(userEmail);

    if (isValidName && isValidPhone && isValidEmail) {
      setUserStatus(true);
    } else {
      setUserStatus(false);
    }
  }, [userFullName, userPhone, userEmail]);

  //Efecto que valida la dirección de entrega
  useEffect(() => {
    if (userAddress !== null && userAddress !== "") {
      setAddressStatus(true);
    } else {
      setAddressStatus(false);
    }
  }, [userAddress]);

  useEffect(() => {
    if (selectedDate !== null && selectedTime !== "") {
      setSlotStatus(true);
    } else {
      setSlotStatus(false);
    }
  }, [selectedDate, selectedTime]);

  //Efecto que valida los planes agregados
  useEffect(() => {
    const ammount = x2Plans + x4Plans + x8Plans;
    setItemCount(ammount);
    if (ammount <= 0) {
      setOrderStatus(false);
    } else {
      setOrderStatus(true);
    }
  }, [x2Plans, x4Plans, x8Plans]);

  //Efecto que valida los checks
  useEffect(() => {
    if (paymentTerms) {
      setPaymentStatus(true);
    } else {
      setPaymentStatus(false);
    }
  }, [paymentTerms]);

  //Handlres
  const handleNameChange = (e) => {
    setUserFullName(e.target.value);
  };

  const handlePhoneChange = (event) => {
    let formattedPhoneNumber = event.target.value.replace(/\D/g, "");
    formattedPhoneNumber = formattedPhoneNumber.replace(/^(\+57|57)/, "");

    const validRanges = /^(300|3[0-9]{2}|60[1-9]|61[0-9])$/;
    if (
      formattedPhoneNumber.length >= 3 &&
      !validRanges.test(formattedPhoneNumber.slice(0, 3))
    ) {
      setUserPhone(""); // Borrar el número si no está dentro de los rangos válidos
      return;
    }

    formattedPhoneNumber = formattedPhoneNumber.slice(0, 10);
    setUserPhone(formattedPhoneNumber);

    let formattedPhoneNumberWithSpaces = "";
    for (let i = 0; i < formattedPhoneNumber.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumberWithSpaces += " "; // Add a space after the third and sixth character
      }
      formattedPhoneNumberWithSpaces += formattedPhoneNumber[i];
    }

    // Limit the phone number to a maximum of 10 characters
    const truncatedPhoneNumber = formattedPhoneNumberWithSpaces.slice(0, 12);
    setShowedPhoneNumber(truncatedPhoneNumber);
  };

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };

  function isEmail(emailAdress) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAdress.match(regex)) return true;
    else return false;
  }
  const handleAddressChange = (e) => {
    setUserAddress(e.target.value);
  };
  const handleAddressComplementChange = (e) => {
    setUserAddressComplement(e.target.value);
  };

  const handleChangeIndex = (newIndex) => {
    if (newIndex !== index) {
      setIndex(newIndex);
    }
    return;
  };

  function formatTimestamp(timestamp) {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const days = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];

    const dateObj = new Date(timestamp);
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const dayOfWeek = days[dateObj.getDay()];

    return `${month} ${day}/${year}, ${dayOfWeek}`;
  }

  function formatTimestampWithNextMonth(timestamp) {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const days = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];

    const dateObj = new Date(timestamp);
    dateObj.setMonth(dateObj.getMonth() + 1); // Add one month
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const dayOfWeek = days[dateObj.getDay()];

    return `${month} ${day}/${year}, ${dayOfWeek}`;
  }

  return (
    <div className="bg-white">
      {!onboarding ? (
        <div className="d-flex flex-column w-100 align-items-center h-100 bg-white">
          <Navbar version="checkOut" />
          <div
            className="d-flex w-100 vh-100 gap-3 flex-md-row flex-column pt-5"
            style={{ maxWidth: "1024px" }}
          >
            <div
              className="w-100 h-100 d-lg-flex flex-column px-4 pe-5 border-right border-light "
              style={{ maxWidth: isSmallScreen ? "100%" : "50%" }}
            >
              <OrderCheckOut
                data={{
                  x2Plans,
                  x4Plans,
                  x8Plans,
                  setx2Plans,
                  setx4Plans,
                  setx8Plans,
                  disclosureTitlesClasses,
                  disclosurePanelClasses,
                  checkOutItemClass,
                  orderStatus,
                  index,
                  setIndex,
                  handleChangeIndex,
                  pricing,
                }}
              />
              <UserCheckout
                data={{
                  disclosureTitlesClasses,
                  disclosurePanelClasses,
                  checkOutItemClass,
                  userFullName,
                  handleNameChange,
                  showedPhoneNumber,
                  handlePhoneChange,
                  handleEmailChange,
                  userStatus,
                  index,
                  setIndex,
                  handleChangeIndex,
                  userEmail,
                }}
              />
              <AddressCheckout
                data={{
                  disclosureTitlesClasses,
                  disclosurePanelClasses,
                  checkOutItemClass,
                  neighborhood: linkParam.neighborhood,
                  userAddress,
                  handleAddressChange,
                  userAddressComplement,
                  handleAddressComplementChange,
                  handlePhoneChange,
                  handleEmailChange,
                  addressStatus,
                  index,
                  setIndex,
                  handleChangeIndex,
                }}
              />
              <SlotsCheckout
                data={{
                  disclosureTitlesClasses,
                  disclosurePanelClasses,
                  checkOutItemClass,
                  slotStatus,
                  selectedTime,
                  setSelectedTime,
                  selectedDate,
                  setSelectedDate,
                  index,
                  setIndex,
                  handleChangeIndex,
                  itemCount,
                }}
              />
              <PaymentTerms
                data={{
                  disclosureTitlesClasses,
                  disclosurePanelClasses,
                  checkOutItemClass,
                  paymentStatus,
                  paymentTerms,
                  setPaymentTerms,
                  index,
                  setIndex,
                  handleChangeIndex,
                }}
              />
            </div>
            <div className="d-md-none">
              <DividerHorizontal height={24} marginY={8} color="#F8F9FA" />
            </div>
            <div className="w-100 d-flex flex-column gap-3 px-4 px-md-3">
              <div className="card bg-light-subtle shadow p-5 rounded-4 border-0 border-dark-subtle">
                <div className="d-flex w-100 justify-content-between">
                  <p className="h5 fw-bold">Resumen de pedido</p>
                </div>
                <div className="d-flex flex-column gap-2 my-3">
                  {userFullName.length > 6 && (
                    <div className="d-flex w-100 justify-content-between">
                      <p>Cliente </p>
                      <p className="fw-bold">{userFullName}</p>
                    </div>
                  )}
                  {userAddress.length > 6 && (
                    <div className="d-flex w-100 justify-content-between">
                      <p>Direccion de entrega</p>
                      <p className="fw-bold">{userAddress}</p>
                    </div>
                  )}
                  {slotStatus && (
                    <>
                      <div className="d-flex w-100 justify-content-between">
                        <p>Fecha de inicio/entrega</p>
                        <p className="fw-bold text-end">
                          {formatTimestamp(selectedDate)}
                        </p>
                      </div>
                      <div className="d-flex w-100 justify-content-between">
                        <p>Franja de entrega</p>
                        <p className="fw-bold">
                          {selectedTime === "morning" ? (
                            <p className="fw-bold">8am a 12m</p>
                          ) : selectedTime === "afternoon" ? (
                            <p className="fw-bold">2pm a 6pm</p>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </>
                  )}
                  {(slotStatus === true ||
                    addressStatus === true ||
                    userFullName.length > 3) && (
                    <DividerHorizontal height={2} marginY={8} color="#e0e0e0" />
                  )}
                  {x2Plans > 0 && (
                    <>
                      <div className="d-flex w-100 justify-content-between">
                        <p>
                          Planes 2 Recambios
                          <strong>
                            {" - x" + x2Plans + (x2Plans > 1 ? " uds" : " ud")}
                          </strong>
                        </p>

                        <p className="fw-bold">
                          {"$ " +
                            (
                              x2Plans * pricing.plans.x2Plan.price
                            ).toLocaleString()}
                        </p>
                      </div>
                    </>
                  )}
                  {x4Plans > 0 && (
                    <>
                      <div className="d-flex w-100 justify-content-between">
                        <p>
                          Planes 4 Recambios
                          <strong>
                            {" - x" + x4Plans + (x4Plans > 1 ? " uds" : " ud")}
                          </strong>
                        </p>

                        <p className="fw-bold">
                          {"$ " +
                            (
                              x4Plans * pricing.plans.x4Plan.price
                            ).toLocaleString()}
                        </p>
                      </div>
                    </>
                  )}
                  {x8Plans > 0 && (
                    <>
                      <div className="d-flex w-100 justify-content-between">
                        <p>
                          Planes 8 Recambios
                          <strong>
                            {" - x" + x8Plans + (x8Plans > 1 ? " uds" : " ud")}
                          </strong>
                        </p>

                        <p className="fw-bold">
                          {"$ " +
                            (
                              x8Plans * pricing.plans.x8Plan.price
                            ).toLocaleString()}
                        </p>
                      </div>
                    </>
                  )}

                  <DividerHorizontal height={2} marginY={8} color="#e0e0e0" />
                  <div className="d-flex w-100 justify-content-between">
                    <p>Botellones a recibir</p>
                    <p className="fw-bold">{itemCount}</p>
                  </div>

                  {slotStatus && (
                    <div className="d-flex w-100 justify-content-between">
                      <p>Próxima renovación</p>
                      <p className="fw-bold">
                        {formatTimestampWithNextMonth(selectedDate)}
                      </p>
                    </div>
                  )}
                </div>
                <DividerHorizontal height={2} marginY={8} color="#e0e0e0" />{" "}
                <div className="d-flex w-100 justify-content-between pt-3">
                  <p className="h6 fw-bold">Valor de planes</p>
                  <p className="h6 fw-bold">
                    {"$ " +
                      (
                        x2Plans * pricing.plans.x2Plan.price +
                        x4Plans * pricing.plans.x4Plan.price +
                        x8Plans * pricing.plans.x8Plan.price
                      ).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="p-3 p-4 d w-100 m-0 p-0 form-check d-flex align-items-center justify-content-between flex-row-reverse gap-4">
                <input
                  onChange={() => setTerms(!terms)}
                  checked={terms}
                  className="form-check-input d-flex p-0 m-0 position-static"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  style={{
                    border: "#1464ff 3px solid",
                    height: "24px",
                    width: "24px",
                  }}
                />
                <p
                  className="form-check-label w-100 text-wrap text-start p-0 m-0"
                  htmlFor="flexCheckDefault"
                  style={{ fontSize: "15px" }}
                >
                  He leido, comprendo y acepto los términos y condiciones de
                  Fluenti.
                </p>
              </div>
              <DividerHorizontal height={1} color="#ebebeb" marginY={8} />
              <div
                className={`w-100 d-flex align-items-center justify-content-center pb-4 ${
                  orderStatus &&
                  userStatus &&
                  addressStatus &&
                  slotStatus &&
                  paymentStatus &&
                  terms
                    ? ""
                    : "disabled-forced"
                }`}
              >
                <Button
                  text="Enviar solicitud"
                  version="primary"
                  size="large"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
          <img src={loadingImg}></img>
          <p className="h5 fixed-bottom pb-5 w-100 text-center">
            Cargando tu plan...
          </p>
        </div>
      )}
    </div>
  );
};
export default Checkout;

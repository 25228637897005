import React, { useEffect, useState } from "react";
import Button from "./UI/Button";
import "../styles/Slots.css";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../context/CartContext";
import replacementImg from "../images/no-tag-product-replace.png";
import { useFirebase } from "../context/FirebaseContext";


const Slots = () => {
  const afternoonCloseHourToMorningOrder = 11;
  const { userInfo, user } = useFirebase();
  const navigate = useNavigate();
  const {
    itemCount,
    subsCart,
    conditionsConfirmation,
    total,
    resume,
    setResume,
  } = useCartContext();
  const [showComponent, setShowComponent] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // Estado para la fecha seleccionada
  const [slotsConfirm, setSlotsConfirm] = useState(false); // Estado para la fecha seleccionada
  const [currentDateTimeInColombia, setCurrentDateTimeInColombia] =
    useState(null); // Estado para la fecha y hora actual en Colombia
  const [selectedTime, setSelectedTime] = useState(""); // Estado para el horario seleccionado

  const productImgStyle = {
    height: "40px",
  };

  useEffect(() => {
    if (user) {
      return;
    } else {
      navigate("/ingresar");
    }
  }, [user]);

  useEffect(() => {
    setShowComponent(true);
  }, []);

  useEffect(() => {}, [resume]);

  function handleBack() {
    const empty = {};
    setResume(empty);
    navigate("/board", { state: 1 });
  }

  function sendOrder() {
    if (resume) {
      navigate("/gateway");
    }
  }

  function showCheckout() {
    generateResume();
    setSlotsConfirm(true);
    const viewerElement = document.getElementById("viewer");
    if (viewerElement) {
      viewerElement.scrollTop = 0;
    }
  }

  function showSlots() {
    const empty = {};
    setResume(empty);
    setSlotsConfirm(false);
    const viewerElement = document.getElementById("viewer");
    if (viewerElement) {
      viewerElement.scrollTop = 0;
    }
  }

  // Obtener el día de la semana
  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  };

  // Función para seleccionar una fecha
  const handleSelectDate = (date) => {
    setSelectedDate(date);
    if (isBefore11AM()) {
    }
  };

  function getNearestActiveCicleEnd() {
    let nearestActiveCicleEnd = null;

    for (const key in subsCart) {
      if (subsCart.hasOwnProperty(key)) {
        const subscriptions = subsCart[key].subscriptions.filter(
          (sub) => sub.on_cart === true
        );

        subscriptions.forEach((sub) => {
          const activeCicleEnd = new Date(sub.active_cicle_end.seconds * 1000);

          if (
            !nearestActiveCicleEnd ||
            activeCicleEnd < nearestActiveCicleEnd
          ) {
            nearestActiveCicleEnd = activeCicleEnd;
          }
        });
      }
    }

    return nearestActiveCicleEnd;
  }

  const getDeliverysDays = () => {
    const today = new Date(currentDateTimeInColombia);
    const nearestActiveCicleEnd = getNearestActiveCicleEnd(); // Obtener la fecha límite de la función getNearestActiveCicleEnd()
    const days = [];
    let count = 0;
    // Si la hora actual es mayor o igual a 11, incrementamos count para pasar al día siguiente
    if (today.getHours() >= afternoonCloseHourToMorningOrder) {
      count++;
    }
    if (nearestActiveCicleEnd !== null) {
      // Agregamos los próximos lunes y viernes sin incluir el día actual hasta la fecha límite obtenida
      while (days.length < 4 && count < 8) {
        // Agregamos una condición adicional para asegurar que no se exceda el límite de días
        const nextDay = new Date(today);
        nextDay.setDate(nextDay.getDate() + count);
        if (
          nextDay.getTime() <= nearestActiveCicleEnd.getTime() &&
          (nextDay.getDay() === 2 ||
            nextDay.getDay() === 3 ||
            nextDay.getDay() === 1 ||
            nextDay.getDay() === 4 ||
            nextDay.getDay() === 5)
        ) {
          // 1 es lunes, 5 es viernes
          days.push(nextDay);
        }
        // days.push(nextDay);
        count++;
        if (
          nearestActiveCicleEnd.getFullYear() === nextDay.getFullYear() &&
          nearestActiveCicleEnd.getMonth() === nextDay.getMonth() &&
          nearestActiveCicleEnd.getDay() === nextDay.getDay()
        ) {
          return days;
        }
      }
    }
    return days;
  };

  const shouldShowToday = (date) => {
    const today = new Date(currentDateTimeInColombia);
    const cutoffTime = new Date(today);
    cutoffTime.setHours(afternoonCloseHourToMorningOrder, 0, 0); // Establece la hora de corte a las 11 am

    return (
      today.getTime() < cutoffTime.getTime() && // Verifica si la hora actual es anterior al corte
      date.getDate() === today.getDate() && // Verifica si es el día actual
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isToday = (date) => {
    const today = new Date(currentDateTimeInColombia);
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isTomorrow = (date) => {
    const tomorrow = new Date(currentDateTimeInColombia);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      date.getDate() === tomorrow.getDate() &&
      date.getMonth() === tomorrow.getMonth() &&
      date.getFullYear() === tomorrow.getFullYear()
    );
  };

  const getCurrentDateTimeInColombia = () => {
    const currentDate = new Date();
    const colombiaDateTime = new Date(currentDate.getTime());
    colombiaDateTime.setHours(colombiaDateTime.getHours()); // Restar 5 horas para convertir a la hora de Colombia
    return colombiaDateTime;
  };

  // En el useEffect, llama a la función para obtener la fecha y hora actual en Colombia
  useEffect(() => {
    const colombiaDateTime = getCurrentDateTimeInColombia();
    setCurrentDateTimeInColombia(colombiaDateTime);
  }, []);

  const morningSelectedClass =
    selectedTime === "morning" ? "selected-time" : "";
  const afternoonSelectedClass =
    selectedTime === "afternoon" ? "selected-time" : "";
  const disabledClass = isBefore11AM() ? "disabled-forced" : "";

  function formatTimestamp(timestamp) {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const days = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];

    const dateObj = new Date(timestamp);
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const dayOfWeek = days[dateObj.getDay()];

    return `${month} ${day}/${year}, ${dayOfWeek}`;
  }

  // function generateResume() {
  //   const resume = {
  //     user: userInfo,
  //     conditions_confirmation: conditionsConfirmation,
  //     selected_date: selectedDate,
  //     selected_time: selectedTime,
  //     subscriptions_refills: {},
  //   };
  //   for (const key in subsCart) {
  //     if (subsCart.hasOwnProperty(key)) {
  //       if (subsCart[key].refill_on_cart !== 0) {
  //         const subscriptions = subsCart[key].subscriptions.filter(
  //           (sub) => sub.on_cart === true
  //         );
  //         if (subscriptions.length > 0) {
  //           resume.subscriptions_refills[key] = {
  //             ...subsCart[key],
  //             subscriptions,
  //           };
  //         }
  //       }
  //     }
  //   }
  //   return setResume(resume);
  // }

  function generateResume() {
    const resume = {
      user: userInfo,
      conditions_confirmation: conditionsConfirmation,
      selected_date: selectedDate,
      selected_time: selectedTime,
      subscriptions_refills: {},
    };
  
    for (const key in subsCart) {
      if (subsCart.hasOwnProperty(key)) {
        if (subsCart[key].refill_on_cart !== 0) {
          const subscriptions = subsCart[key].subscriptions.filter(
            (sub) => sub.on_cart === true
          );
          if (subscriptions.length > 0) {
            const subscriptionsObj = {}; // Objeto para almacenar las suscripciones como un objeto
            subscriptions.forEach(sub => {
              subscriptionsObj[sub.id] = sub; // Almacenar la suscripción con su ID como clave
            });
            resume.subscriptions_refills[key] = { // Asignar el objeto de suscripciones al objeto de resumen
              ...subsCart[key],
              subscriptions: subscriptionsObj,
            };
          }
        }
      }
    }
    
    return setResume(resume);
  }
  

  const resumeCards = () => {
    return Object.keys(resume.subscriptions_refills).map((key) => (
      <div className="card rounded-3 mt-3 p-3">
        <div key={key}>
          <p className="card-subtitle mb-1 text-muted">
            {resume.subscriptions_refills[key].addressInfo.alias}
          </p>
          <h6 className="card-subtitle mb-2">
            <strong>
              {resume.subscriptions_refills[key].addressInfo.first_line}
            </strong>
          </h6>
          <div className="d-flex gap-2 align-items-center ">
            <img
              src={replacementImg}
              style={productImgStyle}
              alt="New Bottle Image"
            />
            <h6 className="card-text">
              {resume.subscriptions_refills[key].refill_on_cart} recambios
            </h6>
          </div>
        </div>
      </div>
    ));
  };

  function formatCashToString(ammount) {
    return ammount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  function isBefore11AM() {
    const today = new Date(currentDateTimeInColombia);
    if (
      selectedDate &&
      selectedDate.getDay() === today.getDay() &&
      selectedDate.getMonth === today.getMonth
    ) {
      return true;
    }
    return false;
  }

  function isAfter12PM() {
    const today = new Date(currentDateTimeInColombia);
    if (
      selectedDate &&
      selectedDate.getDay() === today.getDay() &&
      selectedDate.getMonth() === today.getMonth() &&
      (today.getHours() >= 11 ||
        (today.getHours() >= 11 && today.getMinutes() >= 0))
    ) {
      return true;
    }
    return false;
  }

  function isAfter7PM() {
    const today = new Date(currentDateTimeInColombia);
    if (
      selectedDate &&
      selectedDate.getDay() === today.getDay() + 1 &&
      selectedDate.getMonth() === today.getMonth() &&
      today.getHours() >= 19 &&
      today.getMinutes() >= 0
    ) {
      return true;
    }
    return false;
  }

  const renderDays = () => {
    const days = getDeliverysDays();
    return days.map((day, index) => (
      <div
        key={index}
        className={`card p-2 rounded-3 align-items-center ${
          selectedDate && selectedDate.toDateString() === day.toDateString()
            ? "selected-date"
            : ""
        }`}
        style={{ minWidth: "120px" }}
        onClick={() => handleSelectDate(day)}
      >
        <p>
          <strong>
            {shouldShowToday(day)
              ? "Hoy"
              : isTomorrow(day)
              ? "Mañana"
              : getDayOfWeek(day)}
          </strong>
        </p>
        <h2 className="h4 p-0 m-0">
          <strong>{day.getDate()}</strong>
        </h2>
        <p>
          {day.toLocaleString("es-CO", { month: "short" }).toLocaleUpperCase()}
        </p>
        <input
          className="form-check-input position-static p-0 m-0"
          type="radio"
          id={`date-check-${index}`} // Cambiar el id para que sea único
          checked={selectedDate && selectedDate.getDate() === day.getDate()}
        />
      </div>
    ));
  };

  const renderHours = () => {
    return (
      <div
        id="hours"
        className={`w-100 d-flex flex-column gap-3 pb-5 pb-5 mb-5 ${
          selectedDate ? "" : "disabled-forced"
        }`}
      >
        <div
          className={`card d-flex flex-row p-4 gap-2 rounded-3 justify-content-between mx-4 mx-md-0 align-items-center ${
            isBefore11AM() || isAfter7PM() ? "disabled-forced" : ""
          } ${morningSelectedClass}`}
        >
          <label
            htmlFor="morning"
            className="w-100 form-check d-flex align-items-center"
          >
            <input
              className="form-check-input"
              type="radio"
              id="morning"
              checked={selectedTime === "morning"}
              onChange={() =>
                setSelectedTime(selectedTime === "morning" ? "" : "morning")
              }
            />
            <div className="d-flex justify-content-between w-100 align-items-center ps-2">
              <div className="w-100 d-flex flex-column gap-0">
                <h5 className="h6 p-0 m-0">
                  <strong>Por la mañana</strong>
                </h5>
                <p className="d-flex gap-1 align-items-center">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                  De 8am a 12m.
                </p>
              </div>
              <h5>
                {isBefore11AM() || isAfter7PM() ? (
                  <span className="badge text-bg-dark">No disponible</span>
                ) : (
                  <span className="badge text-bg-primary">Gratis</span>
                )}
              </h5>
            </div>
          </label>
        </div>
        <div
          className={`card d-flex flex-row p-4 gap-2 rounded-3 justify-content-between mx-4 mx-md-0 align-items-center ${
            isAfter12PM() ? "disabled-forced" : ""
          } ${afternoonSelectedClass}`}
        >
          <label
            htmlFor="afternoon"
            className="w-100 form-check d-flex align-items-center"
          >
            <input
              className="form-check-input"
              type="radio"
              id="afternoon"
              checked={selectedTime === "afternoon"}
              onChange={() =>
                setSelectedTime(selectedTime === "afternoon" ? "" : "afternoon")
              }
            />
            <div className="w-100 d-flex flex-column gap-0">
              <h5 className="h6 p-0 m-0">
                <strong>Por la tarde</strong>
              </h5>
              <p className="d-flex gap-1 align-items-center">
                <i className="fa fa-clock-o" aria-hidden="true"></i>
                De 2pm a 6pm.
              </p>
            </div>
            <h5>
              {isAfter12PM() ? (
                <span className="badge text-bg-dark">No disponible</span>
              ) : (
                <span className="badge text-bg-primary">Gratis</span>
              )}
            </h5>
          </label>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`vh-100 d-flex justify-content-center ${
        showComponent ? "fade-in" : ""
      }`}
    >
      {slotsConfirm === false ? (
        <div
          className="w-100 py-4 px-sm-5"
          style={{ maxWidth: "750px", backgroundColor: "#fff" }}
        >
          <div className="d-flex flex-column " style={{ height: "100%" }}>
            <div className="px-4 px-md-0 d-flex">
              <Button
                version="secondary"
                text={"Volver a carrito"}
                onClick={handleBack}
              />
            </div>
            <div
              className="d-flex flex-column gap-2 my-4 overflow-auto overflow-x-hidden"
              id="viewer"
            >
              <div className="px-4 p-md-0">
                <h3>
                  <strong>Agenda tu entrega</strong>
                </h3>
                <h6 className="d-flex gap-1">
                  <span className="badge text-bg-secondary">
                    {itemCount * 20} kg
                  </span>
                  <span
                    className="badge text-bg-secondary"
                    style={{ minWidth: "24px" }}
                  >
                    {itemCount}
                  </span>
                  Botellones
                </h6>
              </div>
              <hr className="p-0 m-0 pb-3"></hr>

              <div className="px-4 px-md-0 p-0"></div>
              <h5 className="px-4 px-md-0 h5 pb-3 d-flex flex-column">
                📆 Fechas de entrega disponibles
                <label
                  style={{
                    fontSize: "14px",
                    marginLeft: "2rem",
                    marginTop: "4px",
                    color: "#888",
                  }}
                >
                  Limitadas a la expiración de los planes agregados*
                </label>
              </h5>
              <div
                id="dates"
                className="d-flex gap-3 px-4 px-md-0 w-100 overflow-x-scroll"
                style={{ minHeight: "120px" }}
              >
                {renderDays()}
              </div>
              <h5 className="px-4 px-md-0 h5 py-3">🕒 Franja de entrega</h5>
              {renderHours()}
            </div>
          </div>
          <div
            className="fixed-bottom shadow-lg py-3 min-vw-100 d-flex flex-column px-4 align-items-center justify-content-center"
            style={{
              backgroundColor: "#fff",
              minHeight: "96px",
              borderTop: "#dedede 1px solid",
            }}
          >
            <div
              className="w-100 d-flex flex-column gap-sm-0 gap-3 flex-sm-row justify-content-between align-items-center"
              style={{
                maxWidth: "750px",
              }}
            >
              <div
                className="w-100"
                style={{
                  color: "#222",
                  maxWidth: "100%",
                }}
              >
                <p className="color-white d-flex gap-1 align-items-center text-truncate">
                  {!selectedDate && "Escoge una fecha y un horario."}
                  {selectedDate && (
                    <p className="text-truncate">
                      <i className="fa fa-truck pe-2" aria-hidden="true"></i>
                      {formatTimestamp(selectedDate)}
                    </p>
                  )}
                  {selectedTime === "morning" ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "#ffe76e", color: "#000" }}
                    >
                      8am a 12m
                    </span>
                  ) : selectedTime === "afternoon" ? (
                    <span className="badge text-bg-warning">2pm a 6pm</span>
                  ) : (
                    <></>
                  )}
                </p>
                {selectedTime !== "" && (
                  <h6 className="color-white d-flex gap-2 p-0 m-0 align-items-center">
                    <i className="fa fa-usd" aria-hidden="true"></i>
                    <strong>Entrega Gratis</strong>
                  </h6>
                )}
              </div>
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div
                  className={`w-100 ${
                    selectedTime !== "" && selectedDate !== null
                      ? ""
                      : "disabled-forced"
                  }`}
                  onClick={showCheckout}
                >
                  <Button text="Continuar" version="primary" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="w-100 py-4 px-sm-5"
          style={{ maxWidth: "750px", backgroundColor: "#fff" }}
        >
          <div className="d-flex flex-column " style={{ height: "100%" }}>
            <div className="px-4 px-md-0 d-flex">
              <Button version="secondary" text={"Atras"} onClick={showSlots} />
            </div>
            <div
              id="viewer"
              className="d-flex flex-column gap-2 my-4 overflow-auto"
            >
              <div className="px-4 p-md-0">
                <h3>
                  <strong>Resumen de pedido</strong>
                </h3>
              </div>
              <hr className="p-0 m-0 pb-3"></hr>

              <div className="mx-4 mx-sm-0">
                <h5 className="h5">📆 Fecha de entrega</h5>
                <p className="color-white d-flex gap-1 align-items-center text-truncate">
                  {!selectedDate && "Escoge una fecha y un horario."}
                  {selectedDate && (
                    <h6 className="text-truncate">
                      {formatTimestamp(selectedDate)}
                      {selectedTime === "morning" ? (
                        <span
                          className="ms-2 badge"
                          style={{ backgroundColor: "#ffe76e", color: "#000" }}
                        >
                          8am a 12m
                        </span>
                      ) : selectedTime === "afternoon" ? (
                        <span className="ms-2 badge text-bg-warning">
                          2pm a 6pm
                        </span>
                      ) : (
                        <></>
                      )}
                    </h6>
                  )}
                </p>
              </div>
              <div
                style={{ minHeight: "4px", backgroundColor: "#fafafa" }}
                className="w-100 my-3"
              ></div>
              <div className="mx-4 mx-sm-0">
                <h5 className="h5">🛒 Pedido</h5>
                {resumeCards()}
              </div>
              <div
                style={{ minHeight: "4px", backgroundColor: "#fafafa" }}
                className="w-100 my-3"
              ></div>
              <div className="mx-4 mx-sm-0 mb-5 pb-5">
                <h5 className="h5">🧾 Resumen</h5>
                <div className="card rounded-3 bg-light border-0 p-3 w-100 mt-3">
                  <ul>
                    <li className="w-100 d-flex justify-content-between pt-2">
                      <h6 className="p-0 m-0">Productos</h6>
                      <h6 className="p-0 m-0">
                        <strong>{itemCount}</strong>
                      </h6>
                    </li>
                    <li className="w-100 d-flex justify-content-between pt-2">
                      <h6 className="p-0 m-0">Valor productos</h6>
                      <h6 className="p-0 m-0">
                        <del className="text-body-tertiary pe-1">
                          $ {formatCashToString(itemCount * 11990)}
                        </del>
                        <strong>
                          $ {formatCashToString(itemCount * 9990)}
                        </strong>
                      </h6>
                    </li>
                    <li className="w-100 d-flex justify-content-between pt-2">
                      <h6 className="p-0 m-0">Pagado en planes</h6>
                      <h6 className="p-0 m-0 text-success">
                        <strong>
                          $ -{formatCashToString(itemCount * 9990)}
                        </strong>
                      </h6>
                    </li>
                    <li className="w-100 d-flex justify-content-between pt-2">
                      <h6 className="p-0 m-0">Envío</h6>
                      <h6 className="p-0 m-0">
                        <strong>$ 0</strong>
                      </h6>
                    </li>
                    <li className="w-100 border-top border-1 pt-3 mt-3 justify-content-center align-items-lg-center">
                      <h6 className="text-center text-body-secondary p-0 m-0">
                        <strong>Ahorraras un 8%</strong>
                      </h6>
                      <p className="text-center text-body-secondary">
                        <strong>$4.500</strong> al tener planes activos.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="fixed-bottom shadow-lg py-4 min-vw-100 d-flex flex-column px-4 align-items-center justify-content-center"
            style={{
              backgroundColor: "#fff",
              minHeight: "96px",
              borderTop: "#dedede 1px solid",
            }}
          >
            <div
              className="w-100 d-flex flex-column gap-2 flex-sm-row justify-content-center align-items-center"
              style={{
                maxWidth: "750px",
              }}
            >
              <div className="w-100">
                <p>Total</p>
                <h5 className="color-white d-flex gap-1 p-0 m-0 align-items-center">
                  <i className="fa fa-usd" aria-hidden="true"></i>
                  <strong>0</strong>
                </h5>
              </div>
              <div className="w-100">
                <Button
                  text="Realizar pedido"
                  version="primary"
                  onClick={sendOrder}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slots;

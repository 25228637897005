import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Navbar from "./Navbar";
import Footer from "./Footer";
import { useCartContext } from "../context/CartContext";
import logoBrand from "../images/fluenti-logo-140.svg";
import lineSub from "../images/line-sub.svg";
import bgImg from "../images/bg.jpg";
import banner01 from "../images/cta-01-banner.png";
import banner02 from "../images/cta-02-banner.png";
import banner03 from "../images/cta-03-banner.png";
import "../styles/Medias.css";
import "../styles/Landing.css";
import locationIcon from "../images/blue-location-icon.svg";
import Modal from "react-modal";
import ModalContents from "./ModalContents";
import ProductPage from "./ProductPage";
import EarlyAccess from "./subscriptions/EarlyAccess";
import EarlyIntention from "./subscriptions/EarlyIntention";
import { useFirebase } from "../context/FirebaseContext";
import Button from "./UI/Button";
import heroBgAbstract from "../images/heroBgAbstract.png";
import Compare from "./subscriptions/Compare";
import OurOffer from "./subscriptions/OurOffer";
import dropsImg from "../images/drops-blue.jpg";
import slotsBannerImg from "../images/slots-banner.jpg";
import image01 from "../images/plan-x2.png";
import image02 from "../images/plan-x4.png";
import image03 from "../images/plan-x8.png";
import DividerHorizontal from "./UI/DividerHorizontal";
import { useMediaQuery } from "react-responsive";
import { useSalesContext } from "../context/SalesContext";

// import { useNavigate } from "react-router-dom";
// import waterBgImg from "../images/waterbg.jpg";
// import Stepper from "./UI/Stepper";
// import productInfoIcon from "../images/blue-info-icon.svg";
// import waves from "../images/wave-footer.svg";
// import wappIcon from "../images/wapp-icon.svg";
// import mapIcon from "../images/map-icon.svg";
// import fluentiSuperImg from "../images/super-fluenti.svg";
// import replacementImg from "../images/product-replace.png";
// import newBottleImg from "../images/product-new.png";
// import fluentImg from "../images/product-fluent.png";
// import subsImg from "../images/suscribebg.jpg";
// import waterFooterImg from "../images/waterfooter.jpg";

const Landing = (props) => {
  const navigate = useNavigate();
  const { user } = useFirebase();
  const { pricing, availability } = useSalesContext();
  const [plansArray, setPlansArray] = useState([]);

  

  const {
    // pricing,
    // formatCashToString,
    conditionsConfirmation,
    newSig,
    usedSig,
    recharge,
  } = useCartContext();
  // const now = new Date();
  // const year = now.getFullYear();
  // const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [modalType, setModalType] = useState("full-screen-modal");
  const [onboarding, setOnboarding] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(1);
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });
  const isMediumScreen = useMediaQuery({ minWidth: 601, maxWidth: 900 });
  const isLargeScreen = useMediaQuery({ minWidth: 901 });

  useEffect(() => {
    if (pricing && pricing.plans) {
      const sortedPlans = Object.values(pricing.plans).sort((a, b) => a.refills - b.refills);
      setPlansArray(sortedPlans);
      const onboardingTimer = setTimeout(() => {
        setOnboarding(false);
      }, 800);
    } else {
      setOnboarding(true);
    }
  }, [pricing]);

  const landingStyles = {
    backgroundColor: "#fff",
    minHeight: "100vh",
    overflowX: "hidden",
  };
  const bgImgStyles = {
    background: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center", // La imagen se posicionará en el centro del contenedor
    backgroundRepeat: "no-repeat",
  };
  const banner01Style = {
    background: `url(${banner01})`,
    backgroundSize: "cover",
    backgroundPosition: "center", // La imagen se posicionará en el centro del contenedor
    backgroundRepeat: "no-repeat",
    minHeight: "220px",
    minWidth: "311px",
    maxWidth: "496px",
    border: "#fff 1px solid",
    boxShadow: "-1px 4px 8px 0px rgba(0,0,0,0.05)",
  };
  const banner02Style = {
    background: `url(${banner02})`,
    backgroundSize: "cover",
    backgroundPosition: "center", // La imagen se posicionará en el centro del contenedor
    backgroundRepeat: "no-repeat",
    minHeight: "220px",
    minWidth: "311px",
    maxWidth: "496px",
    border: "#fff 1px solid",
    boxShadow: "-1px 4px 8px 0px rgba(0,0,0,0.05)",
  };
  const banner03Style = {
    background: `url(${banner03})`,
    backgroundSize: "cover",
    backgroundPosition: "center", // La imagen se posicionará en el centro del contenedor
    backgroundRepeat: "no-repeat",
    minHeight: "220px",
    minWidth: "311px",
    maxWidth: "496px",
    border: "#fff 1px solid",
    boxShadow: "-1px 4px 8px 0px rgba(0,0,0,0.05)",
  };
  const h1StyleSplash = {
    textTransform: "none",
    color: "#fff",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineWeight: "normal",
    textAlign: "center",
  };
  const logoStyle = {
    maxHeight: "88px",
  };
  const lineSubStyle = {
    maxWidth: "50%",
  };
  const onboardingLocationStyle = {
    position: "absolute",
    bottom: "32px",
  };
  const dropsImageStyle = {
    backgroundImage: `url(${dropsImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "360px",
    backgroundColor: "#d9e8ff",
  };
  const slotsBannerStyle = {
    backgroundImage: `url(${slotsBannerImg})`,
    backgroundSize: "cover",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    minHeight: "360px",
    backgroundColor: "#d9e8ff",
    border: "#eaeaea 1px solid",
  };
  const earlyStyles = {
    background: `url(${heroBgAbstract})`,
    backgroundSize: "cover",
    backgroundPosition: "center", // La imagen se posicionará en el centro del contenedor
    backgroundRepeat: "no-repeat",
  };

  const plans = [
    {
      id: 1,
      name: "2 Recambios al mes",
      price: "17.990",
      bottles: 1,
      dispensers: 1,
      refills: 2,
      refillPrice: "9.000",
      isPopular: false,
    },
    {
      id: 2,
      name: "4 Recambios al mes",

      price: "33.990",
      bottles: 1,
      dispensers: 1,
      refills: 4,
      refillPrice: "8.500",
      isPopular: true,
    },
    {
      id: 3,
      name: "8 Recambios al mes",

      price: "63.990",
      bottles: 1,
      dispensers: 1,
      refills: 8,
      refillPrice: "8.200",
      isPopular: false,
    },
  ];

  const openModal = (modalValue) => {
    switch (modalValue) {
      case "about":
      case "process":
      case "map":
      case "support":
      case "terms":
        setModalValue(modalValue);
        setModalType("full-screen-modal");
        break;
      case "recharge":
      case "newSig":
      case "usedSig":
        setModalValue(modalValue);
        setModalType("partial-modal");
        break;
      case "earlyIntentionForm":
        setModalValue(modalValue);
        setModalType("floating-modal");
      default:
        break;
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalValue("");
  };

  const getModalClassName = () => {
    switch (modalType) {
      case "partial-modal":
        return "partial-modal";
      case "floating-modal":
        return "floating-modal";
      case "full-screen-modal":
        return "full-screen-modal";
      // Agrega más casos según sea necesario
      default:
        return "full-screen-modal";
    }
  };

  // const handleCoverage = () => {
  //   // navigate("/cobertura");
  //   navigate("/cobertura");
  // };

  const navigateToProducts = () => {
    const productsElement = document.getElementById("products");
    if (productsElement) {
      const navbarHeight = 120; // Ajusta según la altura de tu navbar
      const offset = productsElement.getBoundingClientRect().top - navbarHeight;
      window.scrollTo({
        top: window.pageYOffset + offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {}, [
    modalValue,
    modalType,
    conditionsConfirmation,
    newSig,
    usedSig,
    recharge,
  ]);

  useEffect(() => {
    // Establecer onboarding a false después de 2 segundos
    const onboardingTimer = setTimeout(() => {
      setOnboarding(false);
    }, 800);

    // Limpiar temporizador al desmontar el componente
    return () => {
      clearTimeout(onboardingTimer);
    };
  }, []);

  function goToCoverge() {
    navigate("/cobertura");
  }

  function goToBoard() {
    navigate("/board");
  }
  function goToPlans() {
    navigate("/planes");
  }

  function goToPlanCheckout(neighborhood) {
    navigate(`/checkout/${selectedPlan}/${neighborhood}`);
  }

  function moveToPlans() {
    const yOffset = isSmallScreen ? -240 : -800;
    const element = document.getElementById("selectPlanLocation");
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
  function moveToPricing() {
    const yOffset = -300;
    const element = document.getElementById("pricing");
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }

  function choosedPlan(plan) {
    setSelectedPlan(plan);
    const yOffset = isSmallScreen ? -240 : -800;
    const element = document.getElementById("selectPlanLocation");
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }

  function hideBoardLink() {
    var floatingAlert = document.querySelector(".floating-alert");
    if (floatingAlert) {
      // Agregamos una clase para ocultar con la transición CSS
      floatingAlert.classList.add("ocultar");
      // Usamos setTimeout para eliminar el elemento después de la transición
      setTimeout(function () {
        floatingAlert.remove();
      }, 1000); // Esperamos 1 segundo antes de eliminar el elemento
    }
  }

  const DeliverySlots = () => (
    <div
      className="align-items-start justify-content-center d-flex flex-column rounded-5 gap-3 p-md-5 p-4 w-100"
      style={{ ...slotsBannerStyle, maxWidth: "1024px" }}
    >
      <p
        className="p-0 m-0 w-100"
        style={{
          fontSize: "40px",
          fontWeight: "800",
          textAlign: "left",
          color: "#222",
          lineHeight: "96%",
        }}
      >
        ¡Envios programados gratis!
      </p>
      <p
        style={{
          fontSize: "20px",
          fontWeight: "300",
          color: "#222",
          textAlign: "left",
          width: "100%",
        }}
      >
        De lunes a sábados, recibe por la mañana ó por la tarde.
      </p>
      <div>
        <Button text="Empieza hoy!" version="primary" onClick={moveToPlans} />
      </div>
    </div>
  );

  const BannersSection = () => (
    <div
      className="d-flex gap-3 justify-content-start productsContainerStyle px-md-0 w-100"
      style={{ maxWidth: "1024px" }}
    >
      <div
        className="d-flex flex-fill m-0 flex-column p-4 justify-content-center align-items-start rounded-4 gap-4"
        style={banner01Style}
      ></div>
      <div
        className="d-flex flex-fill m-0 flex-column p-4 justify-content-center align-items-start rounded-4 gap-4"
        style={banner02Style}
      ></div>
      <div
        className="d-flex flex-fill m-0 flex-column p-4 justify-content-center align-items-start rounded-4 gap-4 me-2 me-md-0"
        style={banner03Style}
      ></div>
    </div>
  );

  const DiscountSection = () => (
    <div
      className="align-items-center justify-content-center d-flex flex-column rounded-5 p-3 gap-3 w-100"
      style={{ ...dropsImageStyle, maxWidth: "1024px" }}
    >
      <p
        className="p-0 m-0 w-100"
        style={{
          fontSize: "40px",
          fontWeight: "400",
          textTransform: "uppercase",
          textAlign: "center",
          color: "#f0f0f0",
          lineHeight: "96%",
        }}
      >
        Ahorra entre el{" "}
        <b style={{ color: "#fff", fontWeight: "900" }}>
          5% y 30% por botellón
        </b>{" "}
      </p>
      <p
        style={{
          fontSize: "20px",
          fontWeight: "300",
          color: "#fff",
          textAlign: "center",
          width: "100%",
        }}
      >
        De $500 a $7.000 comparado con marcas/servicios similares.
      </p>
      <div>
        <Button
          text="Pide tu botellón"
          version="tertiary"
          onClick={moveToPlans}
        />
      </div>
    </div>
  );

  const LocationsTexts = () => (
    <div style={{ maxWidth: "1024px" }} className="locationsTexts">
      <span style={{ fontWeight: 500, color: "#1464ff" }}>
        Domicilio en Barranquilla
      </span>{" "}
      · Alameda del Rio · Miramar · Tabor · Nogales · Campo Alegre · Villa
      Carolina · Paraiso · Norte-Centro Histórico · Sur Occidente Betania ·
      Prado · Boston · Olaya · Recreo · Modelo · Barrio abajo · Centro ·{" "}
      <span
        onClick={goToCoverge}
        style={{ color: "#1464ff", cursor: "pointer" }}
      >
        Ver cobertura
      </span>
    </div>
  );

  const productImgStyles = [
    {
      backgroundImage: `url(${image01})`,
      height: "120px",
      maxWidth: "120px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxHeight: "120px",
      minHeight: "120px",
      minWidth: "120px",
      maxWidth: "120px",
    },
    {
      backgroundImage: `url(${image02})`,
      height: "120px",
      maxWidth: "120px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxHeight: "120px",
      minHeight: "120px",
      minWidth: "120px",
      maxWidth: "120px",
    },
    {
      backgroundImage: `url(${image03})`,
      height: "120px",
      maxWidth: "120px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxHeight: "120px",
      minHeight: "120px",
      minWidth: "120px",
      maxWidth: "120px",
    },
  ];

  const modalCustomStyles = {
    content: {
      borderRadius: "0px",
      border: "#fff 1px solid",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const PlanCard = ({
    name,
    price,
    bottles,
    dispensers,
    refills,
    refillPrice,
    isPopular,
  }) => (
    <div
      className={`card border-0 shadow-sm p-0 mb-2 w-100 rounded-4 ${
        isPopular ? "border-2 border-primary-subtle" : "border-1"
      } bg-white text-center `}
      style={{ minWidth: "240px" }}
    >
      <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-secondary-subtle">
        <strong>{name}</strong>
      </span>
      <div className="pt-4">
        <div className="w-100 d-flex align-items-center justify-content-center">
          {name === "2 Recambios al mes" ? (
            <div
              className="rounded-2 overflow-x-hidden w-100 w-md-50 mb-3"
              style={productImgStyles[0]}
            ></div>
          ) : name === "4 Recambios al mes" ? (
            <div
              className="rounded-2 overflow-x-hidden w-100 w-md-50 mb-3"
              style={productImgStyles[1]}
            ></div>
          ) : (
            <div
              className="rounded-2 overflow-x-hidden w-100 w-md-50 mb-3"
              style={productImgStyles[2]}
            ></div>
          )}
        </div>
        <h4 className="h4 font-weight-normal  text-center mb-0">
          <strong>${price.toLocaleString()}</strong>
        </h4>
        {isPopular && (
          <span
            className="badge text-bg-warning shadow-sm me-2"
            style={{ color: "#ffefc9" }}
          >
            Más popular
          </span>
        )}
        <span className="badge text-bg-light shadow-sm">Pago Mensual</span>
      </div>
      <div className="d-flex flex-column gap-2 p-4">
        <div className="d-flex flex-row p-0 m-0 w-100 w-100 justify-content-between">
          <p>Botellón</p>
          <p>
            <strong>{bottles}</strong>
          </p>
        </div>
        <DividerHorizontal height={0.5} color="#eaeaea" marginY={2} />
        <div className="d-flex flex-row p-0 m-0 w-100 w-100 justify-content-between">
          <p>Dispensador</p>
          <p className="p-0 m-0">
            <strong>{dispensers}</strong>
          </p>
        </div>
        <DividerHorizontal height={0.5} color="#eaeaea" marginY={2} />
        <div className="d-flex flex-row p-0 m-0 w-100 w-100 justify-content-between">
          <p>Recambios</p>
          <p className="p-0 m-0">
            <strong>{refills}</strong>
          </p>
        </div>
        <DividerHorizontal height={0.5} color="#eaeaea" marginY={2} />
        <div className="d-flex flex-row p-0 m-0 w-100 rounded-3 w-100 justify-content-between">
          <p className="p-0 m-0 w-100 d-flex justify-content-between align-items-center">
            <span className="d-flex flex-column gap-0 p-0 m-0 text-start">
              Re-cambio adicional:
              <span
                className="p-0 m-0"
                style={{ fontSize: "10px", lineHeight: "10px" }}
              >
                (Por si necesitas más de {refills} recambios)
              </span>
            </span>
            <b>${refillPrice.toLocaleString()}</b>
          </p>
        </div>
        <div className="pt-4">
          <Button
            text="Quiero este plan"
            version={isPopular ? "primary" : "secondary"}
            onClick={() =>
              choosedPlan(refills === 2 ? 1 : refills === 4 ? 2 : 3)
            }
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="landing-content">
      <div className={` ${onboarding ? "onboarding" : ""}`} style={bgImgStyles}>
        <div
          className={`onboarding-content d-flex flex-column ${
            onboarding ? "" : "hide"
          }`}
          style={{
            transition: "opacity .2s ease-in-out",
          }}
        >
          <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
          <p style={h1StyleSplash} className="mt-2">
            Agua purificada en línea
          </p>
          <img src={lineSub} alt="Line Sub" style={lineSubStyle} />
          <div
            id="location"
            className="d-flex  gap-2 align-items-center"
            style={onboardingLocationStyle}
          >
            <img src={locationIcon} alt="Location Icon" />
            Barranquilla
          </div>
        </div>
      </div>
      {!onboarding && (
        <div
          style={{ ...landingStyles, opacity: 1, visibility: "visible" }}
          className="d-flex flex-column  landingStyles gap-5 mb-5"
        >
          <Navbar
            version="EarlyAccess"
            goToProductsCallback={navigateToProducts}
          />
          <div
            className="min-vw-100 d-flex px-4 px-md-0 pt-5 justify-content-center "
            style={{ ...earlyStyles }}
          >
            <EarlyAccess
              openModal={openModal}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              goToPlanCheckout={goToPlanCheckout}
            />
          </div>
          <section className="flex-fill d-flex m-4 m-md-0 justify-content-center my-0 py-0 py-md-4 ">
            <div
              style={{ maxWidth: "1024px" }}
              className=" w-100 d-flex flex-column gap-4 justify-content-center align-items-center"
            >
              <h5
                className="w-100 text-center pb-3"
                style={{ fontWeight: "400", fontSize: "32px" }}
              >
                ¿Cómo funcionan
                <br />{" "}
                <span style={{ fontWeight: "700", color: "#1464ff" }}>
                  nuestros servicios?
                </span>
              </h5>
              <OurOffer />
            </div>
          </section>
          <section
            className="flex-fill d-flex justify-content-center align-items-lg-center my-0 py-5"
            style={{ backgroundColor: "#fbfbfb" }}
          >
            <div
              id=""
              style={{ maxWidth: "1024px" }}
              className="w-100 d-flex flex-column gap-4 justify-content-center align-items-center"
            >
              <h5
                className="w-100 text-center py-5"
                style={{ fontWeight: "400", fontSize: "32px" }}
              >
                Conoce nuestros
                <br />{" "}
                <span style={{ fontWeight: "700", color: "#1464ff" }}>
                  planes de recambios
                </span>
              </h5>
              <div
                className="d-flex px-4 gap-3 px-md-0 overflow-visible overflow-x-scroll"
                style={{
                  width: "100vw",
                  maxWidth: isSmallScreen ? "1024px" : "100%",
                }}
                id="pricing"
              >
                {plansArray.map((plan) => (
                  <PlanCard
                    key={plan.id}
                    name={plan.name}
                    price={plan.price}
                    bottles={plan.bottles}
                    dispensers={plan.dispensers}
                    refills={plan.refills}
                    refillPrice={plan.refillPrice}
                    isPopular={plan.isPopular}
                  />
                ))}
              </div>
              <h5
                className="w-100 text-center pb-5 pt-4"
                style={{ fontWeight: "400", fontSize: "32px" }}
              >
                Todos los beneficios
                <br />{" "}
                <span style={{ fontWeight: "700", color: "#1464ff" }}>
                  de nuestros planes
                </span>
              </h5>
              <Compare />
              <div>
                <Button
                  text="Ver Planes de Servicio"
                  version="primary"
                  onClick={moveToPricing}
                />
              </div>
            </div>
          </section>

          <section className="flex-fill d-flex gap-5 flex-column m-4 m-md-0 justify-content-center align-items-center my-md-5 ">
            <h5
              className="w-100 text-center"
              style={{ fontWeight: "400", fontSize: "32px" }}
            >
              Empieza hoy con
              <br />{" "}
              <span style={{ fontWeight: "700", color: "#1464ff" }}>
                Fluenti
              </span>
            </h5>
            <DiscountSection />
            <DeliverySlots />
            <BannersSection />
            <LocationsTexts />
          </section>

          {user ? (
            <div className="floating-alert shadow-lg rounded-3">
              <div className="close-icon " onClick={hideBoardLink}>
                <i className=" fa-lg fa fa-times-circle" aria-hidden="true"></i>
              </div>
              <div className="w-100 bg-white d-flex p-3 rounded-3">
                <div className="w-100">
                  <h6 className="p-0 m-0">
                    <strong>Acceso anticipado</strong>
                  </h6>
                  <p>Ingresa desde aquí a tu cuenta</p>
                </div>
                <div>
                  <Button
                    text="Ingresar"
                    version="primary"
                    onClick={goToBoard}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <section id="footerMenu">
            <Footer />
          </section>
          {/* Modal */}
          <Modal
            closeTimeoutMS={250}
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={true}
            overlayClassName="overlay-modal"
            className={getModalClassName()}
          >
            {(modalValue === "about" ||
              modalValue === "process" ||
              modalValue === "map" ||
              modalValue === "support" ||
              modalValue === "terms") && (
              <ModalContents closeModal={closeModal} modalType={modalValue} />
            )}

            {(modalValue === "recharge" ||
              modalValue === "newSig" ||
              modalValue === "usedSig") && (
              <ProductPage closeModal={closeModal} modalType={modalValue} />
            )}
            {modalValue === "earlyIntentionForm" && (
              <EarlyIntention closeModal={closeModal} modalType={modalValue} />
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};
export default Landing;

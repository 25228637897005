import firebaseApp from "../credentials.js";
import { createContext, useContext, useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  getDoc,
  addDoc,
  doc,
  query,
  where,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  limit,
  startAt,
  endBefore,
  startAfter,
  endAt,
} from "firebase/firestore";
import {
  getAuth,
  /*createUserWithEmailAndPassword,*/
  signInWithEmailAndPassword,
  /*updateEmail,
    updatePassword,
    sendPasswordResetEmail,*/
  signOut,
} from "firebase/auth";
import loadingImg from "../images/loading.svg";

// import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

// const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

// const storage = getStorage(firebaseApp);

export const FirebaseContext = createContext();
export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [subsInfoData, setSubsInfoData] = useState(null);
  const [pending, setPending] = useState(true);
  const [addresses, setAddresses] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [recentsOrders, setRecentsOrders] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [ordersPerPage] = useState(4);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        setPending(false);

        // Obtener la referencia de la colección 'users' y filtrar por el correo electrónico del usuario
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", user.email));

        try {
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            // Obtener y actualizar userInfo
            setUserInfo(doc.data());

            // Llamar a los nuevos métodos para obtener y actualizar direcciones y suscripciones
            await updateSubsInfoData(doc.id);
            await updateAddresses(doc.id);
          });
        } catch (error) {
          console.error("Error al obtener datos del usuario:", error);
        }
      }
    });
  }, []);

  const addNewLead = async (leadData) => {
    try {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  
      const primaryData = leadData.email || leadData.phone || leadData.name;
      const documentId = `${primaryData}-${formattedDate}`;
  
      const docRef = await setDoc(doc(db, "users_leads", documentId), leadData);
      console.log("Document written with ID: ", documentId);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const checkLeadExists = async (email, phone) => {
    const q = query(
      collection(db, "users_leads"),
      where("email", "==", email),
      where("phone", "==", phone)
    );

    try {
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking lead existence: ", error);
      return false;
    }
  };
  

  const updateAddresses = async (userId) => {
    setAddresses(null);
    console.log(">>>>>>>>>>> buscando addresses");
    const userRef = doc(db, "users", userId);
    const addressesRef = collection(userRef, "addresses");

    try {
      const addressesSnapshot = await getDocs(addressesRef);
      const addressesData = {};

      addressesSnapshot.forEach((addressDoc) => {
        addressesData[addressDoc.id] = addressDoc.data();
      });

      setAddresses(addressesData);
    } catch (error) {
      console.error("Error al obtener direcciones del usuario:", error);
    }
  };

  const updateSubsInfoData = async (userId) => {
    setSubsInfoData(null);
    console.log(">>>>>>>>>>> buscando subs");
    const userRef = doc(db, "users", userId);
    const subsRef = collection(userRef, "subscriptions");

    try {
      const subsSnapshot = await getDocs(subsRef);
      const subsData = subsSnapshot.docs.map((subDoc) => subDoc.data());

      setSubsInfoData(subsData);
    } catch (error) {
      console.error(
        "Error al obtener información de suscripciones del usuario:",
        error
      );
    }
  };

  const getSingleSubsData = async (user_id, subscription_id) => {
    console.log(">>>>>>>>>>>", user_id);
    console.log(">>>>>>>>>>>", subscription_id);

    // const userRef = doc(db, "users", user_id);
    // const subsRef = collection(userRef, "subscriptions", subscription_id);

    const subscriptionRef = doc(
      db,
      `users/${user_id}/subscriptions`,
      subscription_id
    );
    console.log(">>>>>>>>>>> subscriptionRef ", subscriptionRef);

    try {
      const subsSnapshot = await getDoc(subscriptionRef);
      console.log(">>>>>>>>>>> subsSnapshot ", subsSnapshot.data());
      return subsSnapshot.data();
    } catch (error) {
      console.error(
        "Error al obtener información de suscripciones del usuario:",
        error
      );
      return 0;
    }
  };

  useEffect(() => {
    if (
      addresses &&
      addresses !== null &&
      Object.keys(addresses).length > 0 &&
      subsInfoData &&
      subsInfoData !== null &&
      Object.keys(subsInfoData).length > 0
    ) {
      const addressKeys = Object.keys(addresses);
      const firstAddressKey = addressKeys[0];
      const subsForSelectedAddress = subsInfoData.filter(
        (sub) => sub.address_id === firstAddressKey
      );
      const selectedAddressData = {
        address: addresses[firstAddressKey],
        subscriptions: subsForSelectedAddress,
      };

      // Calcular la suma de las recargas disponibles para las suscripciones activas
      const availableRecharges = subsForSelectedAddress.reduce((total, sub) => {
        if (sub.status === "active" && sub.recharges_availables > 0) {
          total += sub.recharges_availables || 0;
        }
        return total;
      }, 0);

      // Agregar el campo "available_recharges" al objeto selectedAddressData
      selectedAddressData.available_recharges = availableRecharges;

      // Asignar selectedAddressData a selectedAddress
      setSelectedAddress(selectedAddressData);
    }
  }, [addresses]);

  useEffect(() => {
    if (userInfo) {
      getOrdersForUser(userInfo.id, currentPage);
    }
  }, [userInfo, currentPage]);

  const getOrdersForUser = async () => {
    const userOrdersRef = collection(
      db,
      `users/${userInfo.id}/orders_references/`
    );

    const snapshot = await getDocs(userOrdersRef);
    const numberOfDocuments = snapshot.size;
    setTotalOrders(numberOfDocuments);

    let ordersQuery;
    if (recentsOrders && recentsOrders.length > 0) {
      // Si ya hay órdenes recientes, obtener la siguiente página de órdenes
      const lastOrder = recentsOrders[recentsOrders.length - 1];
      ordersQuery = query(
        userOrdersRef,
        orderBy("created_at", "desc"),
        startAfter(lastOrder.created_at),
        limit(ordersPerPage)
      );
    } else {
      // Si no hay órdenes recientes, obtener las primeras ordersPerPage órdenes
      ordersQuery = query(
        userOrdersRef,
        orderBy("created_at", "desc"),
        limit(ordersPerPage)
      );
    }

    try {
      const querySnapshot = await getDocs(ordersQuery);
      const orders = querySnapshot.docs.map((doc) => doc.data());

      if (orders.length === 0) {
        // Si no hay más órdenes, devolver un objeto con un cero y un array vacío
        setRecentsOrders({ empty: true, count: 0 });
      } else {
        // Si hay órdenes, actualizarlas en el estado
        setRecentsOrders((prevOrders) => [...(prevOrders || []), ...orders]);
      }
    } catch (error) {
      console.error("Error al obtener órdenes del usuario:", error);
    }
  };

  const updateOrdersForUser = async () => {
    const userOrdersRef = collection(
      db,
      `users/${userInfo.id}/orders_references/`
    );

    let ordersQuery;
    if (recentsOrders && recentsOrders.length > 0) {
      // Si ya hay órdenes recientes, obtener la siguiente página de órdenes
      const lastOrder = recentsOrders[recentsOrders.length - 1];
      ordersQuery = query(
        userOrdersRef,
        orderBy("created_at", "desc"),
        startAfter(lastOrder.created_at),
        limit(ordersPerPage)
      );
    } else {
      // Si no hay órdenes recientes, obtener las primeras ordersPerPage órdenes
      ordersQuery = query(
        userOrdersRef,
        orderBy("created_at", "desc"),
        limit(ordersPerPage)
      );
    }

    try {
      const querySnapshot = await getDocs(ordersQuery);
      const orders = querySnapshot.docs.map((doc) => doc.data());

      if (orders.length === 0) {
        // Si no hay más órdenes, devolver un objeto con un cero y un array vacío
        setRecentsOrders({ empty: true, count: 0 });
      } else {
        // Si hay órdenes, actualizarlas en el estado
        setRecentsOrders((prevOrders) => [...(prevOrders || []), ...orders]);
      }
    } catch (error) {
      console.error("Error al obtener órdenes del usuario:", error);
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        db,
        collection,
        updateDoc,
        getDoc,
        doc,
        createOpenOrder,
        updateOpenOrder,
        addDocumentToSubCollection,
        deleteDocumentInCollection,
        getOrderData,
        getPaymentLinkInfo,
        formatCashToString,
        updateOrderFieldsOnSuccess,
        checkPaymentStatus,
        login,
        logout,
        user,
        userInfo,
        subsInfoData,
        setSubsInfoData,
        setSelectedAddress,
        selectedAddress,
        setAddresses,
        addresses,
        switchAddress: switchAddress,
        getEnvironment,
        addOrderRefToUser,
        generateOrderRefForUser,
        generateUserRefForOrder,
        updateOrderSuscriptions,
        getOrdersForUser,
        updateOrdersForUser,
        currentPage,
        setCurrentPage,
        recentsOrders,
        setRecentsOrders,
        updateAddresses,
        updateSubsInfoData,
        totalOrders,
        totalPages,
        ordersPerPage,
        //getUserAddresses,
        getSingleSubsData,
        getPricing,
        checkOnLoanContainers,
        checkUserExists,
        addNewLead,
        checkLeadExists
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );

  // Función para obtener órdenes del usuario con paginación

  function switchAddress(addressId) {
    // Filtrar las suscripciones asociadas a la dirección seleccionada
    const subsForSelectedAddress = subsInfoData.filter(
      (sub) => sub.address_id === addressId
    );

    // Contar el número de suscripciones activas con recargas disponibles
    const availableRecharges = subsForSelectedAddress.reduce((count, sub) => {
      if (sub.status === "active" && sub.recharges_availables > 0) {
        count++;
      }
      return count;
    }, 0);

    // Construir el objeto de datos para la dirección seleccionada
    const selectedAddressData = {
      address: addresses[addressId],
      subscriptions: subsForSelectedAddress,
      available_recharges: availableRecharges,
    };

    // Establecer el estado de selectedAddress con el nuevo objeto creado
    setSelectedAddress(selectedAddressData);
  }

  function generateOrderRefForUser(orderId) {
    var orderRef = doc(db, "orders", orderId);
    return orderRef;
  }

  function generateUserRefForOrder(orderId) {
    // Construye la referencia al documento de la orden en la subcolección "orders_references" del usuario
    var userOrderRef = doc(
      db,
      `users/${userInfo.id}/orders_references`,
      orderId
    );
    return userOrderRef;
  }

  async function createOpenOrder(documentData, orderId) {
    const cartRef = doc(db, "orders", orderId);

    await setDoc(cartRef, documentData);
    return cartRef;
  }

  async function updateOpenOrder(orderId, updatedData) {
    const cartRef = doc(db, "carts", orderId);

    // Crear un objeto con los campos a actualizar
    const fieldsToUpdate = {};
    Object.keys(updatedData).forEach((field) => {
      fieldsToUpdate[field] = updatedData[field];
    });

    // Actualizar solo los campos específicos
    await updateDoc(cartRef, fieldsToUpdate);
  }

  async function updateOrderSuscriptions(orders) {
    try {
      // Iterar sobre cada orden en el objeto de datos
      for (const orderKey in orders) {
        const order = orders[orderKey]; // Obtener el user id y el subscription id de la orden actual
        const userId = order.user.id;
        const subscriptionId = order.subscription_on_order.id; // Construir la ruta en Firestore para la suscripción actual
        const subscriptionRef = doc(
          db,
          `users/${userId}/subscriptions`,
          subscriptionId
        ); // Obtener el documento de la suscripción actual
        const subscriptionSnapshot = await getDoc(subscriptionRef);
        if (subscriptionSnapshot.exists()) {
          const subscriptionData = subscriptionSnapshot.data(); // Verificar si active_cicle_end no ha pasado
          const currentTime = new Date().getTime();
          if (subscriptionData.active_cicle_end.seconds * 1000 > currentTime) {
            // Verificar si recharges_availables es mayor que 0
            if (
              subscriptionData.recharges_availables > 0 &&
              subscriptionData.recharges_used <=
                subscriptionData.recharges_total
            ) {
              // Restar uno a recharges_availables y sumar uno a recharges_used
              const updatedRechargesAvailable =
                subscriptionData.recharges_availables - 1;
              const updatedRechargesUsed = subscriptionData.recharges_used + 1; // Actualizar los datos de la suscripción en Firestore
              const onProgressStatus = "active-on-progress";
              await updateDoc(subscriptionRef, {
                recharges_availables: updatedRechargesAvailable,
                recharges_used: updatedRechargesUsed,
                status: onProgressStatus,
                last_order: order.id,
              });
            } else {
              // Devolver error por recharges_stockout si recharges_availables es 0 o menor
              throw new Error("Recharges stockout");
            }
          } else {
            // Devolver error por vencimiento si active_cicle_end ya ha pasado
            throw new Error("Subscription expired");
          }
        } else {
          // Devolver error si no se encuentra la suscripción
          throw new Error("Subscription not found");
        }
      }
    } catch (error) {
      // Manejar los errores
      console.error("Error updating subscriptions:", error); // Devolver el error para que se maneje en el componente de React
      throw error;
    }
  }

  async function addOrderRefToUser(orderData, orderId) {
    // Referencia al documento de usuario
    const userRef = doc(db, "users", userInfo.id);

    // Referencia al documento de pedido dentro de la subcolección orders_refs
    const orderRef = doc(collection(userRef, "orders_references"), orderId);

    // Establecer los datos del pedido en el documento
    await setDoc(orderRef, orderData);

    return orderRef;
  }

  async function addDocumentToSubCollection(
    parentCollectionName,
    parentDocumentId,
    subCollectionName,
    documentData
  ) {
    const subCollectionRef = collection(
      db,
      parentCollectionName,
      parentDocumentId,
      subCollectionName
    );
    const docRef = await addDoc(subCollectionRef, documentData);
    return docRef;
  }

  async function deleteDocumentInCollection(collectionName, orderId) {
    const docRef = doc(db, collectionName, orderId);
    await deleteDoc(docRef);
  }

  async function getEnvironment(document) {
    const cartRef = doc(db, "orders", document);
    try {
      const cartSnapshot = await getDoc(cartRef);
      return cartSnapshot.data();
    } catch (error) {
      console.error("Error al obtener datos:", error);
      return null;
    }
  }

  async function getOrderData(orderId) {
    const cartRef = doc(db, "orders", orderId);

    try {
      const cartSnapshot = await getDoc(cartRef);

      if (cartSnapshot.exists()) {
        const cartData = cartSnapshot.data();
        // Puedes realizar cualquier mapeo adicional aquí si es necesario

        return cartData;
      } else {
        console.error("El documento no existe en la colección 'carts'.");
        return null;
      }
    } catch (error) {
      console.error("Error al obtener datos del carrito:", error);
      return null;
    }
  }

  async function getPaymentLinkInfo(linkParam) {
    const cartRef = doc(db, "orders", linkParam);

    try {
      const cartSnapshot = await getDoc(cartRef);

      if (cartSnapshot.exists()) {
        const cartData = cartSnapshot.data();

        if (cartData && cartData.paymentLinkId) {
          const linkId = cartData.paymentLinkId;
          return linkId;
        } else {
          console.error(
            "paymentLinkId no encontrado en los datos de la orden."
          );
          return null;
        }
      } else {
        console.error("El documento no existe en la colección 'orders'.");
        return null;
      }
    } catch (error) {
      console.error("Error al obtener datos de la orden:", error);
      return null;
    }
  }

  function formatCashToString(ammount) {
    return ammount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  async function updateOrderFieldsOnSuccess(orderId, paymentId) {
    const orderRef = doc(db, "orders", orderId);

    const updatedData = {
      payment: true,
      paymentId: paymentId,
      status: "acceptedPayment",
      paymentSuccessRedirectVisited: true,
    };

    await updateDoc(orderRef, updatedData);
  }

  async function checkPaymentStatus(orderId) {
    const orderRef = doc(db, "orders", orderId);

    try {
      const orderSnapshot = await getDoc(orderRef);
      if (orderSnapshot.exists()) {
        const orderData = orderSnapshot.data();
        const paymentStatus = orderData.payment || false;
        return paymentStatus;
      } else {
        return "El documento no existe en la colección 'orders'.";
      }
    } catch (error) {
      return "Error getting order detail.";
    }
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    signOut(auth);
    setUser(null);
    localStorage.clear(); // Limpiar el almacenamiento local
    window.location.reload(); // Recargar la página
    return;
  }

  async function getPricing() {
    try {
      const docRef = doc(db, "environment", "pricing");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching pricing document:", error);
      throw error;
    }
  }

  async function checkUserExists(email) {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking if user exists:", error);
      throw error;
    }
  }
  async function checkOnLoanContainers() {
    try {
      const querySnapshot = await getDocs(
        collection(db, "containers_inventory")
      );
      const result = { onLoan: false, containers: {} };

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.on_loan === true) {
          result.onLoan = true;
          result.containers[doc.id] = { id: doc.id };
        }
      });

      return result;
    } catch (error) {
      console.error("Error checking on loan containers:", error);
      throw error;
    }
  }
};

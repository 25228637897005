import React, { useEffect, useState } from "react";
import Button from "./UI/Button";
import locationIcon from "../images/blue-location-icon.svg";
import cartIcon from "../images/cart-icon.svg";
import { useCartContext } from "../context/CartContext";
import { useFirebase } from "../context/FirebaseContext";
import { useNavigate } from "react-router-dom";
import "../styles/Navbar.css";
import "../styles/App.css";
import logoBrand from "../images/fluenti-logo-140.svg";
import menuIcon from "../images/menu.svg";
import userIcon from "../images/icon-user.svg";
import subsIcon from "../images/icon-subs.svg";
import shopIcon from "../images/icon-product.svg";
import findOrderIcon from "../images/icon-find.svg";
import manualIcon from "../images/icon-manual.svg";
import supportIcon from "../images/icon-support.svg";
import cartIconBlue from "../images/cart-icon-blue.svg";

const Navbar = ({ version, goToProductsCallback }) => {
  const { itemCount, total } = useCartContext();
  const { user } = useFirebase();
  const [buttonOpacity, setButtonOpacity] = useState(1);
  const [scrolling, setScrolling] = useState(false);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Nuevo estado para controlar la visibilidad del menú

  const logoStyle = {
    maxHeight: "56px",
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    // Verifica la posición del scroll al cargar la página
    if (window.scrollY > 0) {
      setScrolling(true);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Deja la dependencia como un arreglo vacío para que solo se ejecute al montar el componente

  useEffect(() => {
    setButtonOpacity(0);

    const timeoutId = setTimeout(() => {
      setButtonOpacity(1);
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [itemCount]);

  const handlePedirClick = () => {
    navigate("/carrito");
  };

  const openMenu = () => {
    setIsMenuOpen(true); // Abrir el menú estableciendo isMenuOpen en true
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Cerrar el menú estableciendo isMenuOpen en false
  };

  function goToOrders() {
    navigate("/pedidos");
  }
  function goToHome() {
    navigate("/");
  }
  function goToPlans() {
    navigate("/planes");
  }
  function goToFAQ() {
    navigate("/faq");
  }
  function goToBoard() {
    navigate("/board");
  }

  const goToProducts = () => {
    setIsMenuOpen(false); // Cierra el menú estableciendo isMenuOpen en false
    goToProductsCallback(); // Llama a la función de devolución de llamada proporcionada por el componente padre
  };

  return (
    <div
      className={`${
        version === "checkOut" ? "w-100 d-flex align-items-center justify-content-center bg-light sticky-top" : "fixed-top nav-bar-fluenti"
      } ${scrolling ? "scrolling" : ""}`}
    >
      {version === "homeSimply" && (
        <div className="navbarHomeSimply d-flex align-items-center py-3 justify-content-between mx-4">
          <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
          <div className="d-flex gap-2">
            <div className="hideOnMobile">
              <Button
                version="flat"
                text="Rastrear pedido"
                onClick={goToOrders}
              />
              <Button
                version={itemCount > 0 ? "primary" : "secondary"}
                text={"Agregados: " + itemCount}
                image={itemCount > 0 ? cartIcon : cartIconBlue}
                onClick={handlePedirClick}
              />
            </div>
            <div className="hideOnDesktop">
              <Button
                version={itemCount > 0 ? "primary" : "secondary"}
                text={itemCount}
                image={itemCount > 0 ? cartIcon : cartIconBlue}
                onClick={handlePedirClick}
              />
              <img src={menuIcon} alt="Menu" onClick={openMenu} />
            </div>
          </div>
          <div
            id="mobileMenu"
            className={`mobileMenu hideOnDesktop d-flex flex-column p-4 ${
              isMenuOpen ? "open" : ""
            }`}
          >
            <div className="d-flex justify-content-between align-items-end">
              <h2>Menu</h2>
              <Button version="primary" text="Cerrar" onClick={closeMenu} />
            </div>
            <hr></hr>
            <div>
              <div className="cell-menu d-flex gap-2">
                <img src={userIcon}></img>
                Mi cuenta
              </div>
              <div className="cell-menu d-flex gap-2" onClick={goToPlans}>
                <img src={subsIcon}></img>
                Suscripciones
              </div>
              <div className="cell-menu d-flex gap-2" onClick={goToProducts}>
                <img src={shopIcon}></img>
                Pedir en línea
              </div>
              <div className="cell-menu d-flex gap-2" onClick={goToOrders}>
                <img src={findOrderIcon}></img>
                Rastrear pedido
              </div>
              <div className="cell-menu d-flex gap-2 d-none">
                <img src={manualIcon}></img>
                Guías y manuales
              </div>
              <div className="cell-menu d-flex gap-2">
                <img src={supportIcon}></img>
                Atención al cliente
              </div>
            </div>
            <div className="d-flex flex-fill flex-column align-items-center justify-content-end pb-4">
              <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
              <p>WebApp v1.0</p>
            </div>
          </div>
        </div>
      )}
      {version === "EarlyAccess" && (
        <div
          className="navbarHomeSimply d-flex align-items-center py-3 px-4 px-md-0 justify-content-between"
          style={{ maxWidth: "1024px" }}
        >
          <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
          <div className="d-flex gap-2">
            <div className="hideOnMobile">
              <Button version="flat" text="Inicio" onClick={goToHome} />
              {/* <Button
                version="flat"
                text="Fuentes inteligentes"
                onClick={goToOrders}
              /> */}
              <Button
                version="flat"
                text="Preguntas frecuentes"
                onClick={goToFAQ}
              />

              {/* <Button
                version={itemCount > 0 ? "primary" : "secondary"}
                text={"Agregados: " + itemCount}
                image={itemCount > 0 ? cartIcon : cartIconBlue}
                onClick={handlePedirClick}
              /> */}
            </div>

            <div className="ms-3 ">
              <Button
                text="Ingresar a tu cuenta"
                version="primary"
                onClick={goToBoard}
              />
            </div>

            <div className="hideOnDesktop">
              <img src={menuIcon} alt="Menu" onClick={openMenu} />
            </div>
          </div>
          <div
            id="mobileMenu"
            className={`mobileMenu hideOnDesktop d-flex flex-column p-4 ${
              isMenuOpen ? "open" : ""
            }`}
          >
            <div className="d-flex justify-content-between align-items-end">
              <h2>Menu</h2>
              <Button version="primary" text="Cerrar" onClick={closeMenu} />
            </div>
            <hr></hr>
            <div>
              <div
                className="cell-menu d-flex align-items-center gap-2"
                onClick={goToHome}
              >
                <i
                  className="fa fa-home"
                  aria-hidden="true"
                  style={{ color: "#1464ff" }}
                ></i>
                Inicio
              </div>

              <div
                className="cell-menu d-flex gap-2 align-items-center"
                onClick={goToFAQ}
              >
                <i
                  className="fa fa-book"
                  aria-hidden="true"
                  style={{ color: "#1464ff" }}
                ></i>
                Preguntas Frecuentes
              </div>
            </div>
            <div className="d-flex flex-fill flex-column align-items-center justify-content-end pb-4">
              <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
              <p>WebApp v1.0</p>
            </div>
          </div>
        </div>
      )}
      {version === "Board" && (
        <div className="navbarBoard bg-white d-flex align-items-center py-3 justify-content-between mx-4 mx-md-0">
          <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
          <div className="d-flex gap-2">
            <div className="hideOnMobile">
              <Button version="flat" text="Inicio" onClick={goToHome} />
              {/* <Button
                version="flat"
                text="Fuentes inteligentes"
                onClick={goToOrders}
              /> */}
              <Button
                version="flat"
                text="Preguntas frecuentes"
                onClick={goToFAQ}
              />

              <Button
                version={itemCount > 0 ? "primary" : "secondary"}
                text={"Agregados: " + itemCount}
                image={itemCount > 0 ? cartIcon : cartIconBlue}
                onClick={handlePedirClick}
              />
            </div>
            <div className="hideOnDesktop">
              <img src={menuIcon} alt="Menu" onClick={openMenu} />
            </div>
          </div>
          {/* <div
            id="mobileMenu"
            className={`mobileMenu hideOnDesktop d-flex flex-column p-4 ${
              isMenuOpen ? "open" : ""
            }`}
          >
            <div className="d-flex justify-content-between align-items-end">
              <h2>Menu</h2>
              <Button version="primary" text="Cerrar" onClick={closeMenu} />
            </div>
            <hr></hr>
            <div>
              <div className="cell-menu d-flex gap-2">
                <img src={userIcon} onClick={goToHome}></img>
                Inicio
              </div>
              <div
                className="d-none cell-menu d-flex gap-2"
                onClick={goToSuper}
              >
                <img src={subsIcon}></img>
                Fuentes Inteligentes
              </div>
              <div className="cell-menu d-flex gap-2" onClick={goToFAQ}>
                <img src={shopIcon}></img>
                Preguntas Frecuentes
              </div>
            </div>
            <div className="d-flex flex-fill flex-column align-items-center justify-content-end pb-4">
              <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
              <p>WebApp v1.0</p>
            </div>
          </div> */}
        </div>
      )}
      {version === "productos" && <p>Contenido para la página de productos</p>}
      {version === "checkOut" && (
        <div
          className="d-flex align-items-center py-3 px-4 px-md-0 w-100 sticky-top justify-content-between"
          style={{ maxWidth: "1024px" }}
        >
          <h3 className="h3 fw-bold text-body">Solicita tu plan Fluenti</h3>
          <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
        </div>
      )}
      {version === "SubscriptionGateway" && (
        <div
          className="d-flex align-items-center py-3 px-4 px-md-0 w-100 sticky-top justify-content-between"
          style={{ maxWidth: "1024px" }}
        >
          <h3 className="h3 fw-bold text-body">Detalles de tu plan</h3>
          <img src={logoBrand} style={logoStyle} alt="Fluenti Logo" />
        </div>
      )}
    </div>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import { useCartContext } from "../context/CartContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFirebase } from "../context/FirebaseContext";
import ProgressBar from "./UI/ProgressBar";
import Button from "./UI/Button";
import dotsLoading from "../images/dotsloading.gif";
import "../styles/OrderGateway.css";

const OrderGateway = (props) => {
  const { resume, setResume, cleanCartContextSoft, cleanCartContext } =
    useCartContext();
  const {
    createOpenOrder,
    addOrderRefToUser,
    generateOrderRefForUser,
    generateUserRefForOrder,
    updateOrderSuscriptions,
  } = useFirebase();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [orderId, setOrderId] = useState(generateShortCode());
  const [mainOrder, setMainOrder] = useState(null);
  const [splitOrders, setSplitOrders] = useState(null);
  const [orderRef, setOrderRef] = useState(null);
  const [completion, setCompletion] = useState(10);
  const [state, setState] = useState("initOrder");
  const [dots, setDots] = useState("");
  const [dataReadyToDelivery, setDataReadyToDelivery] = useState(false);
  const [addressesPreview, setAddressesPreview] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === "...") {
          return "";
        } else {
          return prevDots + ".";
        }
      });
    }, 400);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (Object.keys(resume).length < 1) {
      navigate("/board");
    } else {
      generateMainOrder();
      setCompletion(20);
      orderSpliterFunction();
      setCompletion(40);
      setDataReadyToDelivery(true);
    }
  }, [resume]);

  useEffect(() => {
    async function fetchData() {
      if (mainOrder !== null && orderRef !== null && splitOrders !== null) {
        try {
          await updateOrderSuscriptions(splitOrders);
          setCompletion(48);
          await delay(2400);
          setState("savingOrders");
          await createOpenOrder(mainOrder, mainOrder.id);
          setCompletion(64);
          await addOrderRefToUser(orderRef, mainOrder.id);
          setCompletion(80);
          sendOrdersToWebhook();
          setState("complete");
          setCompletion(100);
          await cleanCartContext();
          await delay(2400);
        } catch (error) {
          // Manejar el error de acuerdo a tus necesidades
          console.error("Error updating subscriptions:", error);
          // Retornar para salir de la función si hay un error
          return;
        }
      }
    }
    fetchData();
  }, [dataReadyToDelivery]);

  const orderSpliterFunction = () => {
    const orders = {};
    let index = 1;

    // Extraer la información del usuario, refil availables y refill on cart
    const {
      user,
      conditions_confirmation,
      selected_date,
      selected_time,
      subscriptions_refills,
    } = resume;

    // Iterar sobre cada dirección en subscriptions_refills
    for (const addressId in subscriptions_refills) {
      const { addressInfo, refill_availables, refill_on_cart, subscriptions } =
        subscriptions_refills[addressId];

      // Obtener el índice de la suscripción actual para esta dirección
      const now = new Date();

      // Obtener las claves (IDs) de las suscripciones en esta dirección
      const subscriptionIds = Object.keys(subscriptions);

      // Iterar sobre cada clave (ID) de suscripción
      for (const subscriptionId of subscriptionIds) {
        const subscription = subscriptions[subscriptionId]; // Obtener la suscripción actual

        // Crear un objeto temporal para cada pedido
        const order = {
          id: orderId,
          payment: true,
          user,
          conditions: conditions_confirmation,
          selected_date,
          selected_time,
          addressInfo,
          refill_availables,
          refill_on_cart,
          subscription_on_order: subscription,
          created_at: now,
          updated_at: now,
          active: true,
          onCoverge: true,
          terms: true,
          total: 0,
          status: "acceptedSubscriptionOrder",
        };

        // Agregar el pedido al objeto orders
        orders[index] = order;
        index++;

        // Actualizar el índice de la suscripción actual
      }
    }
    setSplitOrders(orders);
  };

  function generateAddressesPreview() {
    const { subscriptions_refills } = resume;
    let firstAddress = null;
    let additionalAddressesCount = 0;

    for (const key in subscriptions_refills) {
      const address = subscriptions_refills[key].addressInfo;

      if (!firstAddress) {
        firstAddress = {
          first_line: address.first_line,
          alias: address.alias,
        };
      } else {
        additionalAddressesCount++;
      }
    }
    const data = {
      firstAddress,
      additionalAddressesCount,
    };
    return(data);
  }

  const generateMainOrder = async () => {
    // Extraer la información del usuario, refil availables y refill on cart
    const {
      user,
      conditions_confirmation,
      selected_date,
      selected_time,
      subscriptions_refills,
    } = resume;
    const now = new Date();
    // generar referenciassss
    const ref = generateOrderRefForUser(orderId);
    const userOrderRef = generateUserRefForOrder(orderId);
    // Obtener el índice de la suscripción actual para esta dirección
    const order = {
      id: orderId,
      payment: true,
      user,
      conditions: conditions_confirmation,
      selected_date,
      selected_time,
      created_at: now,
      updated_at: now,
      active: true,
      onCoverge: true,
      terms: true,
      total: 0,
      status: "acceptedSubscriptionOrder",
      subscriptions_on_order: subscriptions_refills,
      userOrderRef,
    };

    const createdOrderRef = {
      reference: ref,
      id: orderId,
      selected_date,
      selected_time,
      created_at: now,
      active: true,
      onCoverge: true,
      items: countItemsOnOrder(),
      total: 0,
      status: "acceptedSubscriptionOrder",
      addressPreview: generateAddressesPreview(),
    };
    
    setMainOrder(order);
    setOrderRef(createdOrderRef);
    await delay(1200);
    setState("generatingOrders");
  };

  const sendOrdersToWebhook = async () => {
    for (const orderId in splitOrders) {
      const order = splitOrders[orderId];
      try {
        await axios.post(
          "https://hook.us1.make.com/p0a3nfdc4e6ujmmoz6391p0gz5wp2jto",
          order,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(`Pedido ${orderId} enviado al webhook`);
      } catch (error) {
        console.error(
          `Error al enviar el pedido ${orderId} al webhook:`,
          error
        );
      }
      await delay(1200); // Espera 1.2 segundos antes de continuar con el siguiente envío
    }
    setSuccess(true);
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  function countItemsOnOrder() {
    let count = 0;

    // Iterar sobre subscriptions_on_order
    for (const addressId in resume.subscriptions_refills) {
      // Obtener el objeto de subscriptions dentro de cada subscriptions_on_order
      const subscriptions =
        resume.subscriptions_refills[addressId].subscriptions;

      // Iterar sobre las suscripciones dentro de cada subscriptions_on_order
      for (const subscriptionId in subscriptions) {
        // Incrementar el contador por cada suscripción encontrada
        count++;
      }
    }
    return count;
  }

  function generateShortCode() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let shortCode = "";

    for (let i = 0; i < 8; i++) {
      const index = Math.floor(Math.random() * characters.length);
      shortCode += characters.charAt(index);
    }
    return shortCode;
  }

  async function handleGoHome() {
    await 500;
    navigate(`/board`);
  }

  async function handleGoToOrder() {
    await 500;
    navigate(`/pedidos/${orderId}`);
  }

  return (
    <div
      className="d-flex flex-column overflow-hidden justify-content-center align-items-center
      "
      style={{
        position: "",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        className="px-4 pt-5 align-items-center justify-content-center"
        style={{
          position: "",
          maxWidth: "720px",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <h2 className="p-0 m-0">
          Muchas gracias! <br /> Estamos generando tu pedido :D
        </h2>
        <h5 className="pt-2 p-0 m-0">
          {state === "initOrder"
            ? "Consolidando ordenes" + dots
            : state === "generatingOrders"
            ? "Aplicando cambio en suscripciones" + dots
            : state === "savingOrders"
            ? "Guardando y enviando orden a operaciones" + dots
            : state === "complete"
            ? "Se ha enviado tu pedido!"
            : ""}
        </h5>
        <div className="d-flex gap-3">
          <Button
            text="Ir al inicio"
            version="primary"
            onClick={() => handleGoHome()}
          />
          <Button
            text="Ver orden"
            version="primary"
            onClick={() => handleGoToOrder()}
          />
        </div>
      </div>
      <div className="lottieGateway">
        <lottie-player
          src="https://lottie.host/8c3d94fa-2625-43df-8d4b-badb6175b89d/zyfYNbuRe5.json"
          background="transparent"
          speed="1"
          style={{ minWidth: "100vw", minHeight: "auto" }}
          direction="-1"
          loop
          autoplay
        ></lottie-player>
      </div>
      <div className="vw-100 fixed-bottom m-0 p-0">
        <ProgressBar completion={completion} />
      </div>
    </div>
  );
};

export default OrderGateway;

import React from "react";
import Button from "./UI/Button";

const Coverage = () => {
  function handleBack() {
    if (window.history.length > 1) {
      // Si hay historial, retrocede
      window.history.back();
    } else {
      // Si no hay historial, cierra la ventana actual
      window.close();
    }
  }

  const buttonText = window.history.length > 1 ? "Atras" : "Cerrar";

  return (
    <div className="p-4 d-flex flex-column" style={{ height: "100%" }}>
      <div className="d-flex">
        <Button version="primary" text={buttonText} onClick={handleBack} />
      </div>
      <div className="d-flex flex-column gap-2 my-4 flex-fill">
        {/* <CoverageArea/> */}

        <h2>Cobertura en Barranquilla</h2>
        <div className="flex-fill">
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1SRQ5GZzTvVIO7AIOpa350NAnkeBdawY&noprof=1&dg=1&authuser=0&dg=1&authuser=0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Coverage;

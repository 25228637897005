import React from "react";

const DividerHorizontal = ({
  height = 1,
  color = "222222",
  marginY = 0,
  hideOnDesktop = false,
}) => {
  return (
    <div
      className={hideOnDesktop ? "d-block d-md-none" : ""}
      style={{
        width: "100%",
        height: `${height}px`,
        backgroundColor: `${color}`,
        margin: `${marginY}px 0`, // Se aplica el margen en el eje Y
      }}
    ></div>
  );
};

export default DividerHorizontal;

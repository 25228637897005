import React, { useMemo, useState } from "react";
import imgBotRefill from "../../images/no-tag-product-replace.png";
import imgBot from "../../images/no-tag-product-new.png";
import Button from "../UI/Button";
import "../../styles/SubsCard.css";
import "../../styles/Medias.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SubsCard = (props) => {
  const navigate = useNavigate();
  const {
    active,
    status,
    active_cicle_end,
    id,
    type,
    recharges_total,
    recharges_availables,
    recharges_used,
    monthly_fee,
    last_order,
  } = props;

  const formatDateAndDaysUntilEpoch = (seconds, nanoseconds) => {
    const milliseconds = Math.floor(nanoseconds / 1000000);
    const date = new Date(seconds * 1000 + milliseconds);
    const currentDate = new Date();
    const difference = date.getTime() - currentDate.getTime();
    const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));
    return {
      formattedDate: date.toLocaleDateString("es-ES"),
      daysLeft: daysLeft,
    };
  };

  const activeCicleEnd = new Date(
    active_cicle_end.seconds * 1000 + active_cicle_end.nanoseconds / 1000000
  );

  const now = new Date();

  const cardStatus = useMemo(() => {
    if (status === "pending" || activeCicleEnd < now) {
      return "expired";
    } else if (status === "active" && activeCicleEnd > now) {
      return "active_card";
    } else if (status === "active-on-progress") {
      return "on_progress";
    } else {
      return "expired";
    }
  }, [status, activeCicleEnd, now]);

  const renderRenewInfo = () => {
    if (!active) return null;

    const { formattedDate, daysLeft } = formatDateAndDaysUntilEpoch(
      active_cicle_end.seconds,
      active_cicle_end.nanoseconds
    );

    const renderCommonInfo = (status) => (
      <div
        className={`w-100 d-flex flex-column rounded-2 p-2 py-2 justify-content-between align-items-center ${
          status === "expired" ? "bg-warning-subtle" : "bg-light"
        }`}
      >
        {status === "on_progress" && (
          <div className="w-100 d-flex justify-content-between w-100">
            <p>
              <strong>Pedido activo:</strong>
            </p>
            <p
              className="p-0 m-0 text-primary"
              onClick={() => handleGoToOrder(last_order)}
              style={{ cursor: "pointer" }}
            >
              <strong>Ver detalles</strong>
            </p>
          </div>
        )}
        {status === "on_progress" || status === "active_card" ? (
          <>
            <div className="w-100 d-flex justify-content-between w-100">
              <p>Recambios usados</p>
              <p className="p-0 m-0">{recharges_used}</p>
            </div>
            <div className="w-100 d-flex justify-content-between w-100">
              <p>
                <strong>Recambios disponibles</strong>
              </p>
              <p className="p-0 m-0">
                <strong>{recharges_availables}</strong>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="w-100 d-flex justify-content-between w-100">
              <p>Vencido en:</p>
              <p className="d-flex justify-content-between align-items-center gap-1">
                {formattedDate}
                <span className="badge text-bg-danger">
                  Hace {daysLeft * -1} días
                </span>
              </p>
            </div>
            <div className="w-100 d-flex justify-content-between w-100">
              <p>
                <strong>Valor de renovación:</strong>
              </p>
              <p className="p-0 m-0">
                <strong>${monthly_fee.toLocaleString()}</strong>
              </p>
            </div>
          </>
        )}
      </div>
    );

    async function handleGoToOrder(order_id) {
      if (order_id) {
        navigate(`/pedidos/${order_id}`);
      } else {
        toast.warning("Ups! algo salió mal.");
        return;
      }
    }

    switch (cardStatus) {
      case "expired":
        return (
          <div className="d-flex flex-column gap-3 px-3 w-100 flex-fill h-100">
            {renderCommonInfo("expired")}

            <div className="w-100" onClick={goToSubsGateway}>
              <Button text="Renovar plan" version="secondary" />
            </div>
          </div>
        );
      case "active_card":
      case "on_progress":
        return (
          <div
            className={`d-flex flex-column ${
              cardStatus === "on_progress" ? "gap-2" : "gap-3"
            } px-3 w-100`}
          >
            {renderCommonInfo(cardStatus)}
            <div
              className="w-100 d-flex justify-content-between align-items-center"
              style={{
                minHeight: cardStatus === "on_progress" ? "5px" : "34px",
              }}
            >
              <p>Próxima renovación:</p>
              <p className="d-flex justify-content-between align-items-center gap-1">
                <strong>{formattedDate}</strong>
                {daysLeft !== null && (
                  <span className="badge text-bg-light">
                    {cardStatus === "active_card" ? "en" : "en"} {daysLeft} días
                  </span>
                )}
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  function goToSubsGateway() {
    navigate(`/mi-suscripcion/${id}`);
  }

  return (
    <div
      id="subsCard"
      className={`card grid-item p-0 gap-1 rounded-2 border-0 py-3 shadow-sm ${cardStatus}`}
    >
      <div className=" w-100 d-flex justify-content-between align-items-end">
        <div className="d-flex px-2 pb-2 gap-2 justify-content-between align-items-center">
          <img
            src={
              cardStatus === "active_card" || cardStatus === "expired"
                ? imgBot
                : cardStatus === "on_progress"
                ? imgBotRefill
                : imgBot
            }
            style={{ maxHeight: "48px" }}
            alt="Bot"
          />
          <div className="d-flex flex-column gap-0">
            <h6 className="h6 m-0 p-0 d-flex gap-2 align-items-center ">
              <strong>{id.toUpperCase()}</strong>
              <span
                className={`badge ${
                  cardStatus === "active_card"
                    ? "text-bg-primary"
                    : cardStatus === "expired"
                    ? "text-bg-danger"
                    : cardStatus === "on_progress"
                    ? "text-bg-dark"
                    : ""
                }`}
              >
                {cardStatus === "active_card" && "Activo"}
                {cardStatus === "expired" && "Vencido"}
                {cardStatus === "on_progress" && "Pedido en curso"}
              </span>
            </h6>
            <p className="p-0 m-0 d-flex gap-1 align-items-center">
              Plan Recambios<strong>{type}</strong>
            </p>
          </div>
        </div>
        {cardStatus === "on_progress" && (
          <div
            className="d-flex justify-content-center align-items-center pt-1"
            style={{ maxHeight: "56px", maxWidth: "56px" }}
          >
            <lottie-player
              src="https://lottie.host/cdefc9a3-ca55-42cc-b95f-877b7b1a55b8/24rhlLPKbn.json"
              background="transparent"
              speed="1"
              style={{ minWidth: "96px", minHeight: "96px" }}
              direction="1"
              loop
              autoplay
            ></lottie-player>
          </div>
        )}
      </div>
      <div className="d-flex flex-column gap-5 align-items-center flex-fill">
        {renderRenewInfo()}
      </div>
    </div>
  );
};

export default SubsCard;

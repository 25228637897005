import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const TransitSearch = ({ id }) => {
  const navigate = useNavigate();
  const { linkParam } = useParams();


  useEffect(() => {
    // Redirige automáticamente a la ruta /pedido/:id
    navigate(`/pedido/${linkParam}`);
  }, [id, navigate]);

  // Puedes renderizar algo aquí si es necesario, o simplemente dejarlo vacío
  return null;
};

export default TransitSearch;

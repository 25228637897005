import React from "react";
import "../../styles/OurOffer.css";
import value01 from "../../images/vale1.png";
import value02 from "../../images/vale2.png";
import value03 from "../../images/vale3.png";
import value04 from "../../images/vale4.png";
import value05 from "../../images/vale5.png";
import value06 from "../../images/vale6.png";

const values = [
  { image: value01, title: "Elije un plan de recambios al mes" },
  { image: value02, title: "Recibe un botellón de 20lts y dispensador" },
  { image: value03, title: "Pide recambios cuando los necesites" },
  { image: value04, title: "Domicilio programado gratis!" },
  { image: value05, title: "Recibe botellón lleno y entregas el vacio" },
  { image: value06, title: "Disfruta de agua de calidad" },
];

const OurOffer = () => {
  return (
    <div className="row text-md-center rounded-4 border-0 overflow-hidden bg-white w-100" id="services">
      {values.map((value, index) => (
        <div key={index} className="col-12 col-md-4 px-0 px-md-5 p-0 m-0">
          <div className="card scale-forced border-0 gap-5 gap-md-3 card-body d-flex flex-row flex-md-column justify-content-md-center align-items-center h-100 flex-row-reverse justify-content-between">
            <img
              src={value.image}
              className="valueImage"
              alt={`Value ${index + 1}`}
            />
            <h4 className="card-title">{value.title}</h4>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OurOffer;

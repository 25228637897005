import React, { useState } from "react";
import Button from "../UI/Button";
import SubsCard from "./SubsCard";
import { useFirebase } from "../../context/FirebaseContext";
import replacementImg from "../../images/no-tag-product-replace.png";
import { toast } from "react-toastify";
import "../../styles/SubscriptionsCards.css";
import { Disclosure, Transition } from "@headlessui/react";
import { BeakerIcon } from "@heroicons/react/24/solid";
import DividerHorizontal from "../UI/DividerHorizontal";

const SubscriptionsCards = (props) => {
  const { userInfo, subsInfoData, selectedAddress, addresses, switchAddress } =
    useFirebase();
  const [openDisclosure, setOpenDisclosure] = useState(null); // Estado para controlar qué Disclosure está abierto

  // Verificar si subsInfoData está definido
  if (!subsInfoData) {
    return null; // O cualquier otro componente de carga que desees mostrar
  }

  const productImgStyle = {
    height: "32px",
  };

  // Agrupar suscripciones por dirección
  const groupedSubsByAddress = {};
  subsInfoData.forEach((sub) => {
    const addressId = sub.address_id;
    if (!groupedSubsByAddress[addressId]) {
      groupedSubsByAddress[addressId] = [];
    }
    groupedSubsByAddress[addressId].push(sub);
  });

  function countAllSubscriptions(addressId) {
    let count = 0;
    for (let i = 0; i < subsInfoData.length; i++) {
      if (subsInfoData[i].address_id === addressId) {
        count++;
      }
    }
    return count;
  }

  function countActiveRecharges(addressId) {
    let count = 0;
    for (let i = 0; i < subsInfoData.length; i++) {
      const now = new Date();
      const formattedDateObj = new Date(
        subsInfoData[i].active_cicle_end.seconds * 1000 +
          subsInfoData[i].active_cicle_end.nanoseconds / 1000000
      );
      if (
        subsInfoData[i].address_id === addressId &&
        subsInfoData[i].status === "active" &&
        formattedDateObj > now
      ) {
        count++;
      }
    }
    return count;
  }

  function switchAddressAndTab(addressIdToSelect) {
    props.switchTab("recambio");
    switchAddress(addressIdToSelect);
    setTimeout(() => {
      window.scrollTo(0, 0); // Esto lleva el scroll al principio de la página
    }, 100); // Espera 100 milisegundos antes de realizar el scroll

    // Obtener el alias de la dirección
    const alias = addresses[addressIdToSelect].alias;

    // Verificar si el alias es nulo o vacío
    if (alias && alias !== "") {
      // Si el alias no es nulo ni vacío, mostrar la información con alias
      toast.info(
        "Dirección " +
          addresses[addressIdToSelect].first_line +
          ", (" +
          alias +
          ") seleccionada."
      );
    } else {
      // Si el alias es nulo o vacío, mostrar la información sin alias
      toast.info(
        "Dirección " +
          addresses[addressIdToSelect].first_line +
          " seleccionada."
      );
    }
  }

  return (
    <div className="d-flex flex-column gap-4 mb-5">
      <div className="d-flex flex-column flex-md-row gap-2 align-items-start justify-content-between">
        <h4 className="h4 p-0 m-0">
          Tus planes de servicio{" "}
          <span className="badge rounded-pill bg-primary-subtle text-primary">{subsInfoData.length}</span>
        </h4>
        <Button text="Adquirir nuevo plan" version="primary" />
      </div>
      <hr className="p-0 m-0"></hr>

      {Object.keys(groupedSubsByAddress).map((addressId, index) => (
        <Disclosure
          key={addressId}
          defaultOpen={index === 0} // Abre el primer Disclosure por defecto
          open={openDisclosure === addressId} // Define si este Disclosure está abierto
          onChange={() => setOpenDisclosure(addressId)} // Cambia el estado para abrir o cerrar el Disclosure
        >
          {({ open }) => (
            <>
              <div className="d-flex flex-column flex-md-wrap mb-2">
                <div className="w-100 rounded-2 gap-4 d-flex flex-column ">
                  <div className=" d-flex p-3 gap-0 gap-sm-2 border-0 card rounded-2 justify-content-between align-items-end align-items-md-center">
                    <div className="d-flex justify-content-between w-100">
                      {addresses[addressId].alias &&
                      addresses[addressId].alias !== "" ? (
                        <div>
                          <p className="p-0 m-0 d-flex gap-1 align-items-center">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>
                            <strong>{addresses[addressId].alias}</strong>
                          </p>
                          <p className="h5 p-0 m-0 d-flex gap-2 align-items-center justify-content-center text-primary">
                            <strong>{addresses[addressId].first_line}</strong>
                          </p>
                        </div>
                      ) : (
                        <div className="d-flex gap-1">
                          <p className="h5 p-0 m-0 d-flex gap-2 align-items-center justify-content-center text-primary">
                            <i
                              className="fa fa-map-marker text-dark"
                              aria-hidden="true"
                            ></i>
                            <strong>{addresses[addressId].first_line}</strong>
                          </p>
                        </div>
                      )}
                      <Button
                        text="Pedir aquí"
                        version="secondary"
                        onClick={() => switchAddressAndTab(addressId)}
                      />
                    </div>
                    <DividerHorizontal
                      height={2}
                      color="#f6f6f6"
                      marginY={8}
                    />

                    <div className="d-flex w-100 justify-content-between ">
                      <div className=" d-flex flex-column flex-md-row gap-0 gap-sm-3">
                        <div className="d-flex align-items-center gap-2">
                          <p
                            className="p-0 m-0 d-flex gap-1 align-content-center align-items-center"
                            style={{ lineHeight: "72%" }}
                          >
                            <i
                              className="fa fa-caret-right "
                              aria-hidden="true"
                              style={{ color: "#222" }}
                            ></i>
                            Planes/Botellones:
                          </p>
                          <h5 className="m-0 p-0">
                            <span
                              className="badge rounded-pill text-bg-light"
                              style={{ minWidth: "24px" }}
                            >
                              {countAllSubscriptions(addressId)}
                            </span>
                          </h5>
                        </div>
                        <div class="vr d-none d-md-block"></div>
                        <div className="d-flex align-items-center gap-2">
                          <p
                            className="p-0 m-0 d-flex gap-1 align-content-center align-items-center"
                            style={{ lineHeight: "72%" }}
                          >
                            <i
                              className="fa fa-refresh "
                              aria-hidden="true"
                              style={{ color: "#222" }}
                            ></i>
                            Recambios disponibles:
                          </p>
                          <h5 className="m-0 p-0">
                            <span
                              className={`badge rounded-pill ${
                                countActiveRecharges(addressId) > 0
                                  ? "bg-primary-subtle text-primary"
                                  : "text-bg-secondary"
                              }`}
                              style={{ minWidth: "24px" }}
                            >
                              {countActiveRecharges(addressId)}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <Disclosure.Button className="d-flex border-0 rounded-2 bg-light align-items-center justify-content-center gap-1">
                        <div className="d-none d-sm-block">
                          <Button text={open ? "Ver menos" : "Ver botellones"} version="flat" />
                        </div>
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                          style={{
                            color: "#1464ff",
                            transform: open ? "rotate(180deg)" : "rotate(0deg)",
                          }}
                        ></i>
                      </Disclosure.Button>
                    </div>
                  </div>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className="d-none"
                  ></Transition>
                  <Disclosure.Panel className="text-gray-500">
                    <div
                      className="grid-container"
                      style={{ maxWidth: "1024px" }}
                    >
                      {groupedSubsByAddress[addressId]
                        .filter((sub) => sub.status === "pending") // Filtrar solo las suscripciones activas
                        .map((sub, subIndex) => (
                          <SubsCard
                            key={subIndex}
                            SubIndex={subIndex}
                            {...sub}
                          />
                        ))}
                      {groupedSubsByAddress[addressId]
                        .filter((sub) => sub.status === "active") // Filtrar las suscripciones no activas
                        .map((sub, subIndex) => (
                          <SubsCard
                            key={subIndex}
                            SubIndex={subIndex}
                            {...sub}
                          />
                        ))}
                      {groupedSubsByAddress[addressId]
                        .filter((sub) => sub.status === "active-on-progress") // Filtrar las suscripciones no activas
                        .map((sub, subIndex) => (
                          <SubsCard
                            key={subIndex}
                            SubIndex={subIndex}
                            {...sub}
                          />
                        ))}
                    </div>
                  </Disclosure.Panel>
                  <hr
                    className="p-0 m-0"
                    style={{ border: "#d6d6d6 2px solid" }}
                  ></hr>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default SubscriptionsCards;

import React from "react";
import Button from "../UI/Button";
import DividerHorizontal from "../UI/DividerHorizontal";
import checkON from "../../images/check-checkout-on.svg";
import checkOFF from "../../images/check-checkout-off.svg";
import wompiLogo from "../../images/wompi-principal.svg";
import bancolombiaLogo from "../../images/bancolombia-logo.svg";

const PaymentTerms = (props) => {
  const {
    disclosureTitlesClasses,
    disclosurePanelClasses,
    checkOutItemClass,
    paymentStatus,
    paymentTerms,
    setPaymentTerms,
    index,
    handleChangeIndex,
  } = props.data;

  return (
    <div
      as="div"
      className="d-flex flex-column gap-3 mb-3"
      onClick={() => handleChangeIndex(5)}
    >
      <div
        className={`d-flex justify-content-between align-items-center ${disclosureTitlesClasses}`}
      >
        <div className="d-flex gap-2 align-items-center">
          <i className="fa fa-credit-card" aria-hidden="true"></i>
          Condiciones de Pago en línea{" "}
        </div>
        <img src={paymentStatus ? checkON : checkOFF} />
      </div>
      {index === 5 && (
        <div className={disclosurePanelClasses}>
          <div className={checkOutItemClass}>
            <p className="">
              El pago seguro es procesado por{" "}
              <img
                src={wompiLogo}
                style={{ height: "40px", margin: "-4px" }}
              ></img>
              , pasarela de pagos en línea regulada por{" "}
              <img
                src={bancolombiaLogo}
                style={{ height: "16px"}}
              ></img>, puede tardar hasta 10 minutos en confirmarse.
            </p>
            <DividerHorizontal height={1} marginY={16} color="#ebebeb"/>
            <div className="w-100 m-0 p-0 form-check d-flex align-items-center justify-content-between flex-row-reverse gap-4">

              <input
                onChange={() => setPaymentTerms(!paymentTerms)}
                checked={paymentTerms}
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                style={{
                    border: "#1464ff 3px solid",
                    height:"24px",
                    width:"24px",
                  }}
              />
                <p
                  className="form-check-label w-100 text-wrap text-start p-0 m-0"
                  htmlFor="flexCheckDefault"
                  style={{fontSize:"15px"}}
                >
                Comprendo las condiciones y el pago seguro.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentTerms;

import React from "react";
import othersBrands from "../../images/otherbrands.svg";
import fluentiBrand from "../../images/fluenti-logo-140.svg";

const Compare = () => {
  const brandsStyle = { height: "20px" };
  const fluentiStyle = { height: "40px" };
  const brandsStyleMobile = { height: "28px" };
  const fluentiStyleMobile = { height: "32px" };

  const comparisonData = [
    { title: "Recambios en 24h ó menos", others: "Algunas", fluenti: "✅" },
    { title: "Planes de recambios por botellón", others: "❌", fluenti: "✅" },
    {
      title: "Compra el planes en línea",
      others: "❌",
      fluenti: "✅",
    },
    { title: "2 Botellones incluidos en el plan", others: "❌", fluenti: "✅" },
    { title: "Se entrega un botellón", others: "✅", fluenti: "✅" },
    {
      title: "Dispones de botellón para recambio siempre",
      others: "❌",
      fluenti: "✅",
    },
    {
      title: "Se entrega un dispensador recargable",
      others: "❌",
      fluenti: "✅",
    },
    { title: "Plataforma Web", others: "❌", fluenti: "✅" },
    {
      title: "Pide recambios por plataforma 24/7",
      others: "❌",
      fluenti: "✅",
    },
    {
      title: "Pide recambios por WhatsApp 24/7",
      others: "Algunas",
      fluenti: "✅",
    },
    { title: "Recibe donde lo necesites", others: "Algunas", fluenti: "✅" },
    { title: "Domicilio Programado Gratis", others: "❌", fluenti: "✅" },
    { title: "Domicilio Express Disponible", others: "❌", fluenti: "✅" },
    {
      title: "Monitoreo y seguimiento de los botellones",
      others: "❌",
      fluenti: "✅",
    },
    { title: "🎧 Soporte 24/7", others: "Algunas", fluenti: "✅" },
    { title: "♻️ Enfoque 100% Sustentable", others: "❌", fluenti: "✅" },
  ];

  return (
    <div
      className="w-100 card p-4 rounded-4 border-0 shadow-sm text-center"
      style={{ maxWidth: "720px" }}
    >
      <table
        className="table-hover w-100"
        style={{ fontSize: "16px", fontWeight: "400" }}
      >
        <thead>
          <tr>
            <th className="text-start">Nuestros Beneficios </th>
            <th className="pb-4 d-flex w-100 align-items-center justify-content-center">
              <img
                src={othersBrands}
                style={fluentiStyle}
                className="d-none d-md-block"
              />
              <img
                src={othersBrands}
                style={brandsStyleMobile}
                className="d-md-none"
              />
            </th>
            <th className="pb-4">
              <img
                src={fluentiBrand}
                style={fluentiStyle}
                className="d-none d-md-block"
              />
              <img
                src={fluentiBrand}
                style={fluentiStyleMobile}
                className="d-md-none"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {comparisonData.map((item, index) => (
            <tr key={index} className={index === comparisonData.length - 1 ? "" : "border-bottom"}>
              <td className="py-3 text-start">{item.title}</td>
              <td className="text-danger">{item.others}</td>
              <td>{item.fluenti}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Compare;

import React, { useState, useEffect } from "react";
import Button from "../UI/Button";
import imgRecharge from "../../images/product_image.jpg";
import rechargeActionImg from "../../images/recharge-info.png";
import botellonCheck from "../../images/botellon-check.png";
import Stepper from "../UI/Stepper";
import { useCartContext } from "../../context/CartContext";
import redDot from "../../images/dotred.gif";
import { useFirebase } from "../../context/FirebaseContext";
import SubsStepper from "./SubsStepper";
import DividerHorizontal from "../UI/DividerHorizontal";

const RechargeCard = ({ onScroll }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const {
    recharge,
    conditionsConfirmation,
    setConditionsConfirmation,
    subsCart,
    cleanCartContext,
  } = useCartContext();

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
      onScroll(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onScroll]);

  const { selectedAddress, subsInfoData } = useFirebase();

  const [collapseOpen, setCollapseOpen] = useState(false);

  const dotStyle = {
    width: "20px",
    height: "20px",
  };


  function getAgregarBtnStateClass() {
    return !conditionsConfirmation || selectedAddress.available_recharges < 1
      ? "disabled-forced"
      : "";
  }

  useEffect(() => {
    if (conditionsConfirmation && collapseOpen) {
      setCollapseOpen(false);
    }
  }, [conditionsConfirmation]);

  const handleCollapseToggle = () => {
    setCollapseOpen(!collapseOpen);
  };

  function countAllSubscriptions(addressId) {
    let count = 0;
    for (let i = 0; i < subsInfoData.length; i++) {
      if (subsInfoData[i].address_id === addressId) {
        count++;
      }
    }
    return count;
  }

  function countActiveRecharges(addressId) {
    let count = 0;
    for (let i = 0; i < subsInfoData.length; i++) {
      const now = new Date();
      const formattedDateObj = new Date(
        subsInfoData[i].active_cicle_end.seconds * 1000 +
          subsInfoData[i].active_cicle_end.nanoseconds / 1000000
      );
      if (
        subsInfoData[i].address_id === addressId &&
        subsInfoData[i].status === "active" &&
        formattedDateObj > now
      ) {
        count++;
      }
    }
    return count;
  }

  const imgBg = {
    minWidth: "auto",
    minHeight: "100%",
    background: `url(${imgRecharge})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div
      className="px-4 px-md-0 overflow-y-scroll "
      style={{ maxWidth: "1024px" }}
    >
      {/* {BuyBox Web} */}
      <div
        className="d-none d-md-block"
        style={{ minHeight: "24px", width: "100%" }}
      ></div>
      <div className="m-md-0 mt-3 w-100 card border-0 shadow-sm py-2 d-flex rounded-2">
        <div className="d-flex flex-column gap-0 pt-4 px-4">
          <h5 className="d-flex gap-2">
            <span className="badge text-bg-secondary">
              Envío programado gratis
            </span>
          </h5>
          <h2 className="p-0 m-0 d-none d-md-block">Recambio de botellón</h2>
          <h3 className="p-0 m-0 d-md-none">
            <strong>Recambio de botellón</strong>
          </h3>
          <h3>
            <strong>$0 </strong>
            <del>$11.990</del>
          </h3>
          <a href="#terms" style={{ color: "#1464ff" }}>
            {" "}
            Ver más detalles
          </a>
        </div>
        <DividerHorizontal height={4} color="#f9f9f9" marginY={16}/>
        <div className="d-flex h-100 flex-column justify-content-between gap-2">
          <div className="flex-column w-100gap-0 d-flex rounded-2 ">
            <div className=" d-flex flex-column flex-md-row gap-0 gap-sm-3 px-3 px-md-4 py-3">
              <div className="d-flex align-items-center gap-2">
                <p
                  className="p-0 m-0 d-flex gap-1 align-content-center align-items-center"
                  style={{ lineHeight: "72%" }}
                >
                  <i
                    className="fa fa-caret-right "
                    aria-hidden="true"
                    style={{ color: "#222" }}
                  ></i>
                  Planes/Botellones:
                </p>
                <h5 className="m-0 p-0">
                  <span
                    className="badge rounded-pill text-bg-light"
                    style={{ minWidth: "24px" }}
                  >
                    {countAllSubscriptions(selectedAddress.address.id)}
                  </span>
                </h5>
              </div>
              <div class="vr d-none d-sm-block"></div>
              <div className="d-flex align-items-center gap-2">
                <p
                  className="p-0 m-0 d-flex gap-1 align-content-center align-items-center"
                  style={{ lineHeight: "72%" }}
                >
                  <i
                    className="fa fa-refresh "
                    aria-hidden="true"
                    style={{ color: "#222" }}
                  ></i>
                  Recambios disponibles:
                </p>
                <h5 className="m-0 p-0">
                  <span
                    className={`badge rounded-pill ${
                      countActiveRecharges(selectedAddress.address.id) > 0
                        ? "bg-primary-subtle text-primary"
                        : "text-bg-secondary"
                    }`}
                    style={{ minWidth: "24px" }}
                  >
                    {countActiveRecharges(selectedAddress.address.id)}
                  </span>
                </h5>
              </div>
            </div>
            <div className="d-flex flex-column gap-2  py-2">
              <div className="d-flex flex-md-wrap gap-2 px-3 px-md-4 overflow-x-scroll">
                {subsInfoData
                  .filter(
                    (sub) => sub.address_id === selectedAddress.address.id
                  )
                  .sort((a, b) => {
                    if (a.status === "active" && b.status === "active") {
                      if (
                        a.recharges_availables > 0 &&
                        b.recharges_availables > 0
                      ) {
                        return b.recharges_availables - a.recharges_availables;
                      } else if (
                        a.recharges_availables === 0 &&
                        b.recharges_availables === 0
                      ) {
                        return 0;
                      } else {
                        return a.recharges_availables === 0 ? 1 : -1;
                      }
                    } else {
                      return a.status === "active" ? -1 : 1;
                    }
                  })
                  .map(
                    (
                      sub // Aquí agregué la variable sub
                    ) => (
                      <>
                        <SubsStepper
                          version="refill"
                          subscription={sub.id}
                          addressId={sub.address_id}
                        />
                      </>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ height: "8px", backgroundColor: "#f4f4f4" }}
        className="w-100 my-4"
      ></div>
      {/* {Details} */}
      <div className="w-100 card shadow-sm border-0 gap-4 rounded-2 py-5 py-md-3" id="terms">
        <div className="d-flex flex-column gap-4 px-4">
          <h3 className="h3 p-0 m-0 mt-md-4">
            <strong>Detalles</strong>
          </h3>
          <hr className="p-0 m-0" />
          <h4 className="h5 p-0 m-0">
            <strong>¿Cómo funciona?</strong>
          </h4>
          <p className="text-wrap flex" style={{ fontSize: "16px" }}>
            Hacemos un intercambio! Recibimos tu botellón vacío y te entregamos
            uno reacondicionado, lleno, con tapa nueva y sellado.
          </p>
          <img
            src={rechargeActionImg}
            className="py-3 productInfoImage"
            alt="Imagen de la acción de recarga"
          />
          <h4 className="h5 p-0 m-0 mt-md-4">
            <strong>⚠️ Condiciones de recambio</strong>
          </h4>
          <ul style={{ fontSize: "14px", marginTop: "1rem" }}>
            <li>▸ No tener fisuras o golpes.</li>
            <li>▸ No tener ningún tipo de contenido.</li>
            <li>▸ Entregar botellón otorgado.</li>
          </ul>
        </div>
        <div className="d-flex flex-column gap-4 p-4">
          <h4 className="h5 p-0 m-0 mt-md-4">
            <strong>Descripción</strong>
          </h4>
          <p style={{ fontSize: "16px" }}>
            Botellón ideal para almacenar y transportar agua, llénalo
            indefinidamente gracias a sus materiales aislantes libres de BPA
            (sin olor, ni sabor a plástico), disminuye la pérdida de cualidades
            por luz, temperatura o contaminantes externos.
          </p>
        </div>
        <div className="d-flex flex-column gap-4 px-4">
          <h4 className="h5 p-0 m-0 mt-md-4">
            <strong>Contenido</strong>
          </h4>
          <ul>
            <li>▸ Botellón en excelente estado.</li>
            <li>▸ Capacidad de 20 lt.</li>
            <li>▸ 20 lt de agua purificada Fluenti.</li>
            <li>▸ Tapa.</li>
            <li>▸ Termosellado.</li>
          </ul>
        </div>
        <div className="d-flex flex-column gap-4 px-4">
          <h4 className="h5 p-0 m-0 mt-md-4">
            <strong>Entregas disponibles</strong>
          </h4>
          <ul className="mb-5">
            <li>🕒 Entrega Gratis (Servicio programado)</li>
            <li>🔁 Pick-Up (Puedes pasar a recogerlo)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RechargeCard;

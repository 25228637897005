import React, { useEffect, useState } from "react";
import Button from "./UI/Button";
import Stepper from "./UI/Stepper";
import "../styles/ProductPage.css";
import "../styles/Medias.css";
import replacementImg from "../images/no-tag-product-replace.png";
import newBottleImg from "../images/no-tag-product-new.png";
import rechargeInfo from "../images/recharge-info.png";
import botellonCheck from "../images/botellon-check.png";
import { useCartContext } from "../context/CartContext";

const ProductPage = (props) => {
  const {
    newSig,
    usedSig,
    recharge,
    setRecharge,
    pricing,
    formatCashToString,
    conditionsConfirmation,
    setConditionsConfirmation,
  } = useCartContext();

  const checkboxLabel =
    recharge < 2 ? (
      <div className="d-flex align-items-center flex-row-reverse gap-2">
        <p style={{ lineHeight: "14px" }}>
          Mi botellón <b>cumple</b> con todas las{" "}
          <a href="#terms">condiciones para el recambio</a>.
        </p>
        <img
          src={botellonCheck}
          style={{ height: "32px" }}
        ></img>
      </div>
    ) : (
      <div className="d-flex align-items-center flex-row-reverse gap-2">
        <p style={{ lineHeight: "14px" }}>
          Mis botellones <b>cumplen</b> con todas las{" "}
          <a href="#terms">condiciones para el recambio</a>.
        </p>
        <img
          src={botellonCheck}
          style={{ height: "32px", width: "22px" }}
        ></img>
      </div>
    );

  const content = {
    recharge: (
      <div className="container overflow-scroll mb-5">
        <div className="row">
          <div className="col-12 col-md-6 align-items-center justify-content-center productImageBox">
            <img src={replacementImg} className="productImage "></img>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column">
              <h2>Recambio de botellón 20lt</h2>
              <h2>$ {formatCashToString(pricing.recharge)}</h2>
              <p>$ {formatCashToString(pricing.recharge / 20)} / lt</p>
            </div>
            <hr></hr>
            <div className="d-flex flex-column gap-2" id="terms">
              <h4>¿Cómo funciona?</h4>
              <p className="text-wrap flex">
                Hacemos un intercambio! Recibimos tu botellón vacío y te
                entregamos uno reacondicionado, lleno, con tapa nueva y sellado.
              </p>
              <img src={rechargeInfo} className="py-5 productInfoImage" />
              <div className="d-flex flex-column gap-2">
                <h4>⚠️ Condiciones de recambio</h4>
                <ul>
                  <li>No tener fisuras o golpes.</li>
                  <li>No tener ningún tipo de contenido.</li>
                  <li>Ser de tu propiedad (No botellones marcados).</li>
                </ul>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex flex-column gap-2">
              <h4>Descripción</h4>
              <p>
                Botellón ideal para almacenar y transportar agua, llenalo
                indefinidamente gracias a sus materiales aislantes libres de BPA
                (no olor, ni sabor a plastico), disminuye la perdida de
                cualidades por luz, temperatura o contaminantes externos.
              </p>
            </div>
            <hr></hr>
            <div className="d-flex flex-column gap-2">
              <h4>Contenido</h4>
              <ul>
                <li>Botellón policarbonato excelente estado.</li>
                <li>20 lt capacidad.</li>
                <li>20 lt de agua purificada Fluenti.</li>
                <li>Tapa con dispensador.</li>
                <li>Termosellado.</li>
              </ul>
            </div>
            <hr></hr>
            <div className="d-flex flex-column gap-2 mb-5">
              <h4>Entregas disponibles</h4>
              <ul className="mb-5">
                <li>🕒 Entrega Gratis (Servicio programado)</li>
                <li className="mb-5">🔁 Pick-Up (Puedes pasar a recogerlo)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    ),
    newSig: (
      <div className="container overflow-scroll mb-5">
        <div className="row">
          <div className="col-12 col-md-6 align-items-center justify-content-center productImageBox">
            <img src={newBottleImg} className="productImage "></img>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column">
              <h2>Botellón 20lt Nuevo 100%</h2>
              <h2>$ {formatCashToString(pricing.newSig)}</h2>
              <p>$ {formatCashToString(pricing.newSig / 20)} / lt</p>
            </div>

            <hr></hr>
            <div className="d-flex flex-column gap-2">
              <h4>Descripción</h4>
              <p>
                Botellón ideal para almacenar y transportar agua, llenalo
                indefinidamente gracias a sus materiales aislantes libres de BPA
                (no olor, ni sabor a plastico), disminuye la perdida de
                cualidades por luz, temperatura o contaminantes externos.
              </p>
            </div>
            <hr></hr>
            <div className="d-flex flex-column gap-2">
              <h4>Contenido</h4>
              <ul>
                <li>Botellón policarbonato 100% nuevo.</li>
                <li>20 lt capacidad.</li>
                <li>20 lt de agua purificada Fluenti.</li>
                <li>Tapa con dispensador.</li>
                <li>Termosellado.</li>
              </ul>
            </div>

            <hr></hr>
            <div className="d-flex flex-column gap-2 mb-4">
              <h4>Entregas disponibles</h4>
              <ul>
                <li>🕒 Entrega Gratis (Servicio programado)</li>
                <li>🔁 Pick-Up (Puedes pasar a recogerlo)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    ),
    usedSig: (
      <div className="container overflow-scroll mb-5">
        <div className="row">
          <div className="col-12 col-md-6 align-items-center justify-content-center productImageBox">
            <img src={newBottleImg} className="productImage "></img>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column">
              <h2>Botellón 20lt Preutilizado</h2>
              <h2>$ {formatCashToString(pricing.usedSig)}</h2>
              <p>$ {formatCashToString(pricing.usedSig / 20)} / lt</p>
            </div>

            <hr></hr>
            <div className="d-flex flex-column gap-2">
              <h4>Descripción</h4>
              <p>
                Botellón ideal para almacenar y transportar agua, llenalo
                indefinidamente gracias a sus materiales aislantes libres de BPA
                (no olor, ni sabor a plastico), disminuye la perdida de
                cualidades por luz, temperatura o contaminantes externos.
              </p>
            </div>
            <hr></hr>
            <div className="d-flex flex-column gap-2">
              <h4>Contenido</h4>
              <ul>
                <li>Botellón policarbonato excelente estado.</li>
                <li>20 lt capacidad.</li>
                <li>20 lt de agua purificada Fluenti.</li>
                <li>Tapa con dispensador.</li>
                <li>Termosellado.</li>
              </ul>
            </div>
            <hr></hr>
            <div className="d-flex flex-column gap-2 mb-4">
              <h4>Entregas disponibles</h4>
              <ul>
                <li>🕒 Entrega Gratis (Servicio programado)</li>
                <li>🔁 Pick-Up (Puedes pasar a recogerlo)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    ),
  };

  function getModalContent() {
    switch (props.modalType) {
      case "recharge":
        return content["recharge"];
      case "newSig":
        return content["newSig"];
      case "usedSig":
        return content["usedSig"];
      default:
        break;
    }
  }

  function handleConditionsConfirmation() {
    setConditionsConfirmation(!conditionsConfirmation);
  }

  function getAgregarBtnStateClass() {
    if (props.modalType === "recharge" && !conditionsConfirmation) {
      return "disabled-forced";
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (conditionsConfirmation === false) {
      setRecharge(0);
    }
  }, [newSig, usedSig, recharge, conditionsConfirmation]);

  return (
    <div
      className="py-4 px-2 d-flex flex-column border-0 gap-2"
      style={{ height: "100%", userSelect: "none" }}
    >
      <div className="d-flex flex-row-reverse ">
        <Button version="primary" text="Cerrar" onClick={props.closeModal} />
      </div>
      {getModalContent()}

      <div className="fixed-bottom bottomBar d-flex flex-column justify-content-between">
        {props.modalType === "recharge" && (
          <>
            <div className="d-flex flex-row-reverse gap-3 justify-content-between align-items-center py-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                style={{
                  width: "24px",
                  height: "24px",
                  padding: "0",
                  margin: "0",
                }}
                onChange={handleConditionsConfirmation}
                checked={conditionsConfirmation} // Añade esta línea para reflejar el estado desde el inicio
              />
              {checkboxLabel}
            </div>
            <hr></hr>
          </>
        )}
        <div className="d-flex justify-content-between align-items-center">
          {props.modalType === "recharge" && (
            <div className="flex-column">
              <p>
                $ {formatCashToString(pricing.recharge)}/u x {recharge}ud
              </p>
              <h4>
                Total: $ {formatCashToString(recharge * pricing.recharge)}
              </h4>
            </div>
          )}
          {props.modalType === "newSig" && (
            <div className="flex-column">
              <p>
                Unidad:$ {formatCashToString(pricing.newSig)}/u x {newSig}ud
              </p>
              <h4>Total: $ {formatCashToString(newSig * pricing.newSig)}</h4>
            </div>
          )}
          {props.modalType === "usedSig" && (
            <div className="flex-column">
              <p>
                $ {formatCashToString(pricing.usedSig)}/u x {usedSig}ud
              </p>
              <h4>Total: $ {formatCashToString(usedSig * pricing.usedSig)}</h4>
            </div>
          )}
          <div className={getAgregarBtnStateClass()}>
            <Stepper product={props.modalType} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;

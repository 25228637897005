import React, { useState, useEffect } from "react";
import waterBgImg from "../../images/waterbg.jpg";
import heroImage from "../../images/three-sug-trio-botellon-botellones.png";
import sug02 from "../../images/sug-02.png";
import sug03 from "../../images/sug-03.png";
import sug04 from "../../images/sug-04.png";
import sug05 from "../../images/sug-05.png";
import tinyInfo from "../../images/tiny-info.svg";
import "../../styles/EarlyAccess.css";
import Modal from "react-modal";
import EarlyIntention from "./EarlyIntention";
import Button from "../UI/Button";
import barriosData from "./BarranquillaBarrios.json"; // Importa el archivo JSON
import DividerHorizontal from "../UI/DividerHorizontal";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useMediaQuery } from "react-responsive";
import { useSalesContext } from "../../context/SalesContext";
import NotJugsAvailables from "./NotJugsAvailables";

const EarlyAccess = (props) => {
  const [imageOpacity, setImageOpacity] = useState(1); // Inicializa con la imagen predeterminada sug02
  const { availability } = useSalesContext();

  const barriosBaq = barriosData.barriosBaq;
  // const [props.selectedPlan, setprops.selectedPlan] = useState(1);
  const [selectedNeighborhood, setSelectedNeighborhood] =
    useState("Elije un barrio"); // State to store the selected neighborhood
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const [earlyIntentionNeighborhood, setEarlyIntentionNeighborhood] =
    useState();
  const [isEmptyModalOpen, setIsEmptyModalOpen] = useState(false);

  const modalCustomStyles = {
    content: {
      borderRadius: "0px",
      border: "#fff 1px solid",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999999",
    },
  };

  const unselectedStyle = {
    width: "48px",
    height: "38px",
    fontSize: "16px",
    backgroundColor: "#F8F9FA",
    border: "none",
    fontWeight: "600",
  };
  const selectedStyle = {
    fontSize: "16px",
    width: "auto",
    height: "38px",
    border: "#1464ff 0px solid",
    backgroundColor: "#e0ebff",
    fontWeight: 700,
    color: "#1464ff",
    padding: "0px 10px",
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (modalValue) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isEmailValid = (email) => {
    // Expresión regular para validar un correo electrónico con al menos dos caracteres después del punto
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailPattern.test(email);
  };

  const handleSugsSelection = (sugs) => {
    setImageOpacity(0.0);

    // Gradualmente aumenta la opacidad a 0.5
    for (let opacity = 0.0; opacity <= 0.5; opacity += 0.01) {
      setTimeout(() => {
        setImageOpacity(opacity);
      }, opacity * 250); // Multiplicamos por 1000 para convertir segundos a milisegundos
    }

    props.setSelectedPlan(sugs); // Actualiza el estado con la cantidad de recambios seleccionada

    // Gradualmente aumenta la opacidad a 1
    for (let opacity = 0.5; opacity <= 1.0; opacity += 0.01) {
      setTimeout(() => {
        setImageOpacity(opacity);
      }, opacity * 250); // Multiplicamos por 1000 para convertir segundos a milisegundos
    }

    // Actualiza la imagen seleccionada según la cantidad de botellones
  };

  // Actualiza el estilo de los divs según la cantidad seleccionada
  const getStyleForSugs = (sugs) => {
    return sugs === props.selectedPlan ? selectedStyle : unselectedStyle;
  };

  const handleNeighborhoodChange = (event) => {
    setSelectedNeighborhood(event.target.value); // Update the state with the selected neighborhood
  };

  function moveToServices() {
    const yOffset = -240;
    const element = document.getElementById("services");
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }

  const checkLocation = () => {
    switch (selectedNeighborhood) {
      case "Centro":
      case "Boston":
      case "El Rosario":
      case "El Prado":
      case "Colombia":
      case "Las Delicias":
      case "El Recreo":
      case "Olaya":
      case "Betania":
      case "San Felipe":
      case "Los Andes":
      case "Los Pinos":
      case "Lucero":
      case "Parque Central":
      case "Loma Fresca":
      case "El Carmén":
      case "San Isidro":
      case "Alfonso López":
      case "San José":
      case "San Roque":
      case "Atlántico":
      case "Chiquinquirá":
      case "Barrio Abajo":
        if (availability.avialables_plans > 0) {
          props.goToPlanCheckout(selectedNeighborhood);
        } else {
          setIsEmptyModalOpen(true);
        }
        break;
      default:
        setIsModalOpen(true);
    }
  };

  // const handleEarlyIntentionNeighborhood = (neighborhood) => {
  //   setSelectedNeighborhood(neighborhood);
  // };

  function getSelectedBarrio() {
    console.log(">>>>>>>>>>>", selectedNeighborhood);
    return selectedNeighborhood;
  }

  return (
    <div
      className="pt-5 mt-2 d-flex flex-column justify-content-center align-content-center rounded-5 overflow-visible"
      style={{ maxWidth: "1024px", width: "100%" }}
    >
      <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="h1Style p-0 m-0 text-center">
            {" "}
            <span
              className="h2Style m-0 pe-2"
              style={{
                color: "#8D9AB4",
                paddingLeft: ".5rem",
                fontSize: "80px",
                lineHeight: "86px",
                fontWeight: 400,
              }}
            >
              RECAMBIOS DE
            </span>
            AGUA PURIFICADA
          </h1>
        </div>
        <div className="d-flex gap-2">
          <p className="valueBadge">Planes mensuales</p>
          <p className="valueBadge">Botellón 20lt incluido</p>
          <p className="valueBadge">Pide en línea</p>
          <p className="valueBadge">Domicilio gratis*</p>
        </div>
      </div>
      <div className="p-0 m-0 d-flex align-items-center justify-content-center">
        <img
          className="heroImg mt-4 mt-md-2"
          src={
            props.selectedPlan === 1
              ? sug02
              : props.selectedPlan === 2
              ? sug04
              : sug05
          }
          style={{ filter: `opacity(${imageOpacity})` }}
        />
      </div>
      <div className="pb-5" id="selectPlanLocation">
        <div
          style={{
            backgroundColor: "#fff",
            border: "#EFEFEF 1px",
            filter: "drop-shadow(0px 16px 8px rgba(0,0,0,0.08))",
          }}
          className="p-4 p-md-3 gap-4 gap-md-3 d-flex flex-column py-4 flex-md-row rounded-4 justify-content-around overflow-visible align-items-md-end"
        >
          <div className="d-flex flex-column gap-2 h-100 w-100">
            <strong>
              Elije un plan de recambios/mes{" "}
              <img src={tinyInfo} onClick={moveToServices} />
            </strong>
            <div className="d-flex gap-2">
              <div
                className="unselected-sug  rounded-3 d-flex align-items-center justify-content-center"
                style={getStyleForSugs(1)}
                onClick={() => handleSugsSelection(1)}
              >
                2 {props.selectedPlan === 1 ? "Recambios" : ""}
              </div>
              <div
                className="unselected-sug  rounded-3 d-flex align-items-center justify-content-center"
                style={getStyleForSugs(2)}
                onClick={() => handleSugsSelection(2)}
              >
                4 {props.selectedPlan === 2 ? "Recambios" : ""}
              </div>
              <div
                className="unselected-sug  rounded-3 d-flex align-items-center justify-content-center"
                style={getStyleForSugs(3)}
                onClick={() => handleSugsSelection(3)}
              >
                8 {props.selectedPlan === 3 ? "Recambios" : ""}
              </div>
            </div>
          </div>
          <div
            className="vr d-none d-md-block"
            style={{ filter: "opacity(.24)" }}
          ></div>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger asChild openOn="click">
                <div
                  className=" d-flex flex-column gap-2 h-100"
                  style={{ minWidth: "180px" }}
                >
                  <strong>Ciudad</strong>
                  <select
                    value="Barranquilla"
                    onChange={handleNeighborhoodChange}
                    id="neighborhood-intention"
                    name="neighborhood-intention"
                    className="custom-select rounded-3 bg-light border-0 disabled-forced"
                    required="required"
                  >
                    {isSmallScreen ? (
                      <p className="pt-1 text-secondary">
                        Muy pronto en otras ciudades{" "}
                      </p>
                    ) : null}
                    <option>Barranquilla</option>
                  </select>
                </div>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  className="TooltipContent bg-dark text-white rounded-2 p-2"
                  sideOffset={5}
                >
                  Muy pronto en otras ciudades
                  <Tooltip.Arrow className="TooltipArrow" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>

          <div
            className="vr d-none d-md-block"
            style={{ filter: "opacity(.24)" }}
          ></div>
          <div
            className=" d-flex flex-column gap-2 h-100"
            style={{ minWidth: "220px" }}
          >
            <strong>Barrio</strong>
            <select
              value={selectedNeighborhood}
              onChange={handleNeighborhoodChange}
              id="neighborhood-intention"
              name="neighborhood-intention"
              className="custom-select rounded-3 bg-light border-0"
              required="required"
            >
              {/* Iterar sobre los barrios ordenados para generar las opciones del select */}
              {barriosBaq.map((barrio, index) => (
                <option key={index} value={barrio}>
                  {barrio}
                </option>
              ))}
            </select>
          </div>
          <div
            className="vr d-none d-md-block"
            style={{ filter: "opacity(.24)" }}
          ></div>
          <DividerHorizontal
            height={1}
            color={"#f0f0f0"}
            hideOnDesktop={true}
          />
          <div
            style={{ minWidth: "200px" }}
            className={`${
              selectedNeighborhood !== "Elije un barrio"
                ? ""
                : "disabled-forced"
            }`}
          >
            <Button
              text="Adquirir este plan"
              version="primaryLarge"
              onClick={checkLocation}
            />
          </div>
        </div>
        <Modal
          style={modalCustomStyles}
          closeTimeoutMS={250}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          overlayClassName="overlay-modal"
        >
          <EarlyIntention
            closeModal={closeModal}
            selectedNeighborhood={selectedNeighborhood}
            getSelectedBarrio={getSelectedBarrio}
            selectedPlan={props.selectedPlan}
          />
        </Modal>
        <Modal
          style={modalCustomStyles}
          isOpen={isEmptyModalOpen}
          onRequestClose={() => setIsEmptyModalOpen(false)}
          overlayClassName="overlay-modal"
          contentLabel="New Modal"
        >
          <NotJugsAvailables
            selectedNeighborhood={selectedNeighborhood}
            setIsEmptyModalOpen={setIsEmptyModalOpen}
            selectedPlan={props.selectedPlan}
            getSelectedBarrio={getSelectedBarrio}
          />
        </Modal>
      </div>
    </div>
  );
};

export default EarlyAccess;

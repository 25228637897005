import React, { useState } from "react";
import { FirebaseProvider } from "./context/FirebaseContext";
import { SalesProvider } from "./context/SalesContext";
import { CartProvider } from "./context/CartContext";
import { BrowserRouter, Route, useParams, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Styles
import "./styles/App.css";
// Components
import Landing from "./components/Landing";
import Cart from "./components/Cart";
import PostOrderFeedback from "./components/PostOrderFeedback";
import PageNotFound from "./components/PageNotFound";
import Coverage from "./components/Coverage";
import SuccessPage from "./components/SuccessPage";
import TransitSearch from "./components/TransitSearch";
import SubscriptionsDetails from "./components/SubscriptionsDetails";
import ThankYouPage from "./components/subscriptions/ThankYouPage";
import AccessPage from "./components/AccessPage";
import Slots from "./components/Slots";
import FAQ from "./components/FAQ";
import Board from "./components/Board";
import PrivateRoute from "./components/PrivateRoute";
import OrderGateway from "./components/OrderGateway";
import "@radix-ui/themes/styles.css";

//Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import SubscriptionGateway from "./components/SubscriptionGateway";
import Checkout from "./components/Checkout";

library.add(fab, faCheckSquare, faEnvelope, faWhatsapp);

function PostOrderFeedbackWrapper() {
  const { linkParam } = useParams();

  // Puedes realizar cualquier lógica adicional aquí antes de pasar el parámetro a PostOrderFeedback
  // ...

  return <PostOrderFeedback linkParam={linkParam} />;
}

function App() {
  return (
    <BrowserRouter>
      <FirebaseProvider>
        <SalesProvider>
          <CartProvider>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/ingresar" element={<AccessPage />} />
              <Route
                path="/board"
                element={
                  <PrivateRoute>
                    <Board />
                  </PrivateRoute>
                }
              />

              {/* Aplica directamente la clase al componente Cart */}
              {/* <Route path="/carrito" element={<Cart />} /> */}
              <Route
                path="/checkout/:selectedPlan/:neighborhood"
                element={<Checkout />}
              />
              <Route path="/finalizar-pedido" element={<Slots />} />
              <Route path="/gateway" element={<OrderGateway />} />
              <Route
                path="/mi-suscripcion/:linkParam"
                element={<SubscriptionGateway />}
              />
              <Route
                path="/pedido/:linkParam"
                element={<PostOrderFeedbackWrapper />}
              />
              <Route path="/pedido" element={<PostOrderFeedback />} />
              <Route
                path="/orderSearch/:linkParam"
                element={<TransitSearch />}
              />
              <Route
                path="/pedidos/:linkParam"
                element={<PostOrderFeedbackWrapper />}
              />
              <Route path="/pedidos" element={<PostOrderFeedback />} />
              <Route path="/cobertura" element={<Coverage />} />
              <Route path="/planes" element={<SubscriptionsDetails />} />
              <Route path="/gracias" element={<ThankYouPage />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/preguntas-frecuentes" element={<FAQ />} />
              <Route path="/success/:linkParam" element={<SuccessPage />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </CartProvider>
        </SalesProvider>
      </FirebaseProvider>
      <ToastContainer
        position="top-center"
        autoClose={1200}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
        closeButton={false}
      />
    </BrowserRouter>
  );
}

export default App;
